.demo {
    .banner {
        position: relative;
        height: 22rem;
        background: url('../../images/demo_phone_landing.png');
        background-position: center;
        background-size: cover;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 4.8rem;
        font-weight: 700;
        color: #fff;
        z-index: -1;

        video {
            z-index: -1;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;

      @media (max-width: 565px) {
        display: none;
      }
        }
    }
    form {
        width: 100%;
        max-width: 68.2rem;
        min-height: 50rem;
        // background-color: yellow;
        padding: 0 20px;
        margin: auto;
        display: flex;
        margin-top: 6.4rem;
        margin-bottom: 21.6rem;
        flex-direction: column;
        align-items: center;

        .input_group {
            display: grid;
            grid-template-columns: 1fr 8fr;
            grid-column-gap: 34px;
            grid-row-gap: 24px;
            align-items: center;
            margin-bottom: 6.4rem;

            label {
                font-size: 1.2rem;
                font-weight: 500;
                color: #141414;
                white-space: nowrap;

                span {
                    color: rgba(243, 29, 29, 1);
                    font-size: 1.6rem;
                }
            }
            .input_group_inner {
                display: flex;
                align-items: center;
                width: 100%;
                height: 5.6rem;
                border: 0.5px solid #898989;
                border-radius: 12px;
                padding: 12px;


                input {
                    width: 100%;
                    height: 100%;
                    border: none;
                    outline: none;
                }
                
                .divider {
                    width: 0.05rem;
                    height: 1.4rem;
                    background-color: #063;
                    margin: 0 0.8rem;
                }
            }
        }

        button {
            width: 32.4rem;
            height: 6.7rem;
            background-color: #063;
            font-size: 1.6rem;
            font-weight: 500;
            border: none;
            outline: none;
            border-radius: 12px;
            color: #fff;
            transition: 0.4s;

            &:hover {
              background-color: #0D924F;
            }
        }
    }
}

@media (max-width: 600px) {
    .demo {
        .banner {
            height: 31.7rem;
            font-size: 2.4rem;
            text-align: center !important;
        }

        form {
            .input_group {
            grid-column-gap: 6px;
            grid-row-gap: 16px;
            margin-bottom: 3.6rem;

            .input_group_inner {
                input {
                    font-size: 0.8rem;
                }
            }

            }
        }
    }
}