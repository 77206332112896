.send-demo {
    display: flex;
  
    .send-demo_main {
      max-width: 1440px;
      width: 100%;
      margin: auto;
      height: 100vh;
      background-color: #fff;
      padding: 44px;
      overflow-x: auto;
  
      .send-demo_form {
        max-width: 1000px;
        width: 100%;
        display: flex;
        flex-direction: column;
  
        .send-demo_grid {
          display: grid;
          grid-template-columns: 1fr;
          gap: 55px 68px;
          margin-bottom: 2.4rem;
  
          .send-demo_input {
            width: 100%;
            height: 6.6rem;
            border-radius: 12px;
            border: 1px solid #E5E5E5;
            margin-top: 1.2rem;
            padding-left: 24px;
            outline: none;
          }
        }
  
        label {
          font-size: 1.6rem;
          font-weight: 600;
          color: #1a1a1a;
  
          span {
            color: red;
            font-weight: 800;
          }
        }
  
        textarea {
          border-radius: 12px;
          padding-top: 24px;
          padding-left: 24px;
          outline: none;
          height: 55rem;
          margin-top: 1.2rem;
        }
  
        .send-demo_button-group {
          display: grid;
          margin-top: 3.6rem;
          grid-template-columns: 21.2rem 37.9rem;
          gap: 32px;
  
          .button-group_filled {
              width: 100%;
              height: 72px;
              border-radius: 12px;
              background: linear-gradient(116.29deg, #006633 0.76%, #00aed9 100%);
              display: flex;
              align-items: center;
              justify-content: center;
              border: none;
              outline: none;
              color: #ffffff;
              font-size: 1.6rem;
              font-weight: 600;
              cursor: pointer;
              transition: 0.7s;
      
              &:hover {
                transition: 0.7s;
      
                background: none;
                color: #006633;
                border: 1px solid #006633;
              }
            }
          .button-group_trans {
              width: 100%;
              height: 72px;
              border-radius: 12px;
              background: transparent;
              color:  rgba(2, 164, 204, 0.40);
              display: flex;
              align-items: center;
              justify-content: center;
              border: 0.5px solid  #063;
              outline: none;
              font-size: 1.2rem;
              font-weight: 700;
              cursor: pointer;
              transition: 0.7s;
      
              
            }
        }
  
        svg {
          margin-bottom: 1.1rem;
          cursor: pointer;
        }
        
      }
    }
  }
  