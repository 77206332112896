.login {
  background: url("../../images/green-world-with-two-trucks\ 1.png");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  border: none;
  min-height: 100vh;
  display: flex;
  align-items: center;
  overflow: auto;

  .thirdparty_login_inner {
    width: 1446px;
    max-width: 100%;
    // background-color: yellow;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 100px;
    padding: 0 0 0 100px !important;
    align-items: center;
    margin: auto;

    @media (max-width: 1024px) {
      padding: 0 16px !important;
    }
    @media (max-width: 768px) {
      grid-template-columns: 1fr;
      padding: 0 100px !important;

    }

    @media (max-width: 565px) {
      padding:0 16px !important;
    }
    
    .title {
      font-size: 4.8rem;
      color: #ffffff;
      height: fit-content;

      @media (max-width: 768px) {
        margin-bottom: 2.4rem;
      }

      @media (max-width: 565px) {
        font-size: 2.4rem;
      }
    }

    .thirdparty_box {
        width: 100%;
        background-color: #fff;
        border-radius: 0;
        padding: 89px 32px;
        min-height: 500px;
        position: relative;
        height: 100vh;
        margin: 0;

        @media (max-width: 1024px) {
          height: unset;
          min-height: 50rem;
        }        
        @media (max-width: 565px) {
          padding: 32px;
        }        
    
        .signup_link {
          font-size: 1.2rem;
          color: #006633;
          text-align: right;
    
          span {
            font-weight: 700;
          }
        }
    
        .box_title {
          font-size: 3.2rem;
          font-weight: 700;
          color: #141414;
          margin-bottom: 4rem;

          @media (max-width: 565px) {
            font-size: 2.4rem;
          }
        }
        .box_subtitle {
          font-size: 1.2rem;
          color: #667185;
          margin-bottom: 36px;
        }
        form {
          .login_label {
            font-size: 1.6rem;
            font-weight: 600;
            color: #282828;
            margin-bottom: 1.2rem;
          }
          .thirdparty_login_input {
            color: #667185;
            border-bottom: 1px solid #98A2B3;
            height: 2.3rem;
            width: 100%;
            outline: none !important;
            font-size: 1.2rem;
            margin-bottom: 3.2rem;
            border-radius: 0;
            padding: 1px;
          }
    
          .thirdparty_login_error {
            border: 1px solid #d42620 !important;
          }
    
          .login_error_text {
            font-size: 12px;
            color: #d42620;
            margin-top: -2rem;
            margin-bottom: 2.4rem;
          }
    
          .login_remember {
            display: flex;
            align-items: center;
            font-size: 12px;
            gap: 8px;
          }
    
          .checkbox {
            width: 24px;
            height: 24px;
            border-radius: 50px !important;
            // outline: 1px solid yellow;
            border: none !important;
          }
        }
        .login_flex {
          display: flex;
          width: 100%;
          justify-content: space-between;
          align-items: center;
        }
    
        .forgot {
          color: #006633;
          text-decoration: underline;
          font-size: 14px;
          font-weight: 700;

          @media (max-width: 565px) {
            font-size: 12px;
          }
        }
    
        .login_submit {
          width: 100%;
          height: 72px;
          border-radius: 12px;
          background: linear-gradient(116.29deg, #006633 0.76%, #00aed9 100%);
          display: flex;
          align-items: center;
          justify-content: center;
          border: none;
          outline: none;
          color: #ffffff;
          font-size: 1.6rem;
          font-weight: 600;
          cursor: pointer;
          margin-top: 6rem;
          transition: 0.7s;

          @media (max-width: 565px) {
            height: 58px;
            margin-top: 4.8rem;
          }
    
          & > div {
            margin-right: 12px;
            color: #ffffff;
          }
    
          &:hover {
            transition: 0.7s;
    
            background: none;
            color: #006633;
            border: 1px solid #006633;
    
            & > div {
              color: #006633;
            }
            svg {
              path {
                fill: #006633;
              }
            }
          }
        }
      }
  }

  .modal_none {
    display: none;
  }
  .modal {
    width: 100%;
    height: 100vh;
    position: absolute;
    top: 0;
    left: 0;
    background: rgba(0, 0, 0, 0.7);
    z-index: 1;
  }

  .thirdparty_email_modal {
    width: 488px;
    min-height: 432px;
    background-color: #fff;
    position: absolute;
    top: 50%;
    left: 50%;
    z-index: 2;

    transform: translate(-50%, -50%);
    /* background-color: #ffffff; */
    box-shadow: 24;
    /* height: 641, */
    overflow: auto;
    padding: 32px;
    border-radius: 0;
    border: none !important;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    .confirm_svg {
      margin-bottom: 24px;
    }

    .modal_title {
      font-size: 32px;
      font-weight: 700;
      color: #141414;
      margin-bottom: 12px;
    }
    .modal_subtitle {
      font-size: 14px;
      font-weight: 400;
    }

    ul {
      font-size: 14px;
      font-weight: 700;
    }

    .modal_email {
      font-weight: 700;
      font-size: 12px;
      margin-top: 2.4rem;
    }

    .modal_label {
      font-size: 16px;
      font-weight: 500;
      color: #141414;
      width: 100%;
      margin-bottom: 12px;
      margin-top: 12px;
    }
    .thirdparty_modal_input {
      width: 100%;
      color: #667185;
      border: none;
      border-radius: 12px;
      height: 5.5rem;
      outline: none !important;
      font-size: 1.2rem;
      // margin-bottom: 2.4rem;
    }

    .modal_button {
      width: 100%;
      height: 72px;
      border-radius: 12px;
      background: linear-gradient(116.29deg, #006633 0.76%, #00aed9 100%);
      display: flex;
      align-items: center;
      justify-content: center;
      border: none;
      outline: none;
      color: #ffffff;
      font-size: 1.6rem;
      font-weight: 600;
      cursor: pointer;
      margin-top: 3.6rem;
      margin-bottom: 2.4rem;
      transition: 0.7s;

      & > div {
        margin-right: 12px;
      }

      &:hover {
        transition: 0.7s;

        background: none;
        color: #006633;
        border: 1px solid #006633;
      }
    }

    .didnt_get {
      font-size: 12px;
    }
  }
}

@media (max-width: 1025px) {
  .login {
    .login_inner {
      padding: 0 40px;
    }
  }
}
