.settings {
  display: flex;

  .sidebar_container {
    // background-color: yellow;
    //  height: 100%;
    max-height: 700px;
    position: relative;

    @media (max-width: 1024px) {
      display: none;
    }
  }

  .main {
    background-color: #fff;
    max-width: 1440px;
    width: 100%;
    padding: 44px;
    height: 100vh;
    overflow: auto;
    margin: auto;

    @media (max-width: 565px) {
      padding: 24px;
    }

    .settings_divider {
      max-width: 997px;
      width: 100%;
      height: 0.1rem;
      background-color: #d0d5dd;
      margin: 3.4rem 0;
    }

    .settings_box {
      width: 977px;
      max-width: 100%;

      .title {
        font-size: 1.6rem;
        font-weight: 600;
        color: #1a1a1a;
        margin-bottom: 1.2rem;

        @media (max-width: 565px) {
          font-size: 1rem;
        }
      }

      .subtitle_group {
        // max-width: 74.5rem;
        width: 100%;
        display: flex;
        // grid-template-columns: 10fr 1fr;
        // grid-column-gap: 129px;
        align-items: center;
        justify-content: space-between;
        font-size: 1.4rem;
        color: #515151;
        margin-bottom: 2.4rem;

        @media (max-width: 565px) {
          font-size: 1rem;
          // grid-template-columns: 10fr 1fr;
          // grid-column-gap: 102px;
        }

        input {
          margin-top: 0;
          width: 2.4rem;
          height: 2.4rem;

          @media (max-width: 565px) {
            width: 1.2rem;
            height: 1.2rem;
          }
        }
      }

      .nowrap {
        white-space: nowrap;

        @media (max-width: 768px) {
          white-space: unset !important;
        }
      }

      .settings_button_group {
        width: 100%;
        display: flex;
        justify-content: end;
        font-size: 1.2rem;
        font-weight: 700;

      

        .filled {
          border-radius: 8px;
          background: linear-gradient(116deg, #063 0.76%, #02a4cc 100%);
          height: 4rem;
          min-width: 11.8rem;
          padding: 0 16px;
          outline: none;
          border: none;
          color: #fff;
          white-space: nowrap;
          margin-top: -2rem !important;

          @media (max-width: 565px) {
            height: 2.4rem;
            font-size: 0.8rem;
            min-width: 8rem;
            padding: 0;
          }
        }

        .password {
          background: linear-gradient(
            116deg,
            rgba(0, 102, 51, 0.4) 0.76%,
            rgba(2, 164, 204, 0.4) 100%
          );
          min-width: 15.9rem;

          @media (max-width: 565px) {
            min-width: 8rem;
          }

          &:hover {
            background: linear-gradient(116deg, #063 0.76%, #02a4cc 100%);
          }
        }

        .settings-switch {
          width: 11.7rem;
          height: 4.7rem;
          border-radius: 50px;
          background: #98a2b3;
          // position: relative;
          cursor: pointer;
          padding: 0 6px;
          display: flex;
          align-items: center;

          @media (max-width: 565px) {
            width: 8rem;
            height: 3rem;
          }

          &__ball {
            width: 3.6rem;
            height: 3.6rem;
            border-radius: 50%;
            background: #fff;
            transition: 0.4s;
            // position: absolute;
            // top: 50%;

            @media (max-width: 565px) {
              width: 2.4rem;
              height: 2.4rem;
            }
          }
        }

        .settings-switch-active {
          background: #00aed9;
          // justify-content: end;

          & > div {
            transition: 0.4s;
            transform: translate(190%, 0);
          }
        }
      }

      .password_group {
        display: flex;
        align-items: center;
        justify-content: space-between;
      }

      .delete_group {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
      }
    }
  }

  .password_change_modal {
    position: absolute;
    top: 50%;
    left: 50%;
    padding: 22px;
    z-index: 2;
    transform: translate(-50%, -50%);
    max-width: 58rem;
    width: 100%;
    // height: 29.5rem;
    background-color: #fff;
    border-radius: 16px;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25),
      4px 0px 4px 0px rgba(0, 0, 0, 0.25);
    display: flex;
    flex-direction: column;

    label {
      font-size: 1.6rem;
      margin-bottom: 0.8rem;
    }

    .password_modal_input {
      color: #667185;
      border: none;
      border-radius: 12px;
      height: 5.5rem;
      width: 100%;
      outline: none !important;
      font-size: 1.2rem;
      margin-bottom: 2.4rem;
      background-color: #f0f0f0;
    }

    .password_modal_submit {
      width: 100%;
      height: 72px;
      border-radius: 12px;
      background: linear-gradient(116.29deg, #006633 0.76%, #00aed9 100%);
      display: flex;
      align-items: center;
      justify-content: center;
      border: none;
      outline: none;
      color: #ffffff;
      font-size: 1.6rem;
      font-weight: 600;
      cursor: pointer;
      margin-top: 1rem;
      transition: 0.7s;

      & > div {
        margin-right: 12px;
        color: #ffffff;
      }

      &:hover {
        transition: 0.7s;

        background: none;
        color: #006633;
        border: 1px solid #006633;

        & > div {
          color: #006633;
        }
      }
    }
  }

  .password_change_modal_container {
    width: 100%;
    height: 100vh;
    position: absolute;
    top: 0;
    left: 0;
    background: rgba(0, 0, 0, 0.7);
    z-index: 1;
  }

  .tfa-modal-container {
    width: 100%;
    height: 100vh;
    position: absolute;
    top: 0;
    left: 0;
    background: rgba(0, 0, 0, 0.7);
    z-index: 1;
  }

  .tfa-modal {
    position: absolute;
    top: 50%;
    left: 50%;
    padding: 48px;
    z-index: 2;
    transform: translate(-50%, -50%);
    max-width: 71rem;
    width: 85%;
    min-height: 67.4rem;
    // overflow: auto;
    // height: 29.5rem;
    background-color: #fff;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25),
      4px 0px 4px 0px rgba(0, 0, 0, 0.25);
    display: flex;
    flex-direction: column;
    text-align: center;

    @media (max-width: 768px) {
      min-height: 324px;
      padding: 24px;
    }

    &__svg {
      width: 100%;
      display: flex;
      justify-content: end;
      margin-bottom: 2.4rem;

      svg {
        cursor: pointer;
      }
    }

    &__title {
      font-size: 3.2rem;
      font-weight: 700;
      color: #1a1a1a;
      margin-bottom: 1.6rem;

      @media (max-width: 768px) {
        font-size: 1.6rem;
      }
    }

    &__subtitle {
      font-size: 1.6rem;
      font-weight: 400;
      color: #1a1a1a;
      width: 45ch;
      margin: 0 auto;

      @media (max-width: 768px) {
        font-size: 1.2rem;
        width: unset;
      }
    }

    .otp_box_group {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
      // background-color: yellow;
      grid-column-gap: 32px;
      margin: auto;
      margin-top: 7.2rem;
      margin-bottom: 7.4rem;
      width: fit-content;

      @media (max-width: 768px) {
        grid-column-gap: 16px;
        margin-top: 2.4rem;
        margin-bottom: 3.6rem;
      }

      .otp_box {
        width: 5.7rem;
        height: 5.7rem;

        @media (max-width: 768px) {
          width: 3.2rem;
          height: 3.2rem;
          border-radius: 6px;
        }

        // background-color: yellow;

        input {
          width: 5.7rem !important;
          height: 5.7rem !important;
          height: auto;
          // background-color: black;
          outline: none;
          border: none;
          border: 1px solid;
          border-color: linear-gradient(116.29deg, #006633 0.76%, #00aed9 100%);
          border-radius: 6px;
          font-size: 32px;
          text-align: center;
          color: #006633;

          @media (max-width: 768px) {
            width: 3.2rem !important;
            height: 3.2rem !important;
            border-radius: 6px;
            font-size: 12px !important;
          }
        }
      }
    }

    .password_modal_submit {
      width: 25.4rem;
      height: 8rem;
      border-radius: 12px;
      background: linear-gradient(116.29deg, #006633 0.76%, #00aed9 100%);
      display: flex;
      align-items: center;
      justify-content: center;
      border: none;
      outline: none;
      color: #ffffff;
      font-size: 1.4rem;
      font-weight: 700;
      cursor: pointer;
      transition: 0.7s;
      margin: 0 auto;

      @media (max-width: 768px) {
        height: 6rem;
      }

      & > div {
        margin-right: 12px;
        color: #ffffff;
      }

      &:hover {
        transition: 0.7s;

        background: none;
        color: #006633;
        border: 1px solid #006633;

        & > div {
          color: #006633;
        }
      }
    }
  }

  .delete-modal {
    position: absolute;
    width: 480px;
    height: 310px;
    border-radius: 12px;
    top: 50%;
    left: 50%;
    padding: 22px;
    z-index: 2;
    transform: translate(-50%, -50%);
    background: #fff;

    &__svg {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: end;
    }

    &__title {
      font-size: 24px;
      font-weight: 700;
      text-align: center;
      margin-bottom: 1.6rem;
    }

    &__subtitle {
      font-size: 16px;
      font-weight: 400;
      line-height: 19.36px;
      text-align: center;
      margin-bottom: 4.8rem;
      color: #667185;
    }

    &__button-group {
      display: grid;
      grid-template-columns: 1fr 1fr;
      align-items: center;
      justify-content: space-between;
      gap: 24px;

      button {
        width: 100%;
        height: 70px;
        background: #000;
        border-radius: 12px;
        background: linear-gradient(116deg, #063 0.76%, #02a4cc 100%);
        border: none;
        color: #fff;
        text-align: center;
        font-size: 16px;
        font-weight: 600;
      }

      & > :last-child {
        border-radius: 12px;
        border: 1px solid #063;
        background: transparent;
        color: #063;
      }
    }
  }
}

.subscription-plan-modal {
  position: fixed;
  width: 100%;
  height: 100vh;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(252, 252, 252, 0.75);

  &__inner {
    width: 100%;
    max-width: 458px;
    height: 378px;
    border-radius: 8px;
    border: 1px solid var(--Stroke, #e5e5e5);
    background: #fff;
    padding: 32px 37px 32px 32px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    &__cancel {
      width: 100%;
      margin-bottom: 16px;
      display: flex;
      justify-content: end;
    }

    &__icon {
      margin-bottom: 24px;
    }

    &__title {
      color: var(--Net-Black, #101828);
text-align: center;
font-size: 24px;
font-weight: 600;
margin-bottom: 8px;
    }

    &__subtitle {
      color: var(--Grey-500, #667185);
text-align: center;
font-size: 16px;
font-weight: 400;
margin-bottom: 24px;
    }

    & > button {
margin-top: 0 !important;
width: 199px;
height: 42px;
padding: 8px 16px;
background: var(--Gradient, linear-gradient(128deg, #038B47 0%, #00AED9 100%));
background-clip: text;
-webkit-background-clip: text;
-webkit-text-fill-color: transparent;
    }
  }
}

.transparent {
  background: transparent;
  border: 0.5px solid #063;
  border-radius: 8px;
  color: rgba(2, 164, 204, 0.4);
  min-width: 11.8rem;
  padding: 0 16px;
  height: 4rem;
  outline: none;
  margin-right: 1.2rem;
  white-space: nowrap;
  margin-top: -2rem !important;

  @media (max-width: 565px) {
    height: 2.4rem;
    font-size: 0.8rem;
    min-width: 8rem;
    padding: 0;
  }
}
