.resources{
    .hero {
        background: url('../../images/image\ 65.png');
        background-size: cover;
        background-position: center;
        height: 90rem;
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding: 0 144px;
        margin-bottom: 6.4rem;

        .title {
            font-size: 6.4rem;
            font-weight: 700;
            color: #FFF;
            margin-bottom: 1.6rem;
            margin-top: -10rem;
        }

        .subtitle {
            font-size: 2.4rem;
            font-weight: 400;
            color: #fff;
        }

    }
    .resources_section {
        width: 100%;
        max-width: 1440px;
        padding: 0 144px 64px 144px;
        margin: auto;

        .title {
            font-size: 3.6rem;
            font-weight: 700;
            color: #0D924F;
            margin-bottom: 2.4rem;
        }
        .section_group {
            display: grid;
            align-items: start;
            // overflow: auto;
            padding: 10px 0;
            grid-template-columns: repeat(auto-fill, minmax(36.8rem, 1fr));
            grid-column-gap: 24px;
            grid-row-gap: 32px;
            
            &::-webkit-scrollbar {
              display: none;
            }

      
            .box {
              width: 100%;
              border: 1px solid  #0D924F;
              border-radius: 12px;
              // margin-right: 2.2rem;
              position: relative;
              cursor: pointer;
              min-height: 29rem;
      
              .shadow {
                background: rgba(94, 182, 138, 0.30);
                width: 100%;
                height: 100%;
                position: absolute;
                top: 0;
                left: 0;
                display: none;
              }
      
              &:hover {
                .shadow {
                  display: initial;
                }
              }
      
              .image {
                height: 11.6rem;
      
                img {
                  width: 100%;
                  height: 11.6rem;
                  border-radius: 12px 12px 0 0;
                  object-fit:cover;

                }
              }
              .image2 {
                height: 17.9rem;
      
                img {
                  height: 17.9rem;
                }
              }
      
              .content {
                border-radius: 0 0 12px 12px;
                background: #fff;
                padding: 24px 24px 12px 24px;
      
                .title {
                  font-size: 2.2rem;
                  color: #141414;
                  font-weight: 500;
                  margin-bottom: 1.2rem;
                  margin-top: 0 !important;
                }
      
                .subtitle {
                  font-size: 1.4rem;
                  color: #898989;
                  font-weight: 400;
                  margin-bottom: 0;
      
                  & > :last-child {
                    width: 100%;
                    font-weight: 400;
                    color: #898989;
                    text-decoration: underline;
                    margin-top: 1.2rem;
                    text-align: end;
                    display: flex;
                    align-items: center;
                    justify-content: start;
                  }
                }
              }
              .content2 {
                padding: 24px;
                min-height: 22.1rem;

                .subtitle {
                  & > :last-child {
                    width: 100%;
                    font-weight: 500;
                    font-size: 1.6rem;
                    color: #0D924F;
                    text-decoration: underline;
                    margin-top: 1.2rem;
                    text-align: end;
                    display: flex;
                    align-items: center;
                    justify-content: end;
                  }
                }
              }
            }

            .box2 {
              box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
              border: none;
            }
            
      
            // & > :last-child {
            //   margin-right: 0;
            // }
          }
    }
}


@media (max-width: 1200px) {
.resources {
  .resources_section {
    padding: 0 16px 64px 16px;
    .section_group {
      grid-template-columns: repeat(auto-fill, minmax(28.8rem, 1fr));

    }
  }
}
}

@media (max-width: 600px) {
  .resources {
    .hero {
      padding: 0 16px;

      .title {
        font-size: 2.4rem;
        text-align: center;

      }
    }

    .resources_section{
      padding: 0 16px 32px 16px;
      .title {
        font-size: 2.4rem;
        margin-bottom: 1.6rem;
      }
      .section_group {
        grid-row-gap: 16px;
      }
    }
  }
}