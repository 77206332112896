footer {
    background-color: #063;
    color: #fff;
    padding: 64px 0 144px 0;
    .footer_group {
        width: 100%;
        max-width: 1440px;
        margin: auto;
        display: flex;
        justify-content: space-between;
        // grid-template-columns: 1fr 1fr 1fr 1fr;
        // grid-column-gap: 152px;
        padding:0 144px;
        margin-bottom: 5.2rem;

        & > div {
            .title {
                font-size: 2.4rem;
                font-weight: 500;
                margin-bottom: 2.4rem;
            }

            .group {
                display: flex;
                align-items: center;
                margin-bottom: 3.2rem;
                font-size: 1.6rem;
                font-weight: 400;

                svg {
                    margin-right: 1.2rem;
                    width: 2.4rem;
                    height: auto;
                }
            }

            .item {
                font-size: 1.6rem;
                font-weight: 400;
                margin-bottom: 1.6rem;
                white-space: nowrap;
                cursor: pointer;
                
                a {
                    text-decoration: none;
                color: #fff;
                }
            }
        }
    }

    .copy {
        font-size: 1.6rem;
        font-weight: 500;
        text-align: center;
    }
}

@media (max-width: 1200px) {
    footer {
        .footer_group {
            flex-direction: column;

            & > div {
                margin-bottom: 2.4rem;
            }
        }
    }
}

@media (max-width: 1053px) {
footer {
.footer_group {
    padding: 0 18px;
}
}
}