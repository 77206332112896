.monitoring_card {
    padding: 24px;
    border-radius: 16px;
    border: 1px solid #E5E5E5;
    margin-bottom: 1.2rem;

    @media (max-width: 565px) {
      padding: 12px;
    }

    .monitoring_grid {
      display: grid;
      align-items: center;
      grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
      grid-column-gap: 24px;
      margin-bottom: 32px;

      @media (max-width: 768px) {
        gap: 12px;
      }

      & > div {
        & > :first-child {
          font-size: 14px;
          color: #1a1a1a;
          margin-bottom: 12px;

          @media (max-width: 768px) {
            font-size: 1.2rem;
            white-space: wrap;
          }
          @media (max-width: 565px) {
            font-size: 0.8rem;
            margin-bottom: 0.8rem;
          }
        }
        & > :last-child {
          font-size: 14px;
          color: #98a2b3;
          font-weight: 700;

          @media (max-width: 768px) {
            font-size: 1.2rem;
          }
          @media (max-width: 565px) {
            font-size: 0.8rem;
          }
        }
      }
    }
    .chart_container {
      display: flex;
      width: fit-content;
      align-items: center;
      margin: auto;
      .chart_inner {
        width: 656px;
        height: 392px;
        margin: auto;
        margin-left: 24px;
      }
      .chart_label {
        writing-mode: vertical-rl;
        transform: rotate(180deg);
        margin-left: 10px;
        white-space: nowrap;
        font-size: 14px;
        color: #1a1a1a;
      }
    }
  }