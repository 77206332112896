.admin_home {
  display: flex;

  .sidebar_container {
    // background-color: yellow;
    //  height: 100%;
    max-height: 700px;
    position: relative;

    @media (max-width: 1024px) {
      display: none;
    }
  }
  .main {
    max-width: 1800px;
    width: 100%;
    margin: auto;
    padding: 44px;
    height: 100vh;
    overflow: auto;
    display: grid;
    grid-template-columns: 1.5fr 1fr;
    grid-column-gap: 32px;
    
    @media (max-width: 1024px) {
      grid-template-columns: 1fr;
      flex-direction: column-reverse;
    }
    
        @media (max-width: 565px) {
          padding: 24px;
        }
    .main_inner {
      width: 100%;
      .admin_card_group {
        width: 100%;
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-column-gap: 3.2rem;

        @media (max-width: 565px) {
          grid-column-gap: 2rem;
        }

        .card {
          width: 100%;
          // height: 10rem;
          border-radius: 16px;
          background: #fafafa;
          box-shadow: 4px 0px 4px 0px rgba(0, 0, 0, 0.25),
            0px 4px 4px 0px rgba(0, 0, 0, 0.25);
          padding: 23px;

          .title {
            font-size: 1.2rem;
            color: #667185;
            margin-bottom: 1.9rem;

            @media (max-width: 565px) {
              font-size: 1rem;
            }
          }

          .value_group {
            display: flex;
            align-items: center;
            justify-content: space-between;

            .value {
              font-size: 1.4rem;
              font-weight: 700;
              color: #1a1a1a;

              @media (max-width: 565px) {
                font-size: 1.2rem;
              }
            }

            svg {
              width: 16px;
              height: 16px;
            }
          }
        }
      }

    

      .register-iot-desktop {
        @media (max-width: 1024px) {
          display: none;
        }
      }

      .card_full_max {
        height: 64rem;
        overflow-y: auto;

        &::-webkit-scrollbar {
          display: none;
        }
      }

      .total_emission_container {
        width: 100%;
        // height: 34rem;
        border-radius: 16px;
        border: 1px solid #E5E5E5;
        margin: 3.2rem 0;
        padding: 22px;

        .title {
          font-size: 1.4rem;
          font-weight: 700;
          color: #1a1a1a;
          margin-bottom: 1.6rem;
        }
      }

      .offset_credit {
        width: 100%;
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-column-gap: 3.2rem;

        @media (max-width: 565px) {
          grid-template-columns: 1fr;
          grid-row-gap: 2.4rem;

        }

        .card {
          width: 100%;
          border-radius: 16px 16px 0px 0px;
          border: 1px solid #E5E5E5;
          border-bottom: none;
          height: 44rem;
          padding: 24px;
          overflow-y: auto;

          &::-webkit-scrollbar {
            display: none;
          }

          .title {
            font-size: 1.6rem;
            font-weight: 500;
            color: #1a1a1a;
            margin-bottom: 1.6rem;

            @media (max-width: 565px) {
              font-size: 1.2rem;
            }
          }

          .offset_card {
            width: 100%;
            height: 5.5rem;
            border-radius: 12px;
            padding: 8px;
            border: 1px solid #E5E5E5;
            margin-bottom: 1.6rem;
            .offset_card_flex {
              display: flex;
              align-items: center;
              justify-content: space-between;
              margin-bottom: 0.4rem;
              .title {
                font-size: 1rem;
                font-weight: 500;
                margin: 0;
              }

              .subtitle {
                font-size: 0.8rem;
                margin: 0;
              }
            }

            .tag {
              font-size: 0.6rem;
              color: #667185;
            }
          }

          .offset_bar {
            width: 100%;
            height: 0.8rem;
            display: flex;
            align-items: center;
            background: rgba(133, 215, 114, 0.4);
            border-radius: 4px;
            margin-top: 0.8rem;
            .itemOne {
              height: 100%;
              border-radius: 4px;
              transition: 0.4s;
            }
            .itemOne20 {
              width: 20%;
              background: #85d772;
            }
            .itemOne50 {
              width: 50%;
              background: #70a625;
            }
            .itemOne100 {
              width: 100%;
              background: #7bc216;
            }
          }

          .creditDoughnutChart {
            width: 85%;
            margin: auto;
            display: flex;
            margin-bottom: 2.4rem;
          }

          .creditChartFlex {
            display: flex;
            align-items: center;
            margin-bottom: 1.2rem;

            & > :first-child {
              background: #85d772;
              padding: 11px 2px;
              color: #fff;
              font-weight: 500;
              border-radius: 4px;
              margin-right: 0.8rem;
              font-size: 0.8rem;
            }

            & > :last-child {
              font-size: 1.2rem;
            }
          }
          .creditChartFlexNonGreen {
            & > :first-child {
              background: #70a625;
            }
          }
        }
      }
    }
  }

  .sensor_modal {
    width: 100%;
    height: 100vh;
    position: absolute;
    top: 0;
    left: 0;
    background: rgba(0, 0, 0, 0.7);
    z-index: 1;
  }

  .card_full {
    width: 100%;
    min-height: 21.2rem;
    border-radius: 16px;
    background: #fafafa;
    box-shadow: 4px 0px 4px 0px rgba(0, 0, 0, 0.25),
      0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    padding: 23px;
    margin-bottom: 3.2rem;
    position: relative;

    @media (max-width: 1024px) {
      margin: auto;
      margin-top: 3.2rem;
      width: fit-content;
    }
    @media (max-width: 565px) {
      width: 100%;
    }

   

    .title {
      font-size: 1.2rem;
      font-weight: 700;
      color: #1a1a1a;
      margin-bottom: 1.8rem;
    }

    .title_full {
      font-size: 1.6rem;
      font-weight: 700;
      color: #1a1a1a;
      margin-bottom: 1.6rem;

      @media (max-width: 565px) {
        font-size: 1.2rem;
      }
    }

    .card_nav {
      width: 80%;
      margin: auto;
      display: flex;
      align-items: center;
      justify-content: space-between;
      height: 6rem;
      border-radius: 12px;
      border: 0.5px solid #d0d5dd;
      padding: 10px;
      margin-bottom: 1.4rem;

      .item {
        font-size: 1.4rem;
        font-weight: 700;
        color: #667185;
        width: 100px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 8px;
        height: 100%;
        cursor: pointer;

        @media (max-width: 565px) {
          width: 30%;
          font-size: 1.2rem;
        }
      }

      .item_active {
        background-color: #00aed9;
        color: #fff;
      }
    }

    .validation_card {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 1.6rem;

      @media (max-width: 565px) {
        margin-bottom: 1.2rem;
      }

      .validation_card_inner {
        display: flex;
        align-items: center;

        & > :first-child {
          width: 4.2rem;
          height: 4.2rem;
          background-color: #063;
          display: flex;
          justify-content: center;
          align-items: center;
          color: #fff;
          font-size: 1.4rem;
          border-radius: 50%;
          margin-right: 0.9rem;
          text-transform: uppercase;

          
        }

        .validation_items {
          & > :first-child {
            font-size: 1.4rem;
            color: #1a1a1a;

            @media (max-width: 565px) {
              font-size: 1.2rem;
            }
          }
          & > :last-child {
            font-size: 1.2rem;
            color: #98a2b3;

            @media (max-width: 565px) {
              font-size: 1rem;
            }
          }
        }
      }

      & > :last-child {
        color: #034592;
        font-size: 1.2rem;
        font-weight: 700;
      }
    }

    .sensor_grid {
      display: grid;
      grid-template-columns: 79px auto;
      grid-column-gap: 56px;
      align-items: center;
      margin-bottom: 1.8rem;

      @media (max-width: 565px) {
        grid-template-columns:  1fr 2.5fr !important;
      }

      .sensor_title {
        font-size: 1.2rem;
        @media (max-width: 565px) {
          white-space: nowrap;
          font-size: 1rem;
        }
      }
      .sensor_subtitle {
        font-size: 1.4rem;
        white-space: nowrap;
      }

      select {
        height: 3.2rem;
        border-radius: 12px;
        outline: none;
        // &__input-select {
          // padding-right: 1.5rem; /* Adjust the value as needed */
          appearance: none; /* Remove default dropdown icon */
          background-image: url('../../images/Vector.png'); /* Replace with your desired icon */
          background-repeat: no-repeat;
          background-position: right 1.8rem center; /* Adjust the position as needed */
          background-size: 1rem;
        // }

      }

      .sensor_flex {
        display: flex;
        align-items: center;
        flex-wrap: wrap;

        & > div:nth-child(odd) {
          margin-right: 3.2rem;
        }

        input {
          width: 24px;
          height: 24px;
          margin-right: 0.6rem;
        }

        // &>:last-child {
        //   margin-top: 1rem;
        // }
      }
    }
    .sensor_grid-mini {
      grid-template-columns: 79px 232px;
    }
    .sensor_button_container {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-top: 3rem;

      .sensor_button {
        width: 28.9rem;
        height: 5.2rem;
        font-size: 1.2rem;
        font-weight: 700;
        background: linear-gradient(116.29deg, #006633 0.76%, #02a4cc 100%);
        border-radius: 8px;
        border: none;
        outline: none;
        color: #fff;

        @media (max-width: 565px) {
          width: 20.7rem;
        }

        &:hover {
          transition: 0.7s;

          background: none;
          color: #006633;
          border: 1px solid #006633;
        }
      }
    }

    .sensor_modal_card {
      width: 28.9rem;
      min-height: 18rem;
      background-color: #fff;
      border-radius: 16px;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      padding: 24px;
      text-align: center;
      z-index: 2;

      .title {
        font-size: 1.6rem;
        font-weight: 600;
        color: #40b869;
        margin-bottom: 1.2rem;
      }

      .subtitle {
        font-size: 1.2rem;
        color: #667185;
      }
      button {
        width: 18.6rem;
        height: 3.3rem;
        font-size: 1.2rem;
        font-weight: 700;
        background: linear-gradient(116.29deg, #006633 0.76%, #02a4cc 100%);
        border-radius: 8px;
        border: none;
        outline: none;
        color: #fff;
        margin-top: 3.6rem;

        &:hover {
          transition: 0.7s;

          background: none;
          color: #006633;
          border: 1px solid #006633;
        }
      }
    }
  }

  .register-iot-ipad {
    display: none;

    @media (max-width: 1024px) {
      display: grid;
    }
  }
}
