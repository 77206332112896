.blog {
  .blog_inner {
    max-width: 1440px;
    width: 100%;
    margin: auto;
    padding: 61px 144px;

    .return {
      font-size: 1.6rem;
      color: #898989;
      font-weight: 500;
      margin-bottom: 4.8rem;
    }

    .title {
      font-size: 3.2rem;
      color: #141414;
      margin-bottom: 3.2rem;
      font-weight: 500;
      span {
        font-weight: 700;
      }
    }

    .thumbnail {
      display: flex;
      align-items: start;
      margin-bottom: 3.6rem;

      img {
        margin-right: 1.2rem;
        width: 7.4rem;
        height: 7.4rem;
        object-fit: cover;
        border-radius: 8px;
      }

      .title {
        font-size: 1.2rem;
        font-weight: 500;
        color: #141414;
        margin-bottom: 1.2rem;
      }

      .time {
        display: flex;
        // align-items: center;
        color: #898989;

        svg {
          margin: 0 0.3rem;
        }
      }
    }

    .banner {
      width: 100%;
      height: auto;
      border-radius: 8px;
      margin-bottom: 2.4rem;
    }

    .blogSubTitle {
      p {
        font-size: 1.8rem;
      font-weight: 500;
      color: #3b3b3b;
      margin-bottom: 2.4rem;
      background-color: yellow;
      }

      li {
        margin-bottom: 1.2rem;
      }
    }

    .blogTitle {
      color: #141414;
      margin-bottom: 1.2rem;
      font-size: 2.4rem;
      font-weight: 700;
    }

    .button_group {
      display: flex;
      align-items: center;
      margin-bottom: 16rem;

      & > div {
        width: fit-content;
        padding: 10.02px;
        color: #063;
        font-size: 1.2rem;
        font-weight: 400;
        border-radius: 12.026px;
        border: 1.002px solid var(--Grey, #898989);
        display: flex;
        align-items: center;
        justify-content: center;
      }

      & > :nth-child(even) {
        margin: 0 1.603rem;
      }
    }
  }
}

@media (max-width: 600px) {
  .blog {
    .blog_inner {
      padding: 32px 16px;

      .title {
        font-size: 2.4rem;
        margin-bottom: 1.6rem;
      }

      .thumbnail {
        img {
          width: 4rem;
          height: 4rem;
        }

    }
    .blogSubTitle {
      font-size: 1.4rem ;
    }
    .blogTitle {
      font-size: 1.6rem ;
      margin-bottom: 1.6rem;
    }

      .button_group {
        overflow: auto;
        &::-webkit-scrollbar {
          display: none;
        }

        & > div {
          min-width: 6.2rem !important;
          height: 2.1rem !important;
          font-size: 0.8rem;
        }
        & > :nth-child(even) {
          margin: 0 0.8rem;
        }
      }
    }
  }
}
