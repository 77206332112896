.user-resource {
    display: flex;
  .sidebar_container {
    // background-color: yellow;
    //  height: 100%;
    max-height: 700px;
    position: relative;

    @media (max-width: 1024px) {
      display: none;
    }
  }

  .main {
    background-color: #fff;
    max-width: 1440px;
    width: 100%;
    padding: 44px;
    height: 100vh;
    overflow: auto;
    margin: auto;

    @media (max-width: 565px) {
      padding: 24px;
    }

    &__title {
        font-size: 2.4rem;
        font-weight: 700;
        color: #101828;
        margin-bottom: 2.4rem;

        @media (max-width: 565px) {
          font-size: 1.4rem;
          margin-bottom: 1.2rem;
        }
    }

    &__body {
        h2 {
            font-size: 1.6rem;
            font-weight: 500;
            margin-bottom: 1.6rem;
            @media (max-width: 565px) {
              font-size: 1.2rem;
              margin-bottom: 0.8rem;
            }
        }

        p {
            font-size: 1.4rem;
            font-weight: 400;
            margin-bottom: 2.4rem;
            color: #667185;
            @media (max-width: 565px) {
              font-size: 0.8rem;
              margin-bottom: 1.2rem;
            }
        }
    }
  }
}