.admin_users {
  display: flex;
  .sidebar_container {
    // background-color: yellow;
    //  height: 100%;
    max-height: 700px;
    position: relative;

    @media (max-width: 1024px) {
      display: none;
    }
  }
  .main {
    max-width: 1800px;
    width: 100%;
    margin: auto;
    padding: 44px;
    height: 100vh;
    overflow: auto;

    @media (max-width: 565px) {
      padding: 24px;
    }

    .title {
      font-size: 2.4rem;
      font-weight: 700;
      color: #1a1a1a;
      margin-bottom: 2.4rem;

      @media (max-width: 768px) {
        font-size: 1.6rem;
        margin-bottom: 1.6rem;
      }
    }
    .subtitle {
      color: #1a1a1a;
      font-size: 1.6rem;
      font-weight: 500;
      margin-bottom: 1.6rem;
    }

    .users-admin_card_group {
      max-width: 1440px;
      width: 100%;
      display: grid;
      grid-template-columns: 1fr 1fr 1fr;
      grid-column-gap: 32px;
      margin-bottom: 3.2rem;

      @media (max-width: 565px) {
        grid-column-gap: 2rem;
      }

      .card {
        width: 100%;
        // height: 10rem;
        border-radius: 16px;
        background: #fafafa;
        box-shadow: 4px 0px 4px 0px rgba(0, 0, 0, 0.25),
          0px 4px 4px 0px rgba(0, 0, 0, 0.25);
        padding: 23px;

        .title {
          font-size: 1.2rem;
          color: #667185;
          margin-bottom: 1.9rem;

          @media (max-width: 565px) {
            font-size: 1rem;
          }
        }

        .value_group {
          display: flex;
          align-items: center;
          justify-content: space-between;

          .value {
            font-size: 1.4rem;
            font-weight: 700;
            color: #1a1a1a;

            @media (max-width: 565px) {
              font-size: 1.2rem;
            }
          }

          svg {
            width: 16px;
            height: 16px;
          }
        }
      }
    }
  }

  .users-create-third-party-user {
    width: 100%;
    display: flex;
    justify-content: end;
    margin-bottom: 20px;
    & >  button {
      border: none;
      outline: none;
      width: 15.4rem;
      height: 4.1rem;
        border-radius: 8px;
background: var(--Gradient, linear-gradient(116deg, #063 0.76%, #02A4CC 100%));
color: #FFF;
font-size: 1.4rem;
font-weight: 600;
      }
  }
  .users-table {
    width: 100%;
    height: 1200px;
    border: 1px solid #efefef;
    border-radius: 12px;
    position: relative;

    @media (max-width: 565px) {
      overflow-x: auto;
    }

    &__header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 0 24px;
      height: 64px;
      border-bottom: 1px solid #efefef;
      background: #f9fafb;
      border-radius: 12px 12px 0px 0px;

      
    @media (max-width: 565px) {
      width: 800px;
    }

      & > :first-child {
        display: flex;
        align-items: center;
        gap: 10px;
        background: #f0f2f5;
        border-radius: 8px;
        padding: 0 4px;
        height: 50px;

        button {
          min-width: 10.3rem;
          height: 4.2rem;
          outline: none;
          border-radius: 8px;
          background: none;
          border: none;
          color: #898989;
          font-size: 1.8rem;
          font-weight: 400;
        }

        .active {
          border: 1px solid #063;
          background: #fff;
          box-shadow: -2px 2px 2px 0px rgba(0, 102, 51, 0.05);
        }

        & > div {
          width: 1px;
          height: 19px;
          background: #e4e7ec;
        }
      }

      & > :last-child {
        button {
          min-width: 10.3rem;
          height: 4.2rem;
          outline: none;
          border-radius: 8px;
          border: none;
          color: #898989;
          font-size: 1.4rem;
          font-weight: 400;
          background: #f0f2f5;
        }

        .active {
          border: 1px solid #063;
          background: #fff;
          box-shadow: -2px 2px 2px 0px rgba(0, 102, 51, 0.05);
        }
      }
    }

    &__label {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
      color: #1a1a1a;
      align-items: center;
      font-size: 1.2rem;
      font-weight: 500;
      height: 44px;
      padding: 0 24px;
      border-bottom: 1px solid rgba(177, 177, 177, 0.4);
      background: #fafafa;
      gap: 20px;

      
    @media (max-width: 565px) {
      width: 800px;
    }
    }
    &__item {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
      align-items: center;
      height: 72px;
      background: #fff;
      padding: 0 24px;
      gap: 20px;
      cursor: pointer;

      
    @media (max-width: 565px) {
      width: 800px;
    }

      &:hover {
        border-top: 1px solid #063;
        border-bottom: 1px solid #063;
      }

      & > div {
        color: #1a1a1a;
        font-size: 1.4rem;
        font-weight: 500;
        word-wrap: break-word;
        word-break: break-all;
      }
    }
  }
}
