.blog {
  .hero {
    width: 100%;
    max-width: 1440px;
    padding: 0 144px;
    margin: auto;
    margin-bottom: 4.8rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 14.8rem;
    .title {
      font-size: 4.8rem;
      font-weight: 700;
      color: #141414;
      margin-bottom: 1.2rem;

      span {
        font-weight: 400;
        color: #0d924f;
      }
    }

    .subtitle {
      font-size: 1.6rem;
      font-weight: 400;
      color: #3b3b3b;
      margin-bottom: 3.2rem;
    }

    .group {
      display: flex;
      align-items: center;
      max-width: 41.4rem;
      width: 100%;
      height: 5.5rem;
      border-radius: 12px;
      border: 1px solid #898989;
      display: grid;
      grid-template-columns: 1fr 172px;

      input {
        // width: 100%;
        height: 100%;
        border: none;
        outline: none;
        background: transparent;
        font-size: 1.4rem;
        font-weight: 400;
        color: #898989;
        padding: 0 18px;
      }
      button {
        // width: 13.5rem;
        height: 100%;
        color: #fff;
        font-size: 1.6rem;
        font-weight: 500;
        border-radius: 12px;
        background-color: #063;
        border: none;
        outline: none;
        margin-left: 20px;
        padding: 0 18px;
      }
    }
  }

  .blogPosts {
    width: 100%;
    max-width: 1440px;
    padding: 0 144px;
    margin: auto;
    // background-color: yellow;

    .mainPost {
      display: grid;
      grid-template-columns: auto 662px;
      grid-column-gap: 24px;
      align-items: end;

      & > div {
        // background: yellow;
        .title {
          font-size: 3.6rem;
          font-weight: 700;
          color: #0d924f;
          margin-bottom: 2.3rem;
        }
        .subtitle_group {
          display: flex;
          align-items: center;
          justify-content: space-between;
          margin-bottom: 2.3rem;
          .subtitle {
            font-size: 1.4rem;
            color: #898989;
            span {
              color: #0d924f;
            }
          }
        }

        .second_title {
          font-size: 3.2rem;
          font-weight: 700;
          color: #141414;
          margin-bottom: 1.6rem;
        }

        .second_subtitle {
          font-size: 1.6rem;
          font-weight: 400;
          color: #3b3b3b;
          margin-bottom: 2.4rem;
        }

        .time {
          font-size: 1.6rem;
          font-weight: 400;
          color: #898989;
          margin-bottom: 2.3rem;
        }

        .button_group {
          display: flex;
          align-items: center;

          & > div {
            width: fit-content;
            padding: 10.02px;
            color: #063;
            font-size: 1.2rem;
            font-weight: 400;
            border-radius: 12.026px;
            border: 1.002px solid var(--Grey, #898989);
            display: flex;
            align-items: center;
            justify-content: center;
          }

          & > :nth-child(even) {
            margin: 0 1.603rem;
          }
        }
      }

      .check {
        background-color: pink;
        height: 400px;
      }

      img {
        border-radius: 12px;
        width: 100%;
        height: auto;
      }
    }

    .grid1 {
      grid-template-columns: 1.7fr 1.2fr;
    }

    .gridMain {
      display: grid;
      gap: 25.69px;
      margin-top: 2.4rem;

      & > div {
        width: fit-content;
        // height: 100px;
        // background-color: green;
        width: 100%;

        img {
          width: 100%;
          // height: auto;
          height: 30.2rem;
          object-fit: cover;
          border-radius: 12px;
          margin-bottom: 2.4rem;
        }

        .subtitle_group {
          display: flex;
          align-items: center;
          justify-content: space-between;
          margin-bottom: 2.3rem;

          .subtitle {
            font-size: 1.4rem;
            color: #898989;
            span {
              color: #0d924f;
            }
          }
        }

        .second_title {
          font-size: 3.2rem;
          font-weight: 700;
          color: #141414;
          margin-bottom: 1.6rem;
        }

        .second_subtitle {
          font-size: 1.6rem;
          font-weight: 400;
          color: #3b3b3b;
          margin-bottom: 2.4rem;
        }

        .time {
          font-size: 1.6rem;
          font-weight: 400;
          color: #898989;
          margin-bottom: 2.3rem;
        }

        .button_group {
          display: flex;
          align-items: center;

          & > div {
            width: fit-content;
            padding: 10.02px;
            color: #063;
            font-size: 1.2rem;
            font-weight: 400;
            border-radius: 12.026px;
            border: 1.002px solid var(--Grey, #898989);
            display: flex;
            align-items: center;
            justify-content: center;
          }

          & > :nth-child(even) {
            margin: 0 1.603rem;
          }
        }
      }
    }

    .grid2 {
      grid-template-columns: 1.2fr 1.7fr;
    }

    .interviews {
      margin-top: 4.8rem;
      .title {
        font-size: 3.6rem;
        color: #0d924f;
        margin-bottom: 2.4rem;
        font-weight: 700;
      }
      .interviewGrid {
        overflow: auto;
        display: flex;
        align-items: start;

        &::-webkit-scrollbar {
          display: none;
        }

        & > div {
          & > div {
            width: 36.5rem;
          }
        }
      }
    }
    .stories {
      margin-top: 4.8rem;
      margin-bottom: 18rem;
      // background: #bbb;
      .title {
        font-size: 3.6rem;
        color: #0d924f;
        margin-bottom: 2.4rem;
        font-weight: 700;
      }
      .storiesGrid {
        overflow: auto;
        display: flex;
        align-items: start;
        // justify-content: center;

        &::-webkit-scrollbar {
          display: none;
        }

        & > div {
          width: fit-content;
          & > div {
            width: 36.5rem;
          }
        }
      }
    }
  }
}

@media (max-width: 1200px) {
  .blog {
    .blogPosts {
      .mainPost {
        grid-template-columns: 1fr;
        margin-bottom: 1.6rem;

        & > div {
          margin-bottom: 1.631rem;
        }
        img {
          height: 30.2rem;
          object-fit: cover;
        }
      }

      .grid1,
      .grid2 {
        grid-template-columns: 1fr;
      }

      .gridMain {
        margin-top: 0;
        gap: 0;
        & > div {
          display: flex;
          flex-direction: column-reverse;
          // background-color: yellow;

          img {
            margin-bottom: 1.6rem;
          }

          & > div {
            margin-bottom: 1.6rem;
          }

         
        }
      }
    }
  }

 


}


@media (max-width: 600px) {
 .blog {
  .hero {
    padding: 32px 16px;
    text-align: center;
    margin-top: 0;
    margin-bottom: 0;

    .title {
      font-size: 2.4rem;
      margin-bottom: 1.2rem;
    }

    .subtitle {
      font-size: 1.2rem;
      margin-bottom: 2.4rem;
    }

    .group {
      max-width: 35.6rem;
      width: 100%;

    

      button {
        width: fit-content;
      }
    }
  }

  .blogPosts {
    padding: 0 16px;

    .mainPost {
      & > div {
        .title {
          font-size: 2.4rem;
          margin-bottom: 1.6rem;
        }
        .subtitle_group {
          margin-bottom: 0;
        .subtitle {
font-size: 0.8rem;
        }
      }

      .second_title {
        font-size: 2.4rem;
          margin-bottom: 1.2rem;
      }

      .second_subtitle { 
        font-size: 1.4rem;
        margin-bottom: 1.2rem;
      }

      .time {
        font-size: 0.8rem;
        margin-bottom: 1.2rem;
      }
      }
    }

    .gridMain {
      & > div {

        .subtitle_group {

          margin-bottom: 1.2rem;

          .subtitle {
            font-size: 0.8rem;
         
          }
        }
        .second_title {
          font-size: 2.4rem;
          margin-bottom: 1.2rem;
        }

        .second_subtitle {
        font-size: 1.4rem;
        margin-bottom: 1.2rem;
        }
        
      .time {
        font-size: 0.8rem;
        margin-bottom: 1.2rem;
      }
      }
    }

    .interviews {
      margin-top: 3rem;
      .title {
        font-size: 2.4rem;
        margin-bottom: 1.6rem;
      }
      .interviewGrid {
       

        & > div {
          margin-right: 1.25rem;
          .second_title {
            font-size: 1.6rem;
            margin-bottom: 1.2rem;
          }
          .second_subtitle {
            font-size: 0.8rem;
            margin-bottom: 1.2rem;
            }

           
          & > div {
            display: flex;
            img {
              margin-right: 1.2rem !important;
            }
           & > div {
            .button_group{
              .button {
                min-width: 6.2rem !important;
                height: 2.1rem !important;
                font-size: 0.8rem;
              }

              & > :nth-child(even) {
                margin: 0 0.8rem;
              }
            }
           }
            img {
              width: 11.1rem;
              height: 14.8rem;
              margin-right: 1.2rem !important;
            }
          }
        }
      }
    }
    .stories {
      margin-top: 0;
      .title {
        font-size: 2.4rem;
        margin-bottom: 1.6rem;
      }
      .storiesGrid {
       

        & > div {
          margin-right: 1.25rem;

          .second_title {
            font-size: 1.6rem;
            margin-bottom: 1.2rem;
          }
          .second_subtitle {
            font-size: 0.8rem;
            margin-bottom: 1.2rem;
            }

           
          & > div {
            display: flex;
            img {
              margin-right: 1.2rem !important;
            }
           & > div {
            .button_group{
              .button {
                min-width: 6.2rem !important;
                height: 2.1rem !important;
                font-size: 0.8rem;

              }
              & > :nth-child(even) {
                margin: 0 0.8rem;
              }
            }
           }
            img {
              width: 11.1rem;
              height: 14.8rem;
              margin-right: 1.2rem !important;
            }
          }
        }
      }
    }

    
  
  }
 }
}