.admin_carbon-credit {
  display: flex;

  .sidebar_container {
    // background-color: yellow;
    //  height: 100%;
    max-height: 700px;
    position: relative;

    @media (max-width: 1024px) {
      display: none;
    }
  }

  .main {
    max-width: 1800px;
    width: 100%;
    margin: auto;
    padding: 44px;
    height: 100vh;
    overflow: auto;

    @media (max-width: 565px) {
      padding: 24px;
    }

    .title {
      font-size: 2.4rem;
      font-weight: 700;
      color: #1a1a1a;
      margin-bottom: 2.4rem;

      @media (max-width: 768px) {
        font-size: 1.6rem;
        margin-bottom: 1.6rem;
      }
    }

    .carbon-credit-table {
      width: 100%;
      height: 1200px;
      border: 1px solid #efefef;
      border-radius: 12px;
      position: relative;
      overflow-x: auto;

      &__header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0 24px;
        height: 49px;
        border-bottom: 1px solid #efefef;
        background: #f9fafb;
        border-radius: 12px 12px 0px 0px;
        color: var(--Net-Black, #1A1A1A);
font-size: 1.4rem;
font-weight: 500;

@media (max-width: 768px) {
  width: 800px;
}


      }

      &__label {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
        color: #1a1a1a;
        align-items: center;
        font-size: 1.2rem;
        font-weight: 500;
        height: 44px;
        padding: 0 24px;
        border-bottom: 1px solid rgba(177, 177, 177, 0.4);
        background: #fafafa;
        gap: 20px;

        @media (max-width: 768px) {
          width: 800px;
        }
      }
      &__item {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
        align-items: center;
        height: 72px;
        background: #fff;
        padding: 0 24px;
        gap: 20px;
        cursor: pointer;
        @media (max-width: 768px) {
          width: 800px;
        }
        &:hover {
          border-top: 1px solid #063;
          border-bottom: 1px solid #063;
        }

        & > div {
          color: #1a1a1a;
          font-size: 1.4rem;
          font-weight: 500;
          word-wrap: break-word;
          word-break: break-all;
        }

       & >  button {
        border: none;
        outline: none;
        width: 15.4rem;
        height: 4.1rem;
          border-radius: 8px;
background: var(--Gradient, linear-gradient(116deg, #063 0.76%, #02A4CC 100%));
color: #FFF;
font-size: 1.4rem;
font-weight: 600;
        }
      }
    }
  }
}
