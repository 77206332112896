.about {
  .hero {
    min-height: 56.8rem;
    width: 100%;
    max-width: 1440px;
    padding: 61px 144px;
    margin: auto;
    display: flex;
    align-items: center;

    & > :first-child {
      margin-right: 2.4rem;
      .title {
        font-size: 6.4rem;
        font-weight: 400;
        color: #141414;
        margin-bottom: 2.4rem;

        span {
          color: #0d924f;
          font-weight: 500;
        }
      }

      .subtitle {
        font-size: 1.6rem;
        font-weight: 400;
        max-width: 56.4rem;
        color: #3b3b3b;
      }
    }

    & > img {
      width: 100%;
      max-width: 467px;
    }
  }

  .who {
    // height: 56.8rem;

    background: linear-gradient(
      100deg,
      rgba(198, 232, 215, 0.3) 0%,
      rgba(22, 161, 89, 0.3) 50.09%,
      rgba(5, 102, 53, 0.3) 98.75%
    );
    .who_inner {
      width: 100%;
      max-width: 1440px;
      padding: 61px 0 61px 144px;
      margin: auto;
      display: flex;
      justify-content: center;
      // grid-template-columns: 1fr 1fr;
      // grid-column-gap: 48px;
      align-items: center;

      & > :first-child {
        width: 56.4rem;
        .title {
          font-size: 6.4rem;
          font-weight: 500;
          color: #0d924f;
          margin-bottom: 1.6rem;
        }

        .subtitle {
          font-size: 1.6rem;
          font-weight: 500;
          color: #3b3b3b;
        }
      }

      & > :last-child {
        // background-color: green;
      }
    }
  }

  .offer {
    width: 100%;
    max-width: 1440px;
    margin: auto;
    padding: 64px 144px;
    & > div {
      display: grid;
      align-items: center;
      grid-template-columns: 1fr 1fr;
      grid-column-gap: 26px;
      grid-row-gap: 48px;

      img {
        width: 100%;
        height: auto;
      }

      .title {
        font-size: 4.8rem;
        font-weight: 500;
        color: #141414;
        margin-bottom: 1.6rem;
      }
      .subtitle {
        font-size: 1.6rem;
        font-weight: 500;
        color: #3b3b3b;
      }
    }
  }

  .experience {
    background-color: #fafafa;
    margin-bottom: 12.6rem;

    .experience_inner {
      width: 100%;
      max-width: 1440px;
      padding: 64px 144px;
      margin: auto;
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-column-gap: 75px;
      & > div {
        margin-top: 8.9rem;
        .title {
          font-size: 4.8rem;
          font-weight: 500;
          color: #141414;
          margin-bottom: 1.6rem;
        }
        .subtitle {
          font-size: 1.6rem;
          font-weight: 500;
          color: #3b3b3b;
          margin-bottom: 2.4rem;
        }

        & > :last-child {
          color: #0d924f;
          font-size: 1.6rem;
          font-weight: 500;
          text-decoration: underline;
        }
      }
    }
  }
}

.join_us_modal {
  width: 100%;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  // background: rgb(0, 0, 0);
  z-index: 3;
  .join_us {
    //   display: flex;
    //   flex-direction: column;
    //   align-items: center;
    //   justify-content: center;
    width: 83.8rem;
    height: 100vh;
    overflow: auto;
    position: fixed;
    padding: 24px;
    box-shadow: 0px 20px 40px 0px rgba(0, 0, 0, 0.1);
    &::-webkit-scrollbar {
      display: none;
    }

    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: #fff;

    .close {
      width: 100%;
      display: flex;
      justify-content: end;
      margin-bottom: 1.6rem;
    }

    .title {
      font-size: 3.2rem;
      font-weight: 500;
      color: #141414;
      margin-bottom: 1.2rem;
      text-align: center;
    }

    .subtitle {
      font-weight: 400;
      font-size: 1.6rem;
      color: #3b3b3b;
      text-align: center;
      margin: auto;
      margin-bottom: 4.467rem;
      width: 45.1rem;
    }
    .join_us_group {
      // overflow: auto;
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-column-gap: 24px;
      height: 100%;
      width: 100%;

      & > :first-child {
        width: 100%;
        height: 62.9rem;
        margin-right: 2.4rem;
        color: #fff;
        padding: 46px 10px 10px 50px;
        background: url("../../images/Frame\ 23.png");
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
        border-radius: 12px;
        font-size: 4.6rem;
        font-weight: 400;
        color: #fff;
        line-height: 72px;
      }
      & > :last-child {
        form {
          width: 100%;
          max-width: 68.2rem;
          // padding: ;
          margin: auto;
          display: flex;
          // margin-top: 6.4rem;
          // margin-bottom: 21.6rem;
          flex-direction: column;
          align-items: center;

          .input_group {
            width: 100%;

            label {
              font-size: 1.2rem;
              font-weight: 500;
              color: #141414;
              white-space: nowrap;

              span {
                color: rgba(243, 29, 29, 1);
                font-size: 1.6rem;
              }
            }
            .input_group_inner {
              display: flex;
              align-items: center;
              width: 100%;
              height: 5.6rem;
              border: 0.5px solid #898989;
              border-radius: 12px;
              padding: 12px;
              margin-bottom: 1.8rem;
              margin-top: 1.2rem;

              input {
                width: 100%;
                height: 100%;
                border: none;
                outline: none;

                &::placeholder {
                  font-size: 0.8rem;
                  position: absolute;
                  top: 50%;
                  transform: translateY(-50%);
                }
                //   background-color: yellow;
              }

              .divider {
                width: 0.05rem;
                height: 1.4rem;
                background-color: #063;
                margin: 0 0.8rem;
              }
            }

            .input_textarea {
              height: 13.5rem;
              align-items: start;
              textarea {
                width: 100%;
                height: 100%;
                border: none;
                outline: none;
                // background-color: yellow;
                padding: 0;

                &::placeholder {
                  font-size: 0.8rem;
                  position: absolute;
                  top: 3px;
                }
              }
            }
          }

          button {
            width: 32.4rem;
            height: 6.7rem !important;
            background-color: #063;
            font-size: 1.6rem;
            font-weight: 500;
            border: none;
            outline: none;
            border-radius: 12px;
            color: #fff;
            margin-top: 0.6rem;

            transition: 0.4s;

            &:hover {
              background-color: #0D924F;
            }
          }
        }
      }
    }
  }
}

@media (max-width: 1200px) {
  .about {
    .hero {
      flex-direction: column;
      & > :first-child {
        margin-right: 0 !important;
      }
      img {
        width: 100%;
        height: auto;
      }
    }
  }

  .who {
    .who_inner {
      flex-direction: column;
      align-items: start !important;
    //   background: pink;
      justify-content: start !important;
      padding: 61px 144px !important;
      min-height: 700px;

      & > :first-child {
        width: 100% !important;
        margin-bottom: 3.2rem;
      }

      img {
        width: 100%;
        height: auto;
        // background-color: teal;
        padding: 10px;
      }
    }
  }

  .offer {
    & > div {
        grid-template-columns: 1fr !important;
        grid-template-rows: auto !important;

        .first_img {
            grid-row: 2 !important;
    }
        //  & > div {
        //     grid-row: 1 !important;
        //  }
        // img {
        //     grid-row: 2 !important;
        // }
    }
  }

  .experience {
    .experience_inner {
        grid-template-columns: 1fr !important;

        & > div {
          margin-top: 0 !important;
            margin-bottom: 2.4rem;
        }
         img {
            width: 100%;
            height: auto;
         }
    }
  }
}

@media (max-width: 600px) {
  .about {
    .hero {
      padding: 32px 16px;
      text-align: center;
      & > :first-child {
      .title {
        font-size: 2.4rem;
        margin-bottom: 1.6rem;
      }

      .subtitle {
        font-size: 1.4rem;
      }
    }
    }

    .who {
     .who_inner {
      padding: 54px 32px !important;

      & > :first-child {
        width: 100%;

        .title {
          font-size: 2.4rem;
          margin-bottom: 1.2rem;
        }
        .subtitle {
          font-size: 1.4rem;
          margin-bottom: 3.2rem;
        }
      }
     }

    }

    .offer {
      padding: 24px 16px;

      & > div {
        grid-row-gap: 24px;

        .title {
          font-size: 2.4rem;
          margin-bottom: 1.2rem;
        }

        .subtitle {
          font-size: 1.4rem;
          font-weight: 400;
          color: #898989;
          line-height: 21px;
        }
      }

    }

    .experience {
      .experience_inner {
        padding: 24px 16px;
        & > div {
        
          .title {
            font-size: 2.4rem;
            margin-bottom: 1.2rem;
          }
          .subtitle {
            font-size: 1.4rem;
            margin-bottom: 1.6rem;
            color: #898989;
            font-weight: 400;
            line-height: 21px;
          }
        }
      }
    }
  }

  .join_us_modal {
    .join_us {
    width: 100%;

    .title {
      font-size: 2.4rem;
      margin-bottom: 1.2rem;
    }

    .subtitle {
      font-size: 1.4rem;
      width: 100%;
    }
    .join_us_group {
      display: flex;
      flex-direction: column;
    grid-template-columns: unset;

    & > :first-child {
      font-size: 2.4rem;
      line-height: 36px;
      padding: 27px 12px;
      height: 19.5rem;
      margin-bottom: 2.4rem;
      background: url('../../images/mobile_cover.png');
      background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
    }

    }
    }
  }
}
