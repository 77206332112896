.user-resources {
  display: flex;
  .sidebar_container {
    // background-color: yellow;
    //  height: 100%;
    max-height: 700px;
    position: relative;

    @media (max-width: 1024px) {
      display: none;
    }
  }

  .main {
    background-color: #fff;
    max-width: 1440px;
    width: 100%;
    padding: 44px;
    height: 100vh;
    overflow: auto;
    margin: auto;

    @media (max-width: 565px) {
      padding: 24px;
    }

    .user-resources__grid {
      width: 100%;
      display: grid;
      grid-template-columns: 1fr 1fr 1fr;
      gap: 32px;
      justify-content: center;
      align-items: center;

      @media (max-width: 1024px) {
        grid-template-columns: 1fr 1fr;
      }

      &__card {
        width: 100%;
        min-height: 24.5rem;
        border-radius: 8px;
        border: 1px solid #E5E5E5;
        cursor: pointer;
        transition: 0.7s;

        &:hover {
            transform: scale(1.06);
        }

        .grid-card__title {
          // background: blue;
          width: 100%;
          height: 4.3rem;
          border-radius: 12px 12px 0px 0px;
          border-bottom: 1px solid #E5E5E5;
          font-size: 1.6rem;
          font-weight: 600;
          padding: 0 16px;
          color: #101828;

          @media (max-width: 565px) {
            font-size: 1.2rem;
            padding: 0 8px;
            height: 3.1rem;
          }
          

          display: flex;
          align-items: center;
        }

        .grid-card__content {
          padding: 16px 8px;

          .grid-card__content-title {
            font-size: 1.2rem;
            color: #1a1a1a;
            margin-bottom: 0.8rem;
          }
          .grid-card__content-subtitle {
            font-size: 1.2rem;
            color: #98a2b3;

            @media (max-width: 565px) {
              font-size: 0.8rem;
            }
          }

          .grid-card__content-divider {
            width: 95%;
            height: 1px;
            background: rgba(102, 113, 133, 0.4);
            margin: 0.8rem auto;
          }

        }
        .grid-card__content-bottomtitle {
          border-radius: 0px 0px 12px 12px;
          border-top: 1px solid #E5E5E5;
          background: linear-gradient(116deg, #063 0.76%, #02a4cc 100%);
          height: 4.8rem;
          padding: 0 16px;
          display: flex;
          align-items: center;
          justify-content: center;
          font-size: 1.4rem;
          font-weight: 700;
          color: #FFF;

          @media (max-width: 565px) {
            font-size: 1rem;
          }

          svg {
            margin-left: 8px;

            @media (max-width: 565px) {
              width: 16px;
              height: 16px;
            }
          }
        }
      }
    }

    @media (max-width: 565px) {
      padding: 24px;
    }
  }
}
