@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap");

html {
  font-size: 62.5%;
  scroll-padding-top: 120px; 
  /* scroll-behavior: smooth; */
}

* {
  /* color: #667185; */
  box-sizing: border-box;
  font-family: "Inter", sans-serif;
  /* font-size: 12px; */

}

/********************* Presets *********************/

.error {
  color: #d42620 !important;
}

.pointer, button {
  cursor: pointer;
}

.bold {
  font-weight: 700;
}

.center {
  text-align: center;
}

.headerDivider{
  margin-bottom: 6.4rem;
}

.mobile {
  display: none !important;
}


/********************* Presets *********************/


/********************* Help *********************/


.help_icon {
  position: fixed;
  bottom: 8rem;
  right: 3rem;
  z-index: 2;
}

.help_modal {
  position: fixed;
  bottom: 3rem;
  right: 30rem;
  width: 43.1rem;
  min-height: 23.1rem;
  padding: 12px 0;
  border-radius: 16px;
  background-color: #FFF;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  z-index: 2;
}

.help_modal .title {
  font-size: 2rem;
  font-weight: 500;
  margin-bottom: 0.4rem;

}
.help_modal .subtitle {
  font-size: 1.4rem;
  font-weight: 400;
  color: #667185;
  margin-bottom: 1.6rem;
  text-align: center;

  @media (max-width: 565px) {
    font-size: 1.2rem;
  }
}

.help_modal .help_link {
  color: #00AED9;
  text-decoration: underline;
  font-size: 1.2rem;
  font-weight: 600;
}

.help_modal_cover{
  width: 100%;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    background: rgba(0, 0, 0, 0.7);
    z-index: 1;
}
/********************* Help *********************/


@media (max-width: 600px) {
  /********************* Presets *********************/

  .mobile_none {
    display: none !important;
  }

  .mobile {
    display: initial !important;
  }

  /********************* Presets *********************/

}
@media (min-width: 1600px) {
  html {
    font-size: 79.5%;
  }

}
@media (max-width: 1024px) {
  .help_modal {
    bottom: 50%;
    right: 50%;
    transform: translate(50%, 50%);
    width: 85%;
  }

}

@media (max-width: 565px) {
  .help_modal .title {
    font-size: 1.8rem;
  }

  .help_modal .subtitle {
      font-size: 1.2rem;
  }

}