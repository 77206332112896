.ndc {
  display: flex;

  .sidebar_container {
    @media (max-width: 1024px) {
      display: none;
    }
  }

  .ndc_main {
    max-width: 1800px;
    width: 100%;
    margin: auto;
    padding: 44px;

    @media (max-width: 565px) {
      padding: 24px;
    }

    .ndc-main-inner {
      position: relative;
    width: 100%;
    max-width: 1005px;
    height: 100vh;
    overflow: auto;
    // margin: auto;

    &::-webkit-scrollbar {
      display: none;
    }

    
      .ndc_title {
        font-size: 2.4rem;
        font-weight: 700;
        color: #1a1a1a;
        margin-bottom: 1.6rem;
        @media (max-width: 565px) {
          font-size: 1.4rem;
        }

      }
  
      .ndc_subtitle {
        color: #1a1a1a;
        font-size: 1.6rem;
        font-weight: 400;
        line-height: 150%;
        @media (max-width: 565px) {
          font-size: 1.2rem;
        }
      }
  
      .ndc_paragraph {
        margin-top: 3.2rem;
  
        color: #1a1a1a;
        font-size: 1.8rem;
        font-style: normal;
        font-weight: 500;
        line-height: 150%; /* 27px */
        margin-bottom: 1.6rem;
      }
  
      .ndc_terms {
        display: flex;
        align-items: center;
        gap: 16px;
        @media (max-width: 565px) {
          margin-top: 8rem;
        }
  
        input {
          width: 24px;
          height: 24px;
          border-radius: 6px !important;
        }
        & > div {
          color: #1a1a1a;
  
          font-size: 1.4rem;
          font-weight: 400;

          @media (max-width: 565px) {
            font-size: 1rem;
          }
  
          span {
            color: #00aed9;
            font-weight: 700;
          }
        }
      }
  
      .ndc_button {
        width: 100%;
        height: 81px;
        border-radius: 12px;
        background: linear-gradient(116.29deg, #006633 0.76%, #00aed9 100%);
        display: flex;
        align-items: center;
        justify-content: center;
        border: none;
        outline: none;
        color: #ffffff;
        font-size: 1.6rem;
        font-weight: 600;
        cursor: pointer;
        margin-top: 5.15rem;
        transition: 0.7s;
  
        @media (max-width: 768px) {
          margin-top: 4rem;
        }
  
        &:hover {
          transition: 0.7s;
  
          background: none;
          color: #006633;
          border: 1px solid #006633;
        }
      }
  
      &__empty {
        display: flex;
        align-items: center;
        flex-direction: column;
        justify-content: center;
        color: #1a1a1a;
        text-align: center;
        font-size: 1.8rem;
        font-weight: 700;
        width: 100%;
        height: 400px;
  
        svg {
          margin-bottom: 2.4rem;
        }
      }
    }
  }
}
