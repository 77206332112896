.dashboard_home {
  display: flex;
  .sidebar_container {
    // background-color: yellow;
    //  height: 100%;
    max-height: 700px;
    position: relative;

    @media (max-width: 1024px) {
      display: none;
    }
  }

  .main {
    max-width: 1440px;
    width: 100%;
    margin: auto;
    height: 100vh;
    background-color: #fff;
    // background-color: yellow;
    padding: 44px;
    overflow-x: auto;

    @media (max-width: 565px) {
      padding: 24px;
    }

    &__ai-banner {
      width: 100%;
      max-width: 1100px;
      height: 60px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      font-size: 1.4rem;
      font-weight: 400;
      color: #1a1a1a;
      border-radius: 8px;
      border: 1px solid var(--Gradient, #063);
      background: rgba(0, 126, 112, 0.1);
      margin-bottom: 37px;
      padding: 0 24px;

      @media (max-width: 565px) {
        font-size: 10px;
        padding: 0 10px;
      }

      & > :first-child {
        display: flex;
        align-items: center;
        gap: 8px;

        & > div {
          & > span {
            background: var(
              --Gradient,
              linear-gradient(116deg, #063 0.76%, #02a4cc 100%)
            );
            background-clip: text;
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
            font-family: Inter;
            font-size: 1.4rem;
            font-weight: 700;
            text-decoration-line: underline;
            cursor: pointer;

            @media (max-width: 565px) {
              font-size: 12px;
            }
          }
        }
      }

      & > svg {
        cursor: pointer;
      }
    }

    .search_container {
      position: relative;
    }

    .carbon_card_group {
      width: 100%;
      display: grid;
      grid-template-columns: 1fr 1fr 1.3fr;
      grid-column-gap: 78px;
      margin-bottom: 32px;

      @media (max-width: 768px) {
        overflow: auto;
        grid-column-gap: 20px;
      }

      .card {
        position: relative;
        width: 100%;
        height: 19.5rem;
        background-color: #fff;
        border: 1px solid #e5e5e5;
        border-radius: 8px;
        padding: 24px;

        @media (max-width: 768px) {
          width: 252px;
        }

        &__last-sync-date {
          font-weight: 400;
          color: #667185;
        }
        .doughnutChartx {
          width: 100%;
          height: 140px;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;

          &__title {
            font-size: 14px;
            font-weight: 700;
            margin-top: 1.2rem;
          }
        }

        .title_group {
          display: flex;
          align-items: center;
          justify-content: space-between;

          .title {
            font-size: 1.4rem;
            font-weight: 700;
            margin-bottom: 1.6rem;
            color: #282828;

            @media (max-width: 768px) {
              font-size: 1.2rem;
            }
          }

          svg {
            margin-top: -20px;
            cursor: pointer;
          }
        }

        .title {
          font-size: 1.4rem;
          font-weight: 700;
          margin-bottom: 0.5rem;
          color: #282828;

          @media (max-width: 768px) {
            font-size: 1.2rem;
          }
        }

        .credit {
          margin-bottom: 1.6rem !important;
        }

        .subtitle {
          font-size: 1rem;
          margin-top: -1.4rem;
          margin-bottom: 1.6rem;
          color: #667185;

          @media (max-width: 768px) {
            font-size: 0.8rem;
          }
        }
        .emission_container {
          display: flex;
          align-items: center;

          .emission_label {
            display: flex;
            align-items: center;

            .color {
              width: 1rem;
              height: 1rem;
              background-color: #2c9963;
              border-radius: 4px;
              margin-right: 4px;
            }

            .label_text {
              font-size: 0.8rem;
              color: #141414;
              font-weight: 500;
            }
          }

          .doughnutChartx {
            width: 100%;
            height: 140px;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;

            &__title {
              font-size: 14px;
              font-weight: 700;
              margin-top: 1.2rem;
            }
          }

          .total_emission {
            width: 13.5rem;
            height: 13.5rem;
            // border-radius: 50%;
            font-size: 2rem;
            font-weight: 600;
            // background-color: #00aed9;
            // background-color: yellow;
            display: flex;
            align-items: center;
            justify-content: center;
            color: #ffffff;
            position: relative;
            margin-right: 3.2rem;

            #doughnutChart {
            }

            .title {
              position: absolute;
              top: 54%;
              left: 50%;
              font-size: 0.9rem;
              margin: 0 !important;
              font-weight: 700 !important;

              transform: translate(-50%, -50%);
            }
          }
        }

        .offset_view {
          display: flex;
          align-items: center;
          height: 20px;
          width: 100%;
          // background-color: yellow;

          .offset_view_carbon {
            width: 60%;
            background-color: #f0f2f5;
            height: 100%;
            border-radius: 4px 0 0 4px;
            color: #141414;
            padding: 0 17px;
            display: flex;
            align-items: center;
          }

          .offset_view_credit {
            width: 40%;
            background-color: #2c9963;
            height: 100%;
            border-radius: 0 4px 4px 0;
            color: #fff;
            padding: 0 8px;
            display: flex;
            align-items: center;
          }
        }

        .offset_meter {
          width: 153.29px;
          height: 77.43px;
          position: relative;
          margin: auto;

          .meter_active {
            background: #2c9963;
          }

          .meter_inactive {
            background: #e4e7ec;
          }

          .which {
            background-color: yellow;
          }
        }

        .metric {
          font-size: 0.8rem;
          width: 153.29px;
          height: 20px;
          // background-color: yellow;
          margin: auto;
          display: flex;
          justify-content: space-between;
          align-items: center;
          margin-top: 0.8rem;

          .span {
            text-align: right;
          }

          .active {
            & > :first-child {
              color: #2c9963;
            }
          }
        }

        .goal_modal {
          position: absolute;
          top: 50%;
          left: 50%;
          padding: 22px;
          z-index: 2;

          transform: translate(-50%, -50%);
          width: 28rem;
          height: 19.5rem;
          background-color: #fafafa !important;
          border-radius: 16px;
          box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25),
            4px 0px 4px 0px rgba(0, 0, 0, 0.25);

          .goal_flex {
            display: flex;
            align-items: center;
            justify-content: space-between;

            .title {
              color: #00aed9;
              font-size: 1.4rem;
              font-weight: 700;
            }

            svg {
              margin-top: -2rem;
              cursor: pointer;
            }
          }

          label {
            font-size: 0.8rem;
          }

          &__input {
            display: flex;
            align-items: center;
            gap: 5px;
            font-size: 12px;
            font-weight: 500;
            color: #7b7b7b;
            & > input {
              width: 100%;
              height: 2.8rem;
              border-radius: 12px;
              outline: none;
              font-size: 0.8rem;
              border: 1px solid #e5e5e5;
              padding-left: 24px;
              margin-top: 0.4rem;
            }
          }

          .goal_button {
            width: 100%;
            height: 41px;
            outline: none;
            border: none;
            border-radius: 12px;
            background: linear-gradient(116.29deg, #006633 0.76%, #02a4cc 100%);
            font-size: 1.6rem;
            font-weight: 600;
            color: #fff;
            margin-top: 2rem;
          }
        }
      }
    }

    .activity_card_group {
      display: grid;
      grid-template-columns: 1fr;
      grid-column-gap: 3.3rem;
      margin-bottom: 32px;
      position: relative;

      .trigger {
        position: absolute;
        top: 0;
        left: 50%;
        transform: translateX(-50%);
        width: 160px;
        height: 30px;
        z-index: 1;
      }

      .card1 {
        position: relative;
        width: 100%;
        height: 391px;
        border-radius: 12px;
        border: 1px solid #e5e5e5;
        padding: 24px;

        @media (max-width: 565px) {
          height: 265px;
          font-size: 0.8rem;
        }

        .line-chart-empty-state {
          width: 100%;
          height: 100%;
          background: transparent;
          position: absolute;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          bottom: 0;
          right: 0;
          border-radius: 0px 0px 12px 12px;

          font-size: 14px;
          font-weight: 700;
          margin-top: 1.2rem;

          svg {
            margin-bottom: 1.2rem;
          }
        }
      }

      .card2 {
        width: 100%;
        height: 391px;
        border: 1px solid #e5e5e5;
        border-radius: 16px;
        padding: 32px;

        .title_group {
          display: flex;
          align-items: center;
          justify-content: space-between;

          & > :first-child {
            color: #282828;
            font-size: 10px;
            font-weight: 600;
          }

          & > :last-child {
            font-size: 8px;
            color: #667185;
          }
        }
      }
    }

    .management_card_group {
      display: grid;
      grid-template-columns: 1fr;
      grid-column-gap: 3.3rem;

      .card1 {
        border: 1px solid #e5e5e5;
        border-radius: 8px;
        padding: 16px 0;
        position: relative;
        .title_group {
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: space-between;
          margin-bottom: 24px;
          padding: 0 24px;

          & > :first-child {
            font-size: 1.4rem;
            font-weight: 700;
            color: #282828;
            display: flex;
            align-items: center;

            @media (max-width: 768px) {
              font-size: 1.2rem;
            }

            svg {
              margin-left: 4px;
            }
          }

          &__flex {
            display: flex;
            align-items: center;
            gap: 16px;

            &__button {
              width: 105px;
              height: 32px;
              border-radius: 4px;
              background: #2c9963;
              display: flex;
              align-items: center;
              justify-content: center;
              gap: 6px;
              color: #fff;
              font-size: 8px;
              font-weight: 600;
              border: 1px solid var(--Stroke, #e5e5e5);
            }
          }
        }

        .date_group {
          display: flex;
          align-items: center;

          @media (max-width: 565px) {
            font-size: 0.6rem;
          }

          & > :first-child {
            margin-right: 36px;
          }

          input {
            border-radius: 4px;
            border: 1px solid #e5e5e5;
            padding: 0 4px;

            @media (max-width: 565px) {
              width: 62px;
              height: 8px;
              font-size: 6px;
            }
          }
        }

        .emission_table {
          font-family: Arial, Helvetica, sans-serif;
          border-collapse: collapse;
          width: 100%;
          height: 400px;
          font-size: 1.4rem;
          position: relative;
          overflow-y: auto;
          // background-color: yellow;
          .table_header_container {
            width: 100%;
            display: grid;
            grid-template-columns: 1fr 1fr 1fr 1fr;
            margin: auto;
            background-color: #2c9963;
            height: 52px;
            align-items: center;
            font-size: 1.4rem;
            color: #fff;
            .table_header {
              width: fit-content;
              justify-self: center;

              @media (max-width: 768px) {
                font-size: 0.8rem;
              }
            }
          }

          .table_body_container {
            width: 100%;
            display: grid;
            grid-template-columns: 1fr 1fr 1fr 1fr;
            margin: auto;
            height: 47px;
            align-items: center;
            font-size: 1.4rem;

            @media (max-width: 768px) {
              font-size: 1.2rem;
            }

            @media (max-width: 565px) {
              display: none;
            }

            & > div {
              width: fit-content;
              justify-self: center;
            }
          }

          .table_header_container-empty {
            width: 100%;
            height: 52px;
            background: #98a2b3;
          }

          .mobile {
            @media (max-width: 565px) {
              display: grid !important;
            }
          }
          .management-table-empty-state {
            width: 100%;
            height: 117px;
            border-radius: 6px;
            margin: 24px auto;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;

            font-size: 14px;
            font-weight: 700;
            margin-top: 1.2rem;

            svg {
              margin-bottom: 1.2rem;
            }
          }
        }

        &__modal {
          width: 281px;
          height: 221px;
          position: absolute;
          top: 23px;
          right: 0;
          padding: 24px;
          border-radius: 8px;
          border: 1px solid var(--Stroke, #e5e5e5);
          background: #fff;

          &__title {
            color: var(--Net-Black, #101828);
            font-size: 1.4rem;
            font-weight: 600;
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin-bottom: 24px;
          }

          &__group {
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin-bottom: 16px;

            & > :first-child {
              color: var(--Net-Black, #101828);
              font-size: 1.4rem;
              font-weight: 400;
            }
            & > :last-child {
              display: flex;
              align-items: center;
              gap: 4px;
              color: #747474;
              font-size: 1.4rem;
              font-weight: 400;
            }
          }

          &__button {
            margin-top: 16px;
            width: 100%;
            height: 42px;
            border-radius: 8px;
            background: #2c9963;
            color: var(--Shade-White, #fff);
            font-size: 1.6rem;
            font-weight: 600;
            border: none;
          }
        }
      }

      .card2 {
      }
    }

    &__emission-group {
      width: 100%;
      display: grid;
      grid-template-columns: 1.65fr 1fr;
      gap: 32px;

      @media (max-width: 768px) {
        grid-template-columns: 1fr;
      }

      &__faq {
        width: 100%;
        padding: 24px;
        border-radius: 8px;
        border: 1px solid #e5e5e5;

        &__title-group {
          display: flex;
          align-items: center;
          justify-content: space-between;
          margin-bottom: 24px;

          & > :first-child {
            font-size: 1.6rem;
            font-weight: 600;
            color: #101828;
          }

          & > :last-child {
            font-size: 0.8rem;
            font-weight: 400;
            color: #667185;
            text-transform: uppercase;
          }
        }

        &__card {
          width: 100%;
          max-width: 74.4rem;
          height: fit-content;
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          font-size: 12px;
          color: #141414;
          font-weight: 600;
          margin: auto;
          margin-bottom: 3.2rem;
          border-bottom: 0.5px solid #d0d5dd;
          padding-bottom: 8px;

          &__inner {
            display: grid;
            align-items: start;
            grid-template-columns: auto 16px;
            justify-content: space-between;

            &__plus {
              background-color: rgba(13, 146, 79, 0.3);
              width: 16px;
              height: 16px;
              border-radius: 50%;
              position: relative;
              cursor: pointer;

              &__item {
                width: 6px;
                height: 1.5px;
                background-color: #141414;
                border-radius: 1px;
                position: absolute;
                top: 50%;
                right: 50%;
                transform: translate(50%, 50%);
                transition: 0.3s;
              }

              &__item2 {
                transform: translate(50%, 50%) rotate(90deg);
              }

              &__itemOpaque {
                opacity: 0;
              }
            }

            & > svg {
              cursor: pointer;
            }
          }

          .subtitle {
            font-size: 12px;
            font-weight: 400;
            color: #b1b1b1;
            margin-top: 2rem;
            margin-bottom: 1.5rem;
          }

          .line {
            height: 0.1rem;
            width: 96%;
            background-color: #b1b1b1;
          }
        }
      }
    }
  }

  .goal_modal_container {
    width: 100%;
    height: 100vh;
    position: absolute;
    top: 0;
    left: 0;
    background: rgba(0, 0, 0, 0.7);
    z-index: 1;
  }
  .tag_modal {
    width: 100%;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    background: rgba(0, 0, 0, 0.4);
    z-index: 1;

    @media (max-width: 1024px) {
      display: none !important;
    }
  }

  .tag {
    position: absolute;
    width: 212px;
    min-height: 62px;
    background-color: #fff;
    top: 49px;
    left: 24px;
    border-radius: 6px;
    border: 1px solid #e5e5e5;
    font-size: 1.2rem;
    color: #1a1a1a;
    z-index: 3;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 16px 24px;
    transition: 1s;

    @media (max-width: 1024px) {
      display: none !important;
    }
  }

  .reoccuing_tag {
    width: fit-content !important;
    font-size: 1rem !important;
    padding: 16px 8px !important;
  }

  .reoccuing_tag_chart {
    top: 25px;
    left: 50%;
    transform: translateX(-50%);
  }
  .offset_tag {
    height: 77px;
  }

  .credit_tag {
    top: 65px;
  }

  .table_tag {
    top: 0;
    left: 6px;
    width: 283px;
  }

  .no_tag {
    z-index: -2;
    opacity: 0;
    transition: 1s;
  }

  // .validation {
  //   top: 359px;
  //   left: 26px;
  //   width: 250px;
  // }
  .verification {
    top: 359px;
    left: 26px;
    width: 250px;
  }
  .credit {
    top: 430px;
    left: 26px;
    width: 250px;
  }

  .search_tag {
    top: 29px;
    right: -20px;
    left: unset;
  }

  .fake_data_modal {
    width: 100%;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    background: rgba(0, 0, 0, 0.4);
    z-index: 2;
  }

  .schedule-success_data_modal {
    width: 100%;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    background: rgba(0, 0, 0, 0.4);
    z-index: 2;
  }

  .iot-schedule {
    position: absolute;
    top: 50%;
    left: 50%;
    padding: 24px;
    z-index: 2;
    transform: translate(-50%, -50%);
    width: 42.3rem;
    min-height: 41.4rem;
    background-color: #fafafa !important;
    border-radius: 16px;
    box-shadow: -24px 0px 12px 0px rgba(102, 113, 133, 0.35),
      0px 16px 6px 0px rgba(0, 0, 0, 0.25);
    overflow: auto;
    &::-webkit-scrollbar {
      display: none;
    }

    &__title {
      color: #1a1a1a;
      font-size: 1.4rem;
      font-weight: 700;
      margin-bottom: 0.6rem;
    }

    &__subtitle {
      color: #667185;
      font-size: 10px;
      font-weight: 400;
      margin-bottom: 2.3rem;
    }
    &__flex {
      display: flex;
      align-items: center;
      justify-content: space-between;
      color: #1a1a1a;
      font-size: 12px;
      font-weight: 700;
      margin-bottom: 0.7rem;

      & > div {
        display: flex;
        align-items: center;
        gap: 16px;
      }
    }

    &__button-group {
      display: flex;
      align-items: center;
      gap: 16px;
      width: 100%;
      max-width: 256px;
      margin: auto;

      & > button {
        width: 100%;
        height: 4.3rem;
        outline: none;
        border: none;
        border-radius: 6px;
        background: linear-gradient(116.29deg, #006633 0.76%, #02a4cc 100%);
        font-size: 1rem;
        font-weight: 600;
        color: #fff;
        margin: auto;
        margin-top: 1.6rem;
        display: flex;
        align-items: center;
        justify-content: center;
      }

      & > :first-child {
        background: transparent;
        border: 1px solid #063;
        background: linear-gradient(
          116deg,
          rgba(0, 102, 51, 0.4) 0.76%,
          rgba(2, 164, 204, 0.4) 100%
        );
        background-clip: text;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
      }
    }

    &__agree {
      display: flex;
      align-items: center;
      gap: 8px;
      margin-top: 14px;
      font-weight: 500;
      line-height: 140%;

      & > div {
        & > span {
          background: var(
            --Gradient,
            linear-gradient(116deg, #063 0.76%, #02a4cc 100%)
          );
          background-clip: text;
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
          font-family: Inter;
          font-size: 1.2rem;
          font-weight: 700;
        }
      }

      & > svg {
        min-width: 24px;
        cursor: pointer;
      }
    }
  }

  .fake_data {
    position: absolute;
    top: 50%;
    left: 50%;
    padding: 48px 36px 64px 36px;
    z-index: 2;
    transform: translate(-50%, -50%);
    // width: 63.8rem;
    // height: 33.3rem;
    background-color: #fafafa !important;
    border-radius: 16px;
    box-shadow: -24px 0px 12px 0px rgba(102, 113, 133, 0.35),
      0px 16px 6px 0px rgba(0, 0, 0, 0.25);

    .fake_data_title {
      font-size: 2.4rem;
      font-weight: 700;
      color: #1a1a1a;
      margin-bottom: 0.6rem;
    }

    .fake_data_subtitle {
      font-size: 1.2rem;
      font-weight: 400;
      color: #667185;
      margin-bottom: 3.6rem;
    }

    .fake_data_content {
      font-size: 1.8rem;
      font-weight: 500;
      color: #515151;
    }

    .fake_data_button {
      width: 42.5rem;
      height: 9.7rem;
      outline: none;
      border: none;
      border-radius: 12px;
      background: linear-gradient(116.29deg, #006633 0.76%, #02a4cc 100%);
      font-size: 1.8rem;
      font-weight: 700;
      color: #fff;
      margin: auto;
      margin-top: 1.6rem;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  .fake-data-success {
    position: absolute;
    top: 50%;
    left: 50%;
    padding: 24px;
    z-index: 2;
    transform: translate(-50%, -50%);
    width: 46.7rem;
    min-height: 25.2rem;
    background-color: #fafafa !important;
    border-radius: 16px;
    box-shadow: -24px 0px 12px 0px rgba(102, 113, 133, 0.35),
      0px 16px 6px 0px rgba(0, 0, 0, 0.25);
    overflow: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    &::-webkit-scrollbar {
      display: none;
    }

    &__title {
      color: #1a1a1a;
      text-align: center;

      /* Body text 1 Bold */
      font-size: 2.4rem;
      font-weight: 700;
      margin-bottom: 1.2rem;
    }

    &__subtitle {
      color: #00aed9;
      text-align: center;
      /* Paragraph text 2 regular */
      font-size: 1.4rem;
      font-weight: 400;
      margin-bottom: 2.4rem;
    }
    &__subsubtitle {
      color: #667185;
      text-align: center;
      font-size: 10px;
      font-weight: 500;
    }
    &__flex {
      display: flex;
      align-items: center;
      justify-content: space-between;
      color: #1a1a1a;
      font-size: 12px;
      font-weight: 700;
      margin-bottom: 0.7rem;

      & > div {
        display: flex;
        align-items: center;
        gap: 16px;
      }
    }

    &__button {
      width: 23.5rem;
      height: 4.3rem;
      outline: none;
      border: none;
      border-radius: 12px;
      background: linear-gradient(116.29deg, #006633 0.76%, #02a4cc 100%);
      font-size: 1rem;
      font-weight: 600;
      color: #fff;
      margin: auto;
      margin-top: 1.6rem;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  .tutorial-modal {
    width: 100%;
    height: 100vh;
    position: absolute;
    top: 0;
    left: 0;
    background: rgba(0, 0, 0, 0.7);
    z-index: 3;
    display: flex;
    align-items: center;
    justify-content: center;

    @media (max-width: 1024px) {
      display: none !important;
    }

    .tutorial-modal__modal {
      width: 31.7rem;
      min-height: 16.5rem;
      background: #fff;
      border-radius: 12px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      svg {
        margin-bottom: 1.2rem;
      }

      &__title {
        font-size: 1.8rem;
        font-weight: 700;
        color: #1a1a1a;
        margin-bottom: 1.2rem;
      }

      &__subtitle {
        font-size: 1.2rem;
        color: #98a2b3;
        font-weight: 400;
        margin-bottom: 1.4rem;
      }

      &__flex {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        color: #1a1a1a;
        padding: 0 24px;
        font-size: 1.8rem;
        color: #006633;
        margin-top: 2rem;

        & > :first-child {
          color: #bbb;
          font-size: 1.4rem;
        }

        & > div:hover {
          text-decoration: underline;
          cursor: pointer;
        }
      }
    }
  }
}

.calendar-row {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
  gap: 8px;
  align-items: center;
  justify-content: center;

  & > div {
    // background: green;
    width: fit-content;
    display: flex;
    align-items: center;
    justify-content: center;
    align-self: center;
    justify-self: center;
    margin-bottom: 8px;
    font-family: Inter;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    width: 40px;
    height: 40px;
    border-radius: 6px;
    color: #d0d5dd;
  }

  .available {
    color: #1a1a1a;
    cursor: pointer;
  }

  .available:hover {
    background: #00aed9;
    color: #fff;
  }

  .active-date {
    background: #00aed9;
    color: #fff;
  }
}

.calendar-header {
  & > div {
    color: #1a1a1a;
  }
}

#myChartg {
  width: 100%;
  height: 391px;
}

.calendar-modal {
  min-width: 28.7rem;
  min-height: 28.7rem;
  background: #efefef;
  position: absolute;
  top: 52px;
  right: 0;
  border-radius: 4px 0px 0px 4px;
  border: 1px solid #efefef;
  background: #fff;
  padding: 24px 12px;

  .ndc-calendar-row {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
    // gap: 24px;
    align-items: center;
    justify-content: center;

    & > div {
      // background: green;
      width: fit-content;
      display: flex;
      align-items: center;
      justify-content: center;
      align-self: center;
      justify-self: center;
      margin-bottom: 16px;
      font-size: 12px;
      font-weight: 500;
      width: 100%;
      height: 20px;
      color: #1a1a1a;
    }
    .ndc-calendar-date {
      cursor: pointer;
    }
    .ndc-calendar-date:hover {
      background: #00aed9;
      color: #fff;
      border-radius: 50%;
      width: 20px;
    }

    .active-date {
      background: #00aed9;
      color: #fff;
      border-radius: 50%;
      width: 20px;
    }

    .subsidiary-date {
      background: #e3effc;
    }
  }

  .calendar-header {
    & > div {
      color: #1a1a1a;
    }
  }

  &__flex {
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: #1a1a1a;
    font-size: 12px;
    font-weight: 500;
    margin-bottom: 1.6rem;

    & > div {
      display: flex;
      align-items: center;
      gap: 16px;
    }
  }

  &__button-group {
    display: flex;
    align-items: center;
    gap: 8px;
    justify-content: end;
    button {
      width: 7.2rem;
      height: 3.2rem;
      outline: none;
      border: none;
      border-radius: 6px;
      background: linear-gradient(116.29deg, #006633 0.76%, #02a4cc 100%);
      font-size: 10px;
      font-weight: 600;
      color: #fff;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    & > :first-child {
      border: 1px solid #667185;
      color: #1a1a1a;
      background: transparent;
    }
  }
}

.ndclist-table__header__select {
  min-width: 77px;
  height: 32px;
  background: #fff;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: #2c2c2c;
  font-size: 0.8rem;
  font-weight: 400;
  padding: 0 12px;
  border: 1px solid #e5e5e5;

  svg {
    margin-right: 8px;
  }

  & > :last-child {
    margin-left: 8px;
    margin-right: 0;
  }
}
