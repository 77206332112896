.admin_search_container {
  position: relative;
  width: fit-content;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 30px;

  display: none;

  @media (max-width: 1024px) {
    display: flex;
  }

  .mobile_menu {
    display: none;
    // margin-bottom: 2.457rem;
    // background: blue;

   @media (max-width: 1024px) {
    display: flex;
    align-items: center;
    img {
      width: 32px;
      height: auto;
      margin-left: 2.9rem;
    }
   }
   @media (max-width: 565px) {
    img {
      width: 16px;
    }
   }


  }

  .search_form {
    background-color: #fafafa;
    display: flex;
    align-items: center;
    width: 38.4rem;
    height: 4rem;
    padding: 0 24px;
    border-radius: 8px;

    @media (max-width: 565px) {
      width: 17.3rem;
      height: 2.4rem;
    }

    svg {
      margin-right: 8px;

      @media (max-width: 565px) {
        width: 16px;
        margin-right: 4px;
      }
    }

    input {
      width: 100%;
      border: none;
      outline: none;
      background: none;
      height: 4rem;

      @media (max-width: 565px) {
        font-size: 0.8rem;
      }
    }
  }

  .search_flex {
    display: flex;
    align-items: center;
    // background: teal;

    .notification {
      margin-right: 1.025rem;
      position: relative;

      @media (max-width: 565px) {
        margin-right: 0.4rem;
      }

      & > :last-child {
        @media (max-width: 565px) {
          width: 16px;
        }
      }

      .badge {
        position: absolute;
        top: 3px;
        right: 0;
      }
    }
    .profile {
      display: flex;
      align-items: center;
      font-weight: 700;
      cursor: pointer;
  
      .search_name {
        width: 4.2rem;
        height: 4.2rem;
        border-radius: 50%;
        background-color: #063;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 1.4rem;
        color: #fff;
        margin-right: 0.8rem;
        position: relative;
        text-transform: uppercase;

        @media (max-width: 565px) {
          width: 2.4rem;
        height: 2.4rem;
        font-size: 1.2rem;
        margin-right: 0.4rem;
        }
  
        .kyc_svg{
          position: absolute;
          bottom: 1px;
          right: -7px;
        }
        
        .tag {
          position: absolute;
          width: 15.8rem;
          min-height: 4.8rem;
          background-color: #fff;
          top: 41px;
          left: 34px;
          border-radius: 6px;
          border: 1px solid #E5E5E5;
          font-size: 1.2rem;
          color: #1a1a1a;
          z-index: 3;
          display: flex;
          align-items: center;
          justify-content: center;
          padding: 16px 24px;
          transition: 1s;
          color: #063;
        }
  
        .tag_progress {
          color: #00AED9;
        }
      }
  
      .profile_title {
        font-size: 1.2rem;
        color: #98A2B3;

        @media (max-width: 565px) {
          font-size: 1rem;
          }
      }
    }
  
  }
}
