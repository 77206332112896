.blog-list {
  display: flex;

  .sidebar_container {
    // background-color: yellow;
    //  height: 100%;
    max-height: 700px;
    position: relative;

    @media (max-width: 1024px) {
      display: none;
    }
  }


  &__main {
    max-width: 1440px;
    width: 100%;
    margin: auto;
    height: 100vh;
    background-color: #fff;
    padding: 44px;
    overflow-x: auto;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    @media (max-width: 1024px) {
      overflow-x: unset !important;
    }

    @media (max-width: 565px) {
      padding: 24px;
    }

    .blog-list__main-inner {
      .blog-list__tab {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        max-width: 76.3rem;
        border-radius: 16px;
        border: 1px solid #063;
        padding: 23px 100px 23px 23px;
        font-size: 1.8rem;
        font-weight: 500;
        color: #d0d5dd;
        margin-bottom: 3.2rem;

        @media (max-width: 565px) {
          font-size: 1.2rem;
          padding: 16px 60px 16px 16px;

        }
  
        & > div {
          cursor: pointer;
        }
  
        .active {
          padding: 7px 24px;
          background: #00aed9;
          border-radius: 8px;
          color: #fff;
          display: flex;
          align-items: center;
          font-weight: 700;

          @media (max-width: 565px) {
            padding: 8px 12px;
          }
  
          span {
            background: #4fcdec;
            display: block;
            width: 24px;
            height: 24px;
            border-radius: 50%;
            display: flex;
            align-items: center;
            justify-content: center;
            margin-left: 0.4rem;
          }
        }
      }
  
      .blog-list__card {
        position: relative;
        width: 100%;
        max-width: 85rem;
        min-height: 20.8rem;
        border-radius: 12px;
        border: 1px solid var(--Gradient, #063);
        margin-bottom: 1.6rem;
        padding: 27px 36px;

        @media (max-width: 565px) {
          padding: 26px 16px;
          min-height: unset;
          height: fit-content;
        }
  
        svg {
          position: absolute;
          top: 16px;
          right: 37px;
        }
  
        .blog-card__flex {
          display: flex;
          align-items: center;
          margin-bottom: 1.293rem;
  
          & > div {
            border-radius: 12.026px;
            border: 1.002px solid #898989;
            padding: 10.022px;
            margin-right: 1.603rem;
            font-size: 12.026px;
            color: #063;

            @media (max-width: 565px) {
              font-size: 0.8rem;
              padding: 8px;
              margin-right: 0.8rem;
            }
          }
  
          & > :last-child {
            margin-right: 0;
          }
        }
  
        .blog-card__title {
          font-size: 1.8rem;
          font-weight: 700;
          color: #1a1a1a;
          margin-bottom: 1.2rem;

          @media (max-width: 565px) {
            font-size: 1.4rem;
          }
    
        }
        .blog-card__subtitle {
          font-size: 1.4rem;
          color: #3b3b3b;
          margin-bottom: 1.2rem;

          @media (max-width: 565px) {
            font-size: 0.8rem;
          }
        }
  
        .blog-card__bar {
          width: 100%;
          height: 0.8rem;
          display: flex;
          align-items: center;
          background: rgba(0, 102, 51, 0.10);
          border-radius: 4px;
          margin-bottom: 0.8rem;
          .itemOne {
            height: 100%;
            width: 50%;
            border-radius: 4px;
            transition: 0.4s;
            background: #063;
          }
        }
  
        .blog-card__bar-flex {
          display: flex;
          justify-content: space-between;
          align-items: center;
          font-size: 1rem;
          color: #141414;
  
          & > :first-child {
            font-weight: 600;
          }
  
        }
      }
    }

    
  }
}
