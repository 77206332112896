@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap");

.profile {
  display: flex;
  .sidebar_container {
    // background-color: yellow;
    //  height: 100%;
    max-height: 700px;
    position: relative;

    @media (max-width: 1024px) {
      display: none;
    }
  }
  .main {
    background-color: #fff;
    max-width: 1440px;
    width: 100%;
    margin: auto;
    padding: 44px;
    height: 100vh;
    overflow: auto;

    @media (max-width: 565px) {
      padding: 24px;
    }

    .tabs {
      display: flex;
      align-items: center;
      width: 100%;
    }
    .name {
      display: flex;
      align-items: center;
      margin-bottom: 3.6rem;
      .abv {
        font-size: 2.4rem;
        font-weight: 500;
        color: #fff;
        background-color: #006633;
        width: 6.4rem;
        height: 6.4rem;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-right: 1.2rem;
      }

      .name_inner {
        & > :first-child {
          font-size: 1.6rem;
          font-weight: 600;
        }
        & > :last-child {
          font-size: 1.4rem;
          font-weight: 400;
        }
      }
    }
    .tab_label_container {
      width: 82.7rem;
      margin-left: 108px;
      //   background-color: teal;
      border-bottom: 1px solid #d0d5dd;

      @media (max-width: 768px) {
        width: 100%;
        margin-left: 0;
      }

      .tab_label {
        width: 20.6rem;
        text-transform: none;
        font-size: 1.8rem;
        font-weight: 700;
        font-family: "Inter" !important;
        // background-color: tomato;

        @media (max-width: 768px) {
          width: unset;
          font-size: 1.2rem;
        }
      }
    }

    .profile_form {
      margin-top: 3.7rem;
      width: 79.3rem;
      height: fit-content;
      background: none;
      flex-direction: column;
      margin-left: 125px;

      @media (max-width: 768px) {
        width: 100%;
        margin-left: 0;
      }

      .input_container {
        width: 100%;
        display: grid;
        grid-template-columns: 235px auto;
        // justify-content: space-between;
        align-items: center;
        font-size: 1.6rem;
        margin-bottom: 2.4rem;
        color: #667185;
        gap: 24px;
        @media (max-width: 768px) {
          grid-template-columns: 1fr 4.5fr;
          font-size: 1rem;
          // white-space: nowrap;
          gap: 20px;
        }
        input,
        select {
          width: 100%;
          height: 5.9rem;
          border: 1px solid #E5E5E5;
          border-radius: 8px;
          padding-left: 24px;

          @media (max-width: 768px) {
            width: 100%;
          }
          @media (max-width: 565px) {
            font-size: 1rem;
            padding-left: 12px;
          }
        }
        textarea {
          width: 100%;
          height: 11.4rem;
          border: 1px solid #E5E5E5;
          border-radius: 8px;
          padding: 24px;

          @media (max-width: 768px) {
            width: 100%;
          }
        }

        .document {
          display: flex;
          align-items: center;
          justify-content: space-between;
          width: 100%;
          height: 5.9rem;
          border: 1px solid #E5E5E5;
          border-radius: 8px;
          padding: 0 24px;
          cursor: pointer;

          @media (max-width: 768px) {
            width: 100%;
          }
        }

        .input_group {
          width: 100%;
          display: grid;
          grid-template-columns: 1fr 2.4fr;
          grid-column-gap: 12px;

          @media (max-width: 768px) {
            width: 100%;
          }

          input {
            width: 100% !important;
          }

          .cc-input::placeholder {
            font-size: 8px;
          }
        }

        &__verify {
          display: grid;
          grid-template-columns: 1fr 0.3fr;
          gap: 20px;

          & > input {
            width: 100%;
          }

          & > button {
            background: linear-gradient(116.29deg, #006633 0.76%, #02a4cc 100%);
            border: none;
            border-radius: 12px;
            font-weight: 500;
            color: #fff;

            &:hover {
              transition: 0.7s;

              background: none;
              color: #006633;
              border: 1px solid #006633;
            }
          }
        }
      }

      &__input-grid {
        width: 100%;
        height: fit-content;
        display: grid;
        grid-template-columns: 1fr;
        align-items: center;
        gap: 43px;
        margin-bottom: 2.4rem;

        @media (max-width: 768px) {
          grid-template-columns: 1fr;
          gap: 0;
          margin-bottom: 0;
        }

        & > div {
          @extend .input_container;
          grid-template-columns: 235px auto !important;
          @media (max-width: 768px) {
            grid-template-columns: 235px auto !important;
          }
        }
      }
      .settings_submit {
        max-width: 76.1rem;
        width: 100%;
        height: 9.7rem;
        background: linear-gradient(116.29deg, #006633 0.76%, #02a4cc 100%);
        border: none;
        border-radius: 12px;
        font-size: 1.6rem;
        font-weight: 600;
        color: #fff;
        margin-top: 7rem;

        @media (max-width: 768px) {
          margin-top: 1.6rem;
          height: 8rem;
        }

        &:hover {
          transition: 0.7s;

          background: none;
          color: #006633;
          border: 1px solid #006633;

          & > div {
            color: #006633;
          }
        }
      }
    }
  }
}
