.update-firmware {
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  &__title {
    // background: yellow;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: #0d924f;
    // margin-bottom: 2rem;
    cursor: pointer;

    & > :first-child {
      width: 240px;
      height: 180px;
      border-radius: 14px;
      border: 1px dashed #006805;
      background: #f2fff2;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  &__label {
    width: 100%;
    max-width: 400px;
    margin-bottom: 4px;
    // font-size: 1.6rem;
    color: #0d924f;
    text-align: center;
    // font-size: 1.4rem;
  }

  &__input {
    display: flex;
    align-items: center;
    gap: 14px;
    width: 100%;
      max-width: 400px;
      margin: 0 auto;
    & > select {
        width: 100%;

      height: 40px;
      border: 1px solid #bbb;
      border-radius: 8px;
      background: #fbfbfb;
      padding-left: 10px;
    }

    & > button {
        width: fit-content;
        max-width: 400px;
        height: 40px;
        border-radius: 12px;
        padding: 0 12px;
        background: linear-gradient(116.29deg, #006633 0.76%, #00aed9 100%);
        border: none;
        outline: none;
        color: #ffffff;
        font-size: 1.2rem;
        font-weight: 600;
        cursor: pointer;
        transition: 0.7s;
        white-space: nowrap;
    }
  }

  &__button {
    width: 100%;
    max-width: 400px;
    height: 44px;
    border-radius: 12px;
    background: linear-gradient(116.29deg, #006633 0.76%, #00aed9 100%);
    display: flex;
    align-items: center;
    justify-content: center;
    border: none;
    outline: none;
    color: #ffffff;
    font-size: 1.4rem;
    font-weight: 600;
    cursor: pointer;
    margin-bottom: 2rem;
    transition: 0.7s;
  }
}
