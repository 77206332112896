.carbon_credit {
  display: flex;
  .sidebar_container {
    // background-color: yellow;
    //  height: 100%;
    max-height: 700px;
    position: relative;

    @media (max-width: 1024px) {
      display: none;
    }
  }

  &__contract-address {
    width: 100%;
    font-size: 1.4rem;
    color: #98A2B3;
    font-weight: 400;
    margin-bottom: 10px;
    display: flex;
    align-items: center;
    gap: 5px;
    white-space: nowrap;

    & > span {
      font-weight: 500;
      color: #101828;
      display: flex;
      align-items: center;
      gap: 5px;
      cursor: pointer;
      
      @media (max-width: 565px) {
        word-break: break-all;
      white-space: wrap;
      }

      &:hover {
        text-decoration: underline;
      }

      & > svg {
        width: 20px;
      }
    }
  }

  .main {
    width: 1440px;
    max-width: 100%;
    height: 100vh;
    background-color: #fff;
    padding: 44px;
    overflow-x: auto;
    margin: auto;

    @media (max-width: 565px) {
      padding: 24px;
    }

   

    .wallet {
      height: fit-content;
      width: 306px;
      border: 1px solid #E5E5E5;
      padding: 24px;
      border-radius: 8px;
      margin-bottom: 3.5rem;
      transition: 0.4s;

      .subtitle {
        color: #98A2B3;

        font-size: 16px;
        font-weight: 600;
        margin-bottom: 12px;
        width: 100%;
      }
      .title {
        color: #101828;

        /* Subtext regular */
        font-size: 12px;
        font-weight: 400;
        margin-bottom: 8px;
        width: 100%;
      }

      button {
        width: 100%;
        border: none;
        outline: none;
        height: 38px;
        border-radius: 8px;
        color: #FFFFFF;
        background: linear-gradient(127.56deg, #038B47 0%, #00AED9 100%);
        font-weight: 700;
        font-size: 14px;
        transition: 0.4s;

        // &:hover {
        //   background: transparent;
        //   border: 1px solid #e4e7ec;
        //   color: #e4e7ec;
        // }
      }

      // .transparent {
      //   background: #fff;
      //   border: 0.5px solid #e4e7ec;
      //   color: #00aed9;
      //   // &:hover {
      //   //   background: #e4e7ec;
      //   // color: #00aed9;
      //   // }
      // }

      &__exchange {
        width: 100%;
        border-radius: 8px;
        background: #fff;
        margin-top: 8px;
        border: 1px solid #E5E5E5;

        & > div {
          height: 38px;
          width: 100%;
          color: var(--Grey-500, #667185);
          cursor: pointer;
          transition: 0.2s;

          font-size: 14px;
          font-weight: 400;
          display: flex;
          align-items: center;
          justify-content: center;

          &:hover {
            font-weight: 700;
            background: var(
              --Gradient,
              linear-gradient(116deg, #063 0.76%, #02a4cc 100%)
            );
            background-clip: text;
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
          }
        }

        & > :last-child {
          border-top: 0.5px solid #E5E5E5;
        }
      }
    }

    .wallet-empty {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      font-size: 14px;
      font-weight: 700;
      color: #fff;

      svg {
        margin-bottom: 1.2rem;
      }
    }

    .chart {
      position: relative;
      width: 100%;
      height: 420px;
      border: 1px solid #E5E5E5;
      // background-color: #0e7d98;
      border-radius: 8px;
      margin-top: 2.5rem;
padding: 24px;
      @media (max-width: 565px) {
        height: 20.1rem;
      }
    }

    .chart-empty {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      color: #fff;

      font-size: 14px;
      font-weight: 700;

      svg {
        margin-bottom: 1.2rem;
      }
    }

    .carbon_credit_group {
      display: grid;
      grid-template-columns: 1fr 1fr;
      gap: 24px;

      @media (max-width: 768px) {
        grid-template-columns: 1fr;
      }

      & > :first-child {
        .title {
          font-size: 1.6rem;
          font-weight: 600;
          color: #fff;
          margin-bottom: 1.5rem;
          margin-left: 12px;
          margin-top: 17px;
          display: flex;
          align-items: center;

          svg {
            margin-left: 4px;
          }
        }
      }

      .carbon_credit_table_container {
        position: relative;
        border-radius: 16px;
        border: 1px solid #E5E5E5;
        padding-bottom: 5px;

        .carbon_credit_table {
          font-family: Arial, Helvetica, sans-serif;
          border-collapse: collapse;
          width: 100%;
          font-size: 1.4rem;
          color: #fff;
          height: 26rem;
          overflow: auto;

          &::-webkit-scrollbar {
            display: none;
          }

        

          .table_header {
            width: 100%;
            display: grid;
            grid-template-columns: 1fr 1fr 1fr 1fr;
            // grid-column-gap: 3rem;
            height: 52px;
            background: #2C9963;
            align-items: center;
            text-align: center;
            font-size: 1.2rem;
            justify-content: space-between;
            margin-bottom: 1.2rem;

            @media (max-width: 565px) {
              font-size: 1rem;
            }

            & > div {
              width: fit-content;
              justify-self: center;
              word-wrap: break-word;
              word-break: break-all;
            }
          }

          .table_body {
            width: 100%;
            display: grid;
            grid-template-columns: 1fr 1fr 1fr 1fr;
            // grid-column-gap: 3rem;
            background: #fff;
            height: 5rem;
            align-items: center;
            text-align: center;
            margin-top: 1.2rem;
            font-size: 14px;
            font-weight: 400;
            color: #667185;

            & > div {
              width: fit-content;
              justify-self: center;
              word-wrap: break-word;
              word-break: break-all;
            }
          }

          .carbon-credit-table-empty-state {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: start;
            margin: auto;
            width: 100%;
            height: 100%;
            font-size: 1.4rem;
            font-weight: 700;
            margin-top: 75px;

            svg {
              margin-bottom: 1.2rem;
            }
          }
        }

        &__title {
          display: flex;
          align-items: center;
          font-size: 14px;
          font-weight: 600;
          height: 50px;
          padding: 0 14px;
          color: #282828;
          gap: 4px;
        }
      }

      .offset_chart {
        position: relative;
        width: 100%;
        height: 300px;
        border: 0.5px solid #E5E5E5;
        border-radius: 16px;
        padding: 24px;

        @media (max-width: 565px) {
          height: 20.1rem;
        }

        .offset-chart-empty-state {
          width: fit-content;
          height: fit-content;
          border-radius: 6px;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);

          color: #1a1a1a;

          font-size: 14px;
          font-weight: 700;

          svg {
            margin-bottom: 1.2rem;
          }
        }
      }
    }
  }

  .trade_modal_container {
    width: 100%;
    height: 100vh;
    position: absolute;
    top: 0;
    left: 0;
    background: rgba(0, 0, 0, 0.7);
    z-index: 1;

    .trade_modal {
      width: 74.1rem;
      max-width: 90%;
      height: fit-content;
      overflow: auto;
      margin: auto;
      background-color: #fff;
      // box-shadow: -24px 0px 12px 0px #66718559;
      box-shadow: 0px 16px 6px 0px #00000040;
      border-radius: 24px;
      position: absolute;
      top: 50%;
      left: 50%;
      padding: 50px 32px;
      z-index: 2;
      transform: translate(-50%, -50%);

      .trade_grid {
        width: 40rem;
        height: 6.2rem;
        display: flex;

        align-items: center;
        justify-content: center;
        font-size: 1.6rem;
        font-weight: 700;
        margin: auto;
        margin-bottom: 2rem;
        background: transparent;
        border-radius: 8px;
        // padding: 10px 60px;
        outline: none;

        svg {
          width: 4rem;
          height: 4rem;
          // background-color: yellow;
          margin-right: 20px;
        }

        & > div {
          // background-color: pink;
        }
      }

      .pancake {
        border: 1px solid #633001;
      }
      .uni {
        border: 1px solid #ff007a;
      }
      .sushi {
        border: 1px solid #0e0f23;
      }
    }
  }
  .swap-modal-container {
    width: 100%;
    height: 100vh;
    position: absolute;
    top: 0;
    left: 0;
    background: rgba(0, 0, 0, 0.7);
    z-index: 1;

    .swap-modal {
      // width: 57.5rem;
      max-width: 90%;
      height: fit-content;
      overflow: auto;
      margin: auto;
      background-color: #fff;
      // box-shadow: -24px 0px 12px 0px #66718559;
      box-shadow: 0px 16px 6px 0px #00000040;
      border-radius: 24px;
      position: absolute;
      top: 50%;
      left: 50%;
      // padding: 22px;
      z-index: 2;
      transform: translate(-50%, -50%);

      &__header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        font-size: 1.8rem;
        color: #1a1a1a;
        font-weight: 500;
        margin-bottom: 1.6rem;

        & > :first-child {
          display: flex;
          align-items: center;

          & > :first-child {
            margin-right: 1.9rem;
          }
          & > :last-child {
            color: #667185;
          }
        }
      }

      &__card {
        width: 100%;
        height: 14.3rem;
        background: #f9f9f9;
        border-radius: 16px;
        margin-bottom: 0.8rem;
      }

      &__button {
        margin-top: 0.8rem;
      }
    }
    .swap-modal [color="container"] > div > div:nth-child(3) {
      position: relative;
    }
  }

  .trigger {
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    width: 160px;
    height: 30px;
    z-index: 1;
  }

  .tag {
    position: absolute;
    min-width: 231px;
    min-height: 62px;
    background-color: #fff;
    top: 29px;
    left: 4px;
    border-radius: 6px;
    border: 1px solid #E5E5E5;
    font-size: 1rem;
    color: #1a1a1a;
    z-index: 3;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 16px 8px;
    transition: 1s;
  }

  .chart_tag {
    top: 25px;
    left: 50%;
    transform: translateX(-50%);
  }

  .no_tag {
    z-index: -2;
    opacity: 0;
    transition: 1s;
  }

  .ccrtngn-modal {
    width: 100%;
    height: 100vh;
    position: absolute;
    top: 0;
    left: 0;
    background: rgba(0, 0, 0, 0.7);
    z-index: 1;
    display: flex;
    align-items: center;
    justify-content: center;

    &__inner {
      position: relative;
      width: 575px;
      height: 416px;
      border-radius: 24px;
      border: 0.5px solid var(--Grey-500, #667185);
      background: #fff;
      box-shadow: 0px 0px 6px 0px rgba(102, 113, 133, 0.35),
        0px 0px 6px 0px rgba(0, 0, 0, 0.25);
      padding: 24px;

      &__title {
        color: var(--Net-Black, #1a1a1a);

        font-size: 18px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        margin-bottom: 16px;
      }

      &__input {
        width: 100%;
        height: 116px;
        border-radius: 16px;
        background: rgba(249, 249, 249, 1);
        padding: 24px;
        margin-bottom: 12px;

        &__title {
          color: var(--Grey-500, #667185);

          font-size: 18px;
          font-weight: 500;
          margin-bottom: 12px;
        }

        & > input {
          width: 100%;
          height: 39px;
          border: none;
          outline: none;
          background: transparent;
          color: var(--Grey-500, #667185);
          font-family: Inter;
          font-size: 32px;
          font-style: normal;
          font-weight: 600;
          line-height: normal;
        }
      }

      &__input-filled {
        background: rgba(0, 126, 112, 0.2);
      }

      & > svg {
        position: absolute;
        top: 45%;
        left: 50%;
        transform: translate(-50%, -50%);
        z-index: 4;
      }

      &__button {
        width: 100%;
        height: 70px;
        border-radius: 16px;
        border: none;
        outline: none;
        background: #f9f9f9;

        & > div {
          font-size: 24px;
          font-weight: 700;
          background: var(
            --Gradient,
            linear-gradient(116deg, #063 0.76%, #02a4cc 100%)
          );
          background-clip: text;
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
        }
      }

      &__button-active {
background: var(--Gradient, linear-gradient(116deg, #063 0.76%, #02A4CC 100%));

& > div {
  background:unset;
  background-clip: unset;
  -webkit-background-clip: unset;
  -webkit-text-fill-color: unset;
  color: #FFF;
}
      }
    }

    &__recipient-inner {
      width: 417px;
      // height: 362px;
      border-radius: 24px;
      border: 0.5px solid var(--Grey-500, #667185);
      background: #fff;
      padding: 24px;


      &__title-group {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 16px;

        & > :first-child {
          color: var(--Net-Black, #1a1a1a);
          font-size: 18px;
          font-weight: 500;
        }
        & > :last-child {
          color: var(--Grey-500, #667185);
          font-size: 12px;
          font-weight: 400;
        }
      }

      &__details {
        height: 200px;
        border-radius: 16px;
background: rgba(0, 126, 112, 0.20);
padding: 16px;
margin-bottom: 24px;
        & > div {
          display: flex;
          align-items: center;
          justify-content: space-between;
          margin-bottom: 16px;

          & > :first-child {
            color: var(--Grey-500, #667185);
            font-size: 16px;
            font-weight: 400;
          }
          & > :last-child {
            color: var(--Net-Black, #1a1a1a);
            font-size: 16px;
            font-weight: 500;
          }
        }

        &__divider {
          width: 100%;
          height: 0.5px;
          background: #D0D5DD;
        }
        &__amount {
          & >:last-child {
            color: var(--Net-Black, #1A1A1A);
font-size: 18px;
font-weight: 400;

& > span {

font-size: 24px;
font-weight: 700;
}
          }
        }
      }
    }
  }

  
.errorModal {
  width: 100%;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.7);
  z-index: 1;
}

.error_modal {
  width: 31.7rem;
  min-height: 16.5rem;
  background-color: #fff;
  position: absolute;
  top: 50%;
  left: 50%;
  z-index: 2;

  transform: translate(-50%, -50%);
  /* background-color: #ffffff; */
  box-shadow: 24;
  /* height: 641, */
  overflow: auto;
  padding: 18px;
  border-radius: 12px;
  border: none !important;
  display: flex;
  flex-direction: column;
  justify-content: center;

  .modal_title {
    font-size: 1.8rem;
    font-weight: 700;
    color: #141414;
    margin-bottom: 1.2rem;
  }
  .modal_subtitle {
    font-size: 1.2rem;
    font-weight: 400;
    margin-bottom: 1.2rem;
  }

  .error_modal_button {
    width: 100% !important;
    height: 3.6rem;
    border-radius: 12px;
    background: transparent;
    border: 1px solid var(--Gradient, #063);
    display: flex;
    align-items: center;
    justify-content: center;
    outline: none;
    color: #063;
    font-size: 1.6rem;
    font-weight: 600;
    cursor: pointer;
    transition: 0.7s;
    white-space: nowrap;
    padding: 0 36px;
  }
}


.error_modal_button {
  width: 100% !important;
  height: 3.6rem;
  border-radius: 12px;
  background: transparent;
  border: 1px solid var(--Gradient, #063);
  display: flex;
  align-items: center;
  justify-content: center;
  outline: none;
  color: #063;
  font-size: 1.6rem;
  font-weight: 600;
  cursor: pointer;
  transition: 0.7s;
  white-space: nowrap;
  padding: 0 36px;
}
}


#myOffsetChart {
  width: 100%;
  height: 300px;  
}
#myCarbonCreditChart {
  width: 100%;
  height: 420px;
}