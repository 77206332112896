.home {
  .hero {
    position: relative;
    min-height: 96rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    // z-index: -1;
    background: url("../images/vlcsnap-2024-07-30-09h19m47s071.png");
    background-size: cover;
    background-position: center;

    video {
      // z-index: -1;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }

    .hero_inner {
      max-width: 1440px;
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      z-index: 1;
      padding: 0 144px;
      // background-color: blue;
      .title {
        font-size: 6.4rem;
        font-weight: 700;
        color: #063;
        margin-bottom: 2.4rem;
        text-align: center;
      }

      .subtitle {
        font-size: 3.2rem;
        font-weight: 500;
        color: #3b3b3b;
        margin-bottom: 4.8rem;
        text-align: center;
      }

      .hero_group {
        display: flex;
        align-items: center;
        margin-bottom: 21.3rem;
        gap: 24px;

        button {
          display: flex;
          align-items: center;
          border: none;
          outline: none;
          border-radius: 12px;
          background-color: #063;
          color: #fff;
          min-width: 15.8rem;
          min-height: 5.6rem;
          display: flex;
          align-items: center;
          justify-content: center;
          font-size: 1.6rem;
          font-weight: 500;
          white-space: nowrap;
          &:hover {
            background-color: #0d924f;
          }
          svg {
            margin-left: 1rem;
          }
        }

        & > :first-child {
          border: 1px solid var(--Primary-color, #063);
          background: rgba(255, 255, 255, 0.8);
          color: rgba(0, 102, 51, 0.8);
          padding: 0 13px;

          &:hover {
            background: rgba(255, 255, 255, 1);
          }
        }
      }
    }
    &__scroll-container {
      max-width: 1440px;
      width: 100%;
      overflow: hidden;

      &__scroll {
        max-width: 1440px;
        width: 100%;
        display: flex;
        align-items: center;
        animation: heromarquee 17s linear infinite;

        @media (max-width: 565px) {
          animation: heromarqueeMobile 17s linear infinite;
        }

        &::-webkit-scrollbar {
          display: none;
        }
        div {
          height: 8rem;
          width: fit-content;
          padding: 0 18px;
          border: 1px solid #0d924f;
          display: flex;
          align-items: center;
          font-size: 3.2rem;
          border-radius: 8px;
          white-space: nowrap;
          margin-right: 3.6rem;
          color: #141414;

          @media (max-width: 565px) {
            font-size: 2rem;
            height: 4rem;
          }
        }
      }
    }
  }

  .projects {
    max-width: 1440px;
    width: 100%;
    margin: auto;
    padding: 0 144px;
    margin-top: 6.487rem;
    margin-bottom: 6.487rem;
    border-radius: 12px;

    .title {
      font-size: 3.6rem;
      font-weight: 700;
      color: #0d924f;
      margin-bottom: 1.2rem;
    }
    .subtitle {
      font-size: 1.6rem;
      margin-bottom: 2.4rem;
      font-weight: 500;
    }

    .project_group {
      display: flex;
      align-items: start;
      overflow: auto;
      scroll-behavior: smooth;

      &::-webkit-scrollbar {
        display: none;
      }

      .box {
        width: 36.8rem;
        box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
        border-radius: 12px;
        margin-right: 2.4rem;
        position: relative;
        cursor: pointer;

        .shadow {
          background: rgba(94, 182, 138, 0.3);
          width: 100%;
          height: 100%;
          position: absolute;
          top: 0;
          left: 0;
          display: none;
        }

        &:hover {
          .shadow {
            display: initial;
          }
        }

        .image {
          height: 28.3rem;

          img {
            width: 100%;
            height: auto;
            border-radius: 12px 12px 0 0;
          }
        }

        .content {
          min-height: 28.3rem;
          border-radius: 0 0 12px 12px;

          padding: 24px;

          .title {
            font-size: 2.3rem;
            color: #141414;
            font-weight: 500;
          }

          .subtitle {
            font-size: 1.4rem;
            color: #898989;
            font-weight: 400;
            margin-bottom: 0;

            & > :last-child {
              font-size: 1.2rem;
              text-decoration: underline;
              margin-top: 1.2rem;
            }
          }
        }
      }

      & > :last-child {
        margin-right: 0;
      }
    }
  }

  .reach {
    max-width: 1152px;
    width: 100%;
    padding: 64px 0;
    margin: auto;

    .title {
      font-size: 4.8rem;
      font-weight: 700;
      text-align: center;
      color: #141414;
      margin-bottom: 4.2rem;

      span {
        color: #0d924f;
      }
    }

    .reach_group {
      width: 100%;
      // display: grid;
      // grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
      // grid-column-gap: 87px;
      // grid-row-gap: 40px;
      padding: 0 !important;
      display: flex;
      justify-content: center;
      flex-wrap: wrap;
      align-items: center;
      margin: auto;

      & > div {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        margin: 1.6rem 5rem;
        // max-width: 266px;
        // height: 120px;

        svg {
          margin-bottom: 1.2rem;
        }

        .title {
          font-size: 36px;
          font-weight: 500;
          color: #1a1a1a;
          margin-top: 0;
          margin-bottom: 0;
          // white-space: nowrap;
        }

        .subtitle {
          font-size: 16px;
          font-weight: 400;
          color: #3b3b3b;
          margin-bottom: 2.4rem;
          text-align: center;
          // white-space: nowrap;
        }
      }

      // & > :first-child {
      //   margin: 1.6rem 4rem 1.6rem 0 !important;
      // }
      // & > :last-child {
      //   margin: 1.6rem 0 1.6rem 4rem !important;
      // }
    }
  }

  .plans {
    width: 100%;
    padding-bottom: 20px;
    background: #fafafa;
    &__card1 {
      background: #063;
      width: 100%;
      height: 423px;
      border-radius: 12px 12px 0px 0px;
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 84px 64px 20px 64px;

      @media (max-width: 565px) {
        padding: 84px 20px 20px 20px;
      }

      &__title {
        font-size: 1.6rem;
        color: #fff;
        margin-bottom: 1.2rem;
      }
      &__subtitle {
        font-size: 3.2rem;
        font-weight: 700;
        color: #fff;
        margin-bottom: 4.8rem;

        @media (max-width: 565px) {
          text-align: center;
        }
      }

      &__tab {
        width: 100%;
        max-width: 452px;
        height: 48px;
        background: #fff;
        border-radius: 12px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 10px 24px;
        font-size: 12px;
        border: 1px solid #78bb99;
        & > div {
          height: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
          padding: 0 10px;
          min-width: 83px;
          border-radius: 6px;
          cursor: pointer;
        }

        &__active {
          background: #063;
          color: #fff;
        }
      }
    }

    &__card2 {
      width: 100%;
      min-height: 337px;

      &__plan-details {
        position: relative;
        max-width: 900px;
        width: 100%;
        min-height: 400px;
        background: #fff;
        margin: auto;
        margin-top: -130px;
        border-radius: 12px;
        border: 4px solid #b1b1b1;
        padding: 36px;
        display: grid;
        grid-template-columns: 1fr 1px 1fr;
        gap: 9px;

        @media (max-width: 768px) {
          margin-top: -50px;
        }

        @media (max-width: 768px) {
          grid-template-columns: 1fr;
          gap: 20px;
        }

        &__basic-info {
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          // height: 100%;
          &__flex {
            display: flex;
            & > :first-child {
              margin-right: 2.4rem;
              width: 60px;
              height: 60px;
            }

            & > :last-child {
              & > :first-child {
                font-size: 2.4rem;
                color: #141414;
                margin-bottom: 1.3rem;
                font-weight: 500;
              }
              & > :last-child {
                font-size: 1.2rem;
                color: #898989;
                // white-space: nowrap;
              }
            }
          }

          &__price {
            & > :first-child {
              font-size: 5.6rem;
              color: #0d924f;
              font-weight: 700;

              & > span {
                font-size: 2.4rem !important;
              }
            }
          }
        }

        &__divider {
          width: 100%;
          height: 100%;
          background: #efefef;

          @media (max-width: 768px) {
            display: none;
          }
        }

        &__more-info {
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          padding-left: 28px;

          @media (max-width: 565px) {
            padding-left: 0;
          }

          &__inner {
            &__title {
              font-size: 1.4rem;
              color: #3b3b3b;
              margin-bottom: 1.6rem;
              font-weight: 500;
            }
            &__flex {
              display: grid;
              grid-template-columns: 12px 1fr;
              align-items: center;
              gap: 12px;
              margin-bottom: 1.2rem;
              font-size: 12px;
            }
          }

          &__button-group {
            display: flex;
            margin-top: 72px;
            gap: 6px;

            & > button {
              width: 107px;
              height: 37px;
              border-radius: 12px;
              border: none;
            }
            & > :first-child {
              background: #149554;
              color: #fff;
              transition: 0.4s;

              &:hover {
                background: transparent;
                border: 1px solid #149554;
                color: #149554;
              }
            }
            & > :last-child {
              background: #fff;
              color: #0d924f;
              text-decoration: underline;
            }
          }
        }

        &__modal {
          position: absolute;
          bottom: 0;
          right: 100px;
          width: 370px;
          height: 340px;
          background: #fff;
          border-radius: 12px;
          padding: 24px;
          display: flex;
          flex-direction: column;
          align-items: center;
          z-index: 2;

          @media (max-width: 768px) {
            position: fixed;
            right: 50%;
            top: 50%;
            transform: translate(50%, -50%);
          }

          &__title {
            font-size: 1.8rem;
            font-weight: 500;
            color: #141414;
            margin-bottom: 1.2rem;
          }

          &__subtitle {
            color: #898989;
            margin-bottom: 2.4rem;
            font-size: 1.2rem;
            text-align: center;
          }

          &__video {
            width: 252px;
            height: 124px;
            background: #d9d9d9;
            margin-bottom: 2.4rem;
          }

          &__button-group {
            display: flex;
            gap: 6px;
            & > button {
              width: 107px;
              height: 37px;
              border-radius: 12px;
              border: none;
            }
            & > :first-child {
              background: #149554;
              color: #fff;
              transition: 0.4s;

              &:hover {
                background: transparent;
                border: 1px solid #149554;
                color: #149554;
              }
            }
            & > :last-child {
              color: #0d924f;
              text-decoration: underline;
              background: transparent;
            }
          }
        }
      }

      &__plan-details-scale {
        &:hover {
          transform: scale(1.005);
          border: 4px solid #149554;
        }
      }

      &__modal-cover {
        width: 100%;
        height: 100vh;
        position: fixed;
        top: 0;
        left: 0;
        background: rgba(0, 0, 0, 0.7);
        z-index: 1;
      }
    }
  }

  .platform {
    width: 100%;
    background-color: #0d924f;
    margin-bottom: 64px;

    .platform_inner {
      max-width: 1440px;
      width: 100%;
      margin: auto;
      height: 63.1rem;
      position: relative;
      padding: 0 144px;
      display: flex;
      align-items: center;
      justify-content: center;

      .dots {
        position: absolute;
        top: 0;
        right: 0;
      }

      .platform_group {
        display: grid;
        align-items: center;
        grid-template-columns: 410px auto;
        grid-column-gap: 229px;
        width: 100%;

        & > :first-child {
          width: fit-content;
          color: #fff;
          .title {
            font-size: 6.4rem;
            font-weight: 700;
            margin-bottom: 1.6rem;
          }

          .subtitle {
            font-size: 3.6rem;
            font-weight: 500;
            margin-bottom: 3.2rem;
          }

          .subsubtitle {
            font-size: 18px;
            font-weight: 400;
            margin-bottom: 2.4rem;
            text-align: justify;
          }

          button {
            display: flex;
            align-items: center;
            justify-content: center;
            border: none;
            outline: none;
            width: 36.9rem;
            height: 8rem;
            border-radius: 12px;
            background-color: #fff;
            font-size: 1.6rem;
            font-weight: 700;
            color: #141414;

            svg {
              margin-left: 1rem;
            }
          }
        }
        .view {
          width: 100%;
          position: relative;
          height: fit-content;
          width: fit-content;
          z-index: 1;
          // background-color: yellow;
          .people {
            position: absolute;
            top: -70px;
            left: 2px;
            width: fit-content;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            // display: none;
            // background-color: yellow;

            .people_group {
              display: flex;
              align-items: center;
              margin-bottom: 2.4rem;
              z-index: 1;

              img {
                width: 3.2rem;
                height: auto;
                margin-right: 1.6rem;
                border-radius: 50%;
                cursor: pointer;
              }

              .active {
                border: 1px solid #00aed9;
              }

              & > div {
                width: 200px;
                height: 200px;
                padding: 10px;
              }
            }

            .person {
              width: 10.7rem;
              height: 8.6rem;
              padding: 12px 18px;
              background-color: #ffffff;
              border-radius: 8px;
              display: flex;
              flex-direction: column;
              align-items: center;
              justify-content: center;

              img {
                width: 3.2rem;
                height: auto;
                margin-bottom: 1.6rem;
                border-radius: 50%;
              }

              div {
                font-size: 1.2rem;
                font-weight: 500;
                color: #00aed9;
              }
            }
          }
          img {
            width: 100%;
            height: auto;
          }
        }
      }
    }
  }

  .features {
    width: 100%;
    max-width: 1440px;

    padding: 64px 144px;
    margin: auto;
    background-color: #fafafa;

    .features_group {
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-column-gap: 86px;
      margin-bottom: 4.8rem;
      width: 100%;

      .tracking {
        .title {
          font-size: 3.6rem;
          font-weight: 700;
          color: #0d924f;
          margin-bottom: 1.6rem;
        }

        .subtitle {
          font-size: 1.8rem;
          font-weight: 400;
          color: #3b3b3b;
        }

        .divider {
          width: 100%;
          background-color: #b1b1b1;
          height: 0.1rem;
          margin: 2.45rem 0;
        }

        .ticks_grid {
          display: grid;
          grid-template-columns: 1fr 1fr;
          grid-column-gap: 55.36px;
          grid-row-gap: 24px;
          white-space: nowrap;

          & > div {
            display: flex;
            align-items: center;
            font-size: 1.8rem;
            font-weight: 500;
            color: #3b3b3b;

            svg {
              margin-right: 1.6rem;
            }
          }
        }
      }
      img {
        width: 100%;
        height: auto;
      }
    }
  }

  .step-by-step {
    &__header {
      border-width: 1px, 0px, 1px, 0px;

      border-style: solid;

      border-color: var(--Grey-2, #b1b1b1);
      position: sticky;
      top: 0;
      background: #fff;
      z-index: 5 !important;

      &__inner {
        width: 100%;
        height: 104px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        max-width: 1440px;

        padding: 0 144px;
        margin: auto;

        &__nav {
          display: flex;
          align-items: center;
          gap: 32px;

          & > div {
            font-size: 1.2rem;
            font-weight: 500;
            color: #898989;
            display: flex;
            flex-direction: column;
            gap: 8px;
            cursor: pointer;

            & > :last-child {
              width: 100%;
              height: 4px;
              border-radius: 4px;
            }
          }

          &__active {
            color: #006633 !important;

            & > :last-child {
              background: #006633;
            }
          }
        }

        & > button {
          font-size: 14px;
          font-weight: 500;
          color: #ffffff;
          width: 178px;
          height: 54px;
          background: #006633;
          border-radius: 12px;
          border: none;
        }
      }
    }
    &__inner {
      position: relative;
      max-width: 1440px;

      padding: 64px 144px;
      margin: auto;

      &__corner-img {
        position: absolute;
        top: 0;
        left: 0;
        width: 452px;
        height: auto;
        // z-index: -1;
      }
      &__title {
        font-size: 2.4rem;
        font-weight: 500;
        color: #3b3b3b;
        margin-bottom: 24px;
      }
      &__subtitle {
        font-size: 3.6rem;
        font-weight: 700;
        color: #3b3b3b;
        margin-bottom: 12px;
      }
      &__subsubtitle {
        font-size: 1.8rem;
        font-weight: 400;
        width: 100%;
        max-width: 60ch;
        color: #3b3b3b;
        margin-bottom: 48px;
        line-height: 160%;
      }

      &__grid {
        width: 100%;
        display: grid;
        grid-template-columns: 1fr 525px;
        gap: 64px;
        margin-bottom: 72px;

        @media (max-width: 768px) {
          grid-template-columns: 1fr;
        }

        &__nav {
          width: 100%;
          display: flex;
          flex-direction: column;
          gap: 24px;

          &__title {
            font-size: 3.2rem;
            font-weight: 500;
            color: #3b3b3b;
            margin-bottom: 10px;
          }
          &__item {
            padding-left: 32px;
            // border-left: 4px solid #cddad3;
            // margin-bottom: 7.2rem;
            cursor: pointer;
            &__title {
              font-size: 2.4rem;
              font-weight: 500;
              color: #3b3b3b;
              margin-bottom: 1.2rem;
            }
            &__subtitle {
              font-size: 1.4rem;
              font-weight: 400;
              color: #3b3b3b;
              border-bottom: 1px solid var(--Grey-2, #b1b1b1);
              padding-bottom: 16px;
            }
          }

          &__item-active {
            border-left: 4px solid #0d924f;

            & > :first-child {
              color: #0d924f;
            }
          }
        }

        &__img {
          width: 100%;
          height: auto;
          // background: rgba(183, 221, 202, 0.30);
        }
      }

      &__wallet-cards {
        width: 100%;
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: 24px 32px;
        margin-bottom: 64px;

        &__card {
          width: 100%;
          height: fit-content;
          border: 1px solid var(--Grey-2, #b1b1b1);
          border-radius: 12px;
          padding: 16px 32px;
          background: #fff;

          &__title {
            font-size: 2.4rem;
            font-weight: 500;
            color: #141414;
            margin-bottom: 16px;
          }
          &__step {
            font-size: 1.6rem;
            font-weight: 500;
            color: #898989;
            margin-top: -4px;
            margin-bottom: 12px;

            & > ul {
              font-size: 1.4rem;
              font-weight: 400;
            }
          }
        }
      }

      &__exchange {
        box-shadow: -2px 0px 2px 0px rgba(0, 102, 51, 0.1),
          2px 0px 2px 0px rgba(0, 102, 51, 0.1),
          0px -2px 2px 0px rgba(0, 102, 51, 0.1),
          0px 2px 2px 0px rgba(0, 102, 51, 0.1);
        border-radius: 24px;
        padding: 48px;

        display: grid;
        grid-template-columns: 420px auto;
        gap: 98px;

        & > div {
          width: 100%;
        }

        &__card {
          width: 100%;
          margin-bottom: 24px;

          &__title {
            font-size: 2.4rem;
            font-weight: 500;
            color: #141414;
            margin-bottom: 12px;
          }
          &__subtitle {
            font-size: 1.6rem;
            font-weight: 500;
            color: #898989;
            margin-bottom: 8px;
          }
        }

        & > img {
          width: 100%;
          height: auto;
          // border-radius: 12px;
        }
      }

      &__iot-bg {
        width: 100%;
        height: 271px;
        border-radius: 24px;
        margin-bottom: 32px;
        background: url("../images/iot-image.png");
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
        padding: 0 95px;
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: 20px;
        // justify-content: center;
        align-items: center;

        &__title {
          color: var(--White, #fff);
          font-size: 36px;
          font-weight: 700;
          margin-bottom: 12px;
        }

        &__subtitle {
          color: var(--White, #fff);
          font-size: 18px;
          font-weight: 400;
          line-height: 150%;
        }
      }

      &__cards {
        width: 100%;

        box-shadow: -2px 0px 2px 0px rgba(0, 102, 51, 0.1),
          2px 0px 2px 0px rgba(0, 102, 51, 0.1),
          0px -2px 2px 0px rgba(0, 102, 51, 0.1),
          0px 2px 2px 0px rgba(0, 102, 51, 0.1);
        border-radius: 24px;
        padding: 52px 97px;

        display: grid;

        grid-template-columns: 1fr 1fr;
        gap: 32px 64px;

        &__card {
          width: 100%;
          height: 100%;

          &__title {
            font-size: 2.4rem;
            font-weight: 500;
            color: #141414;
            margin-bottom: 12px;
          }

          &__subtitle {
            font-size: 1.6rem;
            font-weight: 500;
            color: #898989;
            margin-bottom: 8px;
          }
          &__subsubtitle {
            font-size: 1.4rem;
            font-weight: 400;
            color: #898989;
            margin-bottom: 8px;
          }
        }
      }

      &__plans {
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: 32px 24px;

        &__card {
          width: 100%;
          height: 367px;
          border-radius: 24px;
          box-shadow: -2px 0px 2px 0px rgba(0, 102, 51, 0.1),
            2px 0px 2px 0px rgba(0, 102, 51, 0.1),
            0px -2px 2px 0px rgba(0, 102, 51, 0.1),
            0px 2px 2px 0px rgba(0, 102, 51, 0.1);
          padding: 48px;

          &__title {
            font-size: 2.4rem;
            font-weight: 500;
            color: #141414;
            margin-top: 24px;
            margin-bottom: 12px;
          }
          &__subtitle {
            font-size: 1.6rem;
            font-weight: 500;
            color: #898989;
            margin-bottom: 24px;
          }

          & > button {
            font-size: 12px;
            font-weight: 500;
            border-radius: 12px;
            width: 107px;
            height: 37px;
            color: #ffffff;
            border: none;
            background: #149554;
          }
        }
      }

      &__bubbles {
        width: 1152px;
        height: auto;
        position: absolute;
        top: 0;
        left: 0;
      }
    }

    &__inner-grey {
      background: #fafafa;
    }
  }

  .how {
    width: 100%;
    max-width: 1440px;

    padding: 64px 144px;
    margin: auto;

    .title {
      font-size: 3.6rem;
      font-weight: 700;
      color: #141414;
      text-align: center;
      margin-bottom: 1.2rem;

      span {
        color: #063;
      }
    }

    .subtitle {
      text-align: center;
      font-size: 1.4rem;
      font-weight: 400;
      color: #898989;
      margin-bottom: 2.4rem;

      span {
        color: #0d924f;
        font-size: 1.6rem;
        font-weight: 500;
        text-decoration: underline;
      }
    }

    .play {
      position: relative;
      width: 76rem;
      height: 49.3rem;
      background: url("../images/image\ 33.png");
      margin: auto;
      // display: flex;
      // align-items: center;
      // justify-content: center;
      margin-bottom: 2.4rem;

      & > svg {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }
    }

    button {
      display: flex;
      align-items: center;
      justify-content: center;
      border: none;
      outline: none;
      border-radius: 12px;
      background-color: #063;
      width: 32.7rem;
      height: 8rem;
      font-size: 1.6rem;
      font-weight: 500;
      color: #fff;
      margin: auto;

      svg {
        margin-left: 1rem;
      }
    }
  }

  .compliance {
    width: 100%;
    max-width: 1440px;

    padding: 0 144px;
    margin: auto;
    &__title {
      font-size: 3.6rem;
      font-weight: 500;
      color: #141414;
      margin-bottom: 2.4rem;
      position: relative;

      display: flex;
      align-items: center;

      &__svg-group {
        display: flex;
        align-items: center;
        gap: 24px;
        position: absolute;
        top: 0;
        right: 0;

        & > div {
          width: 48px;
          height: 48px;
          border-radius: 50%;
          display: flex;
          align-items: center;
          justify-content: center;
          background: rgba(183, 221, 202, 0.3);
          cursor: pointer;

          &:hover {
            background: #00aed9;

            & > svg {
              fill: #fff;
            }
          }

          & > svg {
            fill: #3b3b3b;
          }
        }
      }
    }

    .compliance_group {
      display: flex;
      margin-bottom: 6.4rem;
      overflow: auto;
      scroll-behavior: smooth;

      &::-webkit-scrollbar {
        display: none;
      }

      .compliance_box {
        width: 36.8rem;
        min-height: 21.6rem;
        border: 1px solid #0d924f;
        border-radius: 12px;
        padding: 19px 24px 13px 24px;
        margin-right: 2.4rem;

        img {
          width: 3.2rem;
          height: auto;
          margin-bottom: 1.2rem;
        }

        .title {
          font-size: 1.6rem;
          font-weight: 400;
          color: #141414;
          margin-bottom: 1.2rem;
        }

        .subtitle {
          font-size: 1rem;
          font-weight: 400;
          color: #3b3b3b;
        }
      }
    }
  }

  .attain {
    width: 100%;
    max-width: 1152px;
    margin: auto;
    background: linear-gradient(
      100deg,
      rgba(198, 232, 215, 0.3) 0%,
      rgba(22, 161, 89, 0.3) 50.09%,
      rgba(5, 102, 53, 0.3) 98.75%
    );
    min-height: 40rem;
    border-radius: 12px;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-bottom: 64px;

    & > :first-child {
      font-size: 4.8rem;
      font-weight: 400;
      color: #141414;
      text-align: center;
    }
    span {
      color: #0d924f;
      font-weight: 500;
    }

    .button_group {
      display: flex;
      align-items: center;
      width: fit-content;
      margin-top: 4.8rem;
      button {
        display: flex;
        align-items: center;
        border: none;
        outline: none;
        border-radius: 12px;
        background-color: #063;
        color: #fff;
        min-width: none;
        width: 19rem;
        min-height: 8rem;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 1.6rem;
        margin-right: 2.4rem;
        svg {
          margin-left: 1rem;
        }
      }

      .transparent {
        // background: transparent;
        color: #fff !important;
        text-decoration: underline;
        font-size: 2.4rem;
        font-weight: 400;
        border: none;
        margin: 0 !important;
      }
    }
  }

  .faq {
    width: 100%;
    max-width: 1440px;
    margin: auto;
    padding: 0 144px;
    margin-bottom: 17rem;

    .title {
      font-size: 4.8rem;
      font-weight: 400;
      color: #141414;
      text-align: center;
      margin-bottom: 3.2rem;
    }

    .faq_card {
      width: 100%;
      max-width: 74.4rem;
      min-height: 9.6rem;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      padding: 12px 18px;
      font-size: 2.4rem;
      color: #141414;
      font-weight: 500;
      margin: auto;
      border: 1px solid var(--Green-1, #0d924f);
      border-radius: 12px;
      margin-bottom: 3.2rem;

      .card_inner {
        display: grid;
        align-items: start;
        grid-template-columns: auto 4.8rem;
        justify-content: space-between;

        .plus {
          background-color: rgba(13, 146, 79, 0.3);
          width: 4.8rem;
          height: 4.8rem;
          border-radius: 50%;
          position: relative;
          cursor: pointer;

          .item {
            width: 15px;
            height: 1.5px;
            background-color: #141414;
            border-radius: 1px;
            position: absolute;
            top: 50%;
            right: 50%;
            transform: translate(50%, 50%);
            transition: 0.3s;
          }

          .item2 {
            transform: translate(50%, 50%) rotate(90deg);
          }

          .itemOpaque {
            opacity: 0;
          }
        }
      }

      .subtitle {
        font-size: 1.4rem;
        font-weight: 400;
        color: #b1b1b1;
        margin-top: 2rem;
        margin-bottom: 1.5rem;
      }

      .line {
        height: 0.1rem;
        width: 96%;
        background-color: #b1b1b1;
      }
    }
  }
}

@keyframes heromarquee {
  100% {
    transform: translateX(-276%);
  }
}
@keyframes heromarqueeMobile {
  100% {
    transform: translateX(-637%);
  }
}

@media (max-width: 1200px) {
  .home {
    .hero {
      // height: 100vh;
      // min-height: unset;

      .hero_inner {
        padding: 0 18px;
        .title {
          font-size: 3.2rem;
          margin-bottom: 1.6rem;
        }

        .subtitle {
          font-size: 1.6rem;
        }

        .hero_group {
          & > button {
            width: 16.3rem;
            min-height: 6rem;
            font-size: 16px;
          }
        }
      }
    }

    .platform {
      .platform_inner {
        padding: 32px 144px;
        height: auto;

        .dots {
          top: unset;
          bottom: 0;
        }

        .platform_group {
          grid-template-columns: 1fr;

          & > :first-child {
            margin-bottom: 15rem;
          }
          button {
            width: 100% !important;
          }
        }
      }
    }

    .features {
      .features_group {
        margin-bottom: 0;
        grid-template-columns: 1fr;
        & > div {
          margin-bottom: 1.2rem;
        }
        & > img {
          grid-row: 2;
          margin-bottom: 1.649rem;
        }
      }
    }
  }
}

@media (max-width: 1053px) {
  .home {
    .hero {
      // height: 100vh;
      // min-height: unset;

      .hero_inner {
        padding: 0 18px;
        .title {
          font-size: 3.2rem;
          margin-bottom: 1.6rem;
        }

        .subtitle {
          font-size: 1.6rem;
        }

        .hero_group {
          button {
            width: 16.3rem;
            min-height: 6rem;
          }
        }
      }

      .scroll_container {
        .scroll {
          div {
            font-size: 1.4rem !important;
            padding: 0 8px !important;
            height: 4.9rem !important;
            margin-right: 1.6rem !important;
          }
        }
      }
    }
    .projects {
      margin-top: 3.2rem;
      padding: 0 18px !important;
    }
    .reach {
      padding: 24px 18px;
    }

    .platform {
      .platform_inner {
        padding: 32px 18px;
      }
    }

    .features {
      padding: 24px 18px;
    }
    .how {
      padding: 24px 18px;

      .play {
        width: 100%;
      }

      button {
        width: 27.8rem;
        height: 5.9rem;
      }
    }

    .compliance {
      margin: 2.4rem auto;
      padding: 0 18px;
      .compliance_group {
        margin-bottom: 0;
      }
    }

    .attain {
      margin-bottom: 3.2rem;
      & > :first-child {
        font-size: 3.6rem;
      }

      .button_group {
        button {
          width: 17rem;
          height: 6.2rem;
        }
      }
    }

    .faq {
      padding: 0 18px;
      .faq_card {
        max-width: unset;
      }
    }
  }
}

@media (max-width: 600px) {
  .home {
    .hero {
      height: 84.4rem;
      min-height: unset;
      .hero_inner {
        .hero_group {
          button {
            width: fit-content;
            padding: 0 18px;
            white-space: nowrap;
            min-height: 6rem;
            margin-left: 1.6rem;
          }
        }
      }
    }

    .projects {
      .title {
        font-size: 2.4rem;
      }
      .subtitle {
        font-size: 1.2rem;
      }
      .project_inner_mobile {
        flex-direction: column;
        height: auto;
        align-items: center !important;

        .box {
          width: 100%;
          margin-bottom: 1.2rem;
        }
      }
    }
    .reach {
      padding: 24px 0;
      .title {
        font-size: 2.4rem;
        margin-bottom: 1.6rem;
      }

      .reach_group {
        padding: 0 !important;
        flex-direction: column;

        & > div {
          margin: 1.6rem 0.8rem;
          .title {
            font-size: 2.4rem;
          }
        }
      }
    }

    .partnership {
      padding: 16px 0 34px 0;
      margin-bottom: 2.4rem;
      .title {
        font-size: 2.4rem;
        margin-bottom: 2.4rem;
      }
      .partners_container {
        .partners {
          img {
            width: 100px;
            margin-right: 1.6rem;
          }
        }
      }
    }

    .platform {
      .platform_inner {
        .platform_group {
          & > :first-child {
            .subtitle {
              font-size: 1.2rem;
              margin-bottom: 2.4rem;
            }

            .title {
              font-size: 3.2rem;
              margin-bottom: 1.2rem;
            }

            .subsubtitle {
              font-size: 1.4rem;
            }

            button {
              width: 35.8rem;
              height: 6.5rem;
            }
          }
          .view {
            width: 100%;
            display: flex;
            justify-content: center;
            margin-top: -4rem;
            margin-bottom: 4rem;
            .people {
              top: -48px;
              left: 52px;
              .people_group {
                margin-bottom: 1.2rem;
                img {
                  width: 1.6rem;
                  margin-right: 0.8rem;
                }
              }
              .person {
                width: 5.4rem;
                height: 4.4rem;
                border-radius: 4px;
                img {
                  width: 1.6rem;
                  margin-bottom: 0.2rem;
                }
                div {
                  font-size: 0.4rem;
                }
              }
            }
            img {
              width: 206px;
              margin: auto;
            }
          }
        }
        .dots {
          width: 534px;
          height: 380px;
        }
      }
    }

    .features {
      .features_group {
        .tracking {
          .title {
            font-size: 2.4rem;
            margin-bottom: 1.2rem;
          }

          .subtitle {
            font-size: 1.2rem;
          }

          .ticks_grid {
            grid-column-gap: 12px;
            grid-row-gap: 16px;

            & > div {
              font-size: 1.2rem;
              svg {
                margin-right: 0.8rem;
              }
            }
          }
        }
      }
    }

    .how {
      .title {
        font-size: 2.4rem;
        margin-bottom: 1.2rem;
      }

      .subtitle {
        font-size: 1.2rem;

        span {
          font-size: 1.2rem;
        }
      }

      .play {
        width: 100%;
        height: 23.2rem;
        background: url("../images/mobile_play.png");

        svg {
          width: 4.7105rem;
          height: 4.7105rem;
        }
      }

      button {
        width: 27.8rem;
        height: 5.9rem;
      }
    }

    .compliance {
      .title {
        font-size: 1.8rem;
        margin-bottom: 1.6rem;
      }

      .compliance_group {
        .compliance_box {
          width: 17.3rem;
          min-height: 24.9rem;
        }
      }
    }

    .attain {
      border-radius: 0;
      padding: 32px 42px 80px 42px;
      & > :first-child {
        font-size: 3.6rem;
      }

      .button_group {
        margin-top: 7.2rem !important;
        button {
          width: 17rem;
          height: 6.2rem;
        }
      }
    }

    .faq {
      .title {
        font-size: 2.4rem;
        margin-bottom: 1.6rem;
      }

      .faq_card {
        font-size: 1.6rem;

        .card_inner {
          .plus {
            width: 3.2rem;
            height: 3.2rem;

            .item {
              width: 10px;
            }
          }
        }
      }
    }
  }
}
