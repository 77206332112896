.admin_settings {
  display: flex;

  .sidebar_container {
    // background-color: yellow;
    //  height: 100%;
    max-height: 700px;
    position: relative;

    @media (max-width: 1024px) {
      display: none;
    }
  }
  .main {
    max-width: 1800px;
      width: 100%;
      margin: auto;
    height: 100vh;
    background-color: #fff;
    padding: 44px;
    overflow-y: auto;

    @media (max-width: 565px) {
      padding: 24px !important;
    }

    .title {
      font-size: 2.4rem;
      font-weight: 700;
      color: #1a1a1a;
      margin-bottom: 2.4rem;

      @media (max-width: 768px) {
        font-size: 16px;
        margin-bottom: 16px;
      }
    }
    .main_inner {
      width: 100%;
      min-height: 800px;
      border-radius: 24px;
      border: 1px solid #063;
      display: grid;
      grid-template-columns: 1.3fr 7fr;
      padding: 32px 87px;

      
      @media (max-width: 768px) {
        grid-template-columns: 1fr;
        padding: 32px 8px;
 
      }


      .settings_sidebar {
        height: 90%;
        border-right: 0.5px solid #98a2b3;
        font-size: 1.6rem;
        color: #98a2b3;
        padding: 43px 24px;

        
        @media (max-width: 768px) {
          border-right: none;
   
        }

        .admin_tab_active {
            color: #063;
            font-weight: 600;
        }

        & > div {
          margin-bottom: 3.6rem;
          cursor: pointer;
        }
      }
      .tab {
        width: 100%;
        height: 800px;
        padding: 43px 18px;

        @media (max-width: 565px) {
          padding: 0 18px !important;
        }

        .header {
            width: 74.5rem;
            max-width: 100%;

            
            @media (max-width: 1024px) {
              width: 100%;
            }
        }

        .tab_flex {
          display: flex;
          align-items: center;
          justify-content: space-between;
          padding: 19px;

          @media (max-width: 565px) {
            padding: 12px 0 !important;
          }

          .title {
            font-size: 1.4rem;
            font-weight: 700;
            margin-bottom: 0.6rem;

            @media (max-width: 565px) {
              font-size: 1.2rem;
            }
          }

          .subtitle {
            font-size: 1.2rem;
            color: #98a2b3;

            @media (max-width: 565px) {
              font-size: 8px;
              word-break: break-all;
              word-wrap: break-word;
              white-space: wrap;
            }
          }
          .button_group {
            display: flex;
            align-items: center;

            .transparent {
              background: transparent;
              border: 0.5px solid #063;
              border-radius: 8px;
              color: rgba(2, 164, 204, 0.4);
              min-width: 11.8rem;
              padding: 0 16px;
              height: 4rem;
              outline: none;
              margin-right: 1.2rem;

              @media (max-width: 565px) {
                padding: 0 8px;
                height: 3rem;
                font-size: 0.8rem;
                min-width: unset;
                width: fit-content;
              }
            }

            .filled {
              border-radius: 8px;
              background: linear-gradient(116deg, #063 0.76%, #02a4cc 100%);
              height: 4rem;
              min-width: 11.8rem;
              padding: 0 16px;
              outline: none;
              border: none;
              color: #fff;

              @media (max-width: 565px) {
                padding: 0 8px;
                height: 3rem;
                font-size: 0.8rem;
                min-width: unset;
                width: fit-content;
              }
            }
          }

          .tab_grid_button {
            .transparent, .filled {
                height: 5.2rem;
                min-width: 13.6rem;

                @media (max-width: 768px) {
                  padding: 0 12px;
                  height: 3rem;
                  font-size: 0.8rem;
                  min-width: unset;
                  width: fit-content;
                  white-space: nowrap;
                }
            }
        }
        }

        .tab_divider {
          height: 0.5px;
          background-color: #98a2b3;
          max-width: 750px;
          width: 100%;
          margin: 2.5rem 0;
        }

        .label {
          font-size: 1.2rem;
          color: #1a1a1a;
          margin-bottom: 1.2rem;
          margin-left: 1.9rem;

          
          @media (max-width: 565px) {
            margin-left: 0;
          }
        }

        .input {
          width: 52.8rem;
          max-width: 100%;
          height: 5.9rem;
          border: 1px solid #E5E5E5;
          border-radius: 12px;
          padding-left: 24px;
          outline: none;
          margin-bottom: 2.4rem;
          margin-left: 1.9rem;

          @media (max-width: 768px) {
            width: 100%;
            margin-left: 0 ;
          }
        }

        .photo_input {
            width: 52.8rem;
            height: 12.6rem;
            max-width: 100%;
            border: 1px solid #E5E5E5;
            border-radius: 12px;
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 0 12px;
            font-size: 1.2rem;
            color: #667185;
            margin-bottom: 2.4rem;
            margin-left: 1.9rem;

            @media (max-width: 768px) {
              width: 100%;
            }
        }

        .tab_grid {
            display: grid;
            grid-template-columns: 1fr 1fr 1fr;
            grid-column-gap: 36px;
            font-size: 1.2rem;
            width: 40.9rem;
            max-width: 100%;

            @media (max-width: 768px) {
              grid-column-gap:8px;

            }
            @media (max-width: 565px) {
              font-size: 0.8rem;

            }

        }
        
      }
    }
  }
}
