.thirdparty_sidebar {
  width: 24.4rem;
  height: 100vh;
  overflow: auto;
  background-color: #fafafa;
  // display: flex;
  // flex-direction: column;
  // justify-content: space-between;
  padding-left: 3.2rem;
  padding-top: 6.5rem;
  padding-bottom: 6.5rem;
  font-size: 16px;

  &::-webkit-scrollbar {
    display: none;
}

@media (max-width: 1024px) {
  justify-content: space-around;
}


  .logo_container {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    // background-color: yellow;

    @media (max-width: 1024px) {
      display: none;
  }

    .logo {
      width: 8.3rem;
      height: 8.077rem;
      margin: auto !important;
      margin-left: -1.6rem !important;
      // background-color: gray;
    }
  }

  &__ipad-pro {
    display: none;

    @media (max-width: 1024px) {
        display: initial;
    }
}

  .menu {
      margin-top: 7.5rem;

      margin-bottom: 12.6rem;
  

    .menu_item {
      display: flex;
      align-items: center;
      margin-bottom: 43px;
      cursor: pointer;
      height: 40px;

      @media (max-width: 768px) {
        margin-bottom: 4.8rem;
        font-size: 1.2rem;
    }

      & > div {
        color: #98a2b3;
      }

      svg {
        margin-right: 1.2rem;

        @media (max-width: 768px) {
          width: 16px;
      }
      }
    }

    .menu_active {
      background-color: #063;
      padding: 16px;
      border-radius: 8px 0 0 8px;
      height: 59px;


      & > div {
        color: #fff;
      }
      svg {
        display: unset;
        path {
          fill: white !important;
        }
      }
      .stroke {
        path {
          stroke: #fff !important;
        }
      }
    }

    .active_iot {
      background-color: #063;
      padding: 16px;
      border-radius: 8px 0 0 8px;
      height: 59px;

      
      & > div {
          color: #fff;
      }
      svg {
          display: unset;
          path {
              fill: none !important;
              stroke: white !important;
          }
      }
  }
  }

  .logout {
    display: flex;
    align-items: center;

    & > div {
      color: #98a2b3;
    }

    svg {
      margin-right: 1.2rem;
    }
  }
}
