.onboarding-template {
  width: 1000px;
  max-width: 100%;
  padding: 32px;
  height: 100vh;
  overflow: auto;
  margin: auto;

  @media (max-width: 565px) {
    padding: 32px 24px;
  }

  &__title {
    font-size: 3.2rem;
    font-weight: 700;
    color: #063;
    margin-bottom: 1.2rem;

    @media (max-width: 565px) {
      font-size: 2.4rem;
    }
  }
  &__subtitle {
    font-size: 1.2rem;
    font-weight: 400;
    color: #667185;
    margin-bottom: 1.2rem;
  }

  .steps {
    display: flex;
    align-items: center;
    width: fit-content;
    margin-bottom: 2.4rem;
    .circle {
      width: 2.4rem;
      height: 2.4rem;
      border: 1px solid #006633;
      border-radius: 50%;
      margin: 0 !important;
      display: flex;
      align-items: center;
      justify-content: center;
      svg {
        opacity: 0;
      }
    }

    .circle_active {
      background: linear-gradient(116deg, #006633 0.76%, #00aed9 100%);
      svg {
        opacity: 1;
        transition: 1.7s;
      }
    }
    .line {
      width: 35px;
      height: 1px;
      background-color: #006633;
      margin: 0 !important;
    }
  }

  &__input-label {
    font-size: 1.6rem;
    font-weight: 600;
    color: #282828;
    margin-bottom: 1.2rem;
    display: block;
    position: relative;
    width: fit-content;
  }
  &__info {
    font-size: 1.2rem;
    font-weight: 600;
    color: #282828;
    margin-bottom: 1.2rem;
    display: block;
    position: relative;
    width: fit-content;

    & > a {
      color: #0d924f;
    }
  }

  &__input-label::before {
    content: "*";
    position: absolute;
    right: -7px;
    top: -3px;
    color: #d10c0c;
  }
  &__input-label-no-imp::before {
    content: "" !important;
  }
  &__input {
    color: #667185;
    border: none;
    border-radius: 12px;
    height: 5.5rem;
    width: 100%;
    outline: none;
    font-size: 1.2rem;
    margin-bottom: 3.2rem;
    border: 1px solid #E5E5E5;
    padding-left: 24px;
  }
  &__country-input {
    color: #667185;
    border: none;
    border-radius: 12px;
    height: 5.5rem !important;
    width: 100%;
    outline: none;
    font-size: 1.2rem;
    margin-bottom: 3.2rem;
    border: 1px solid #E5E5E5;
    padding-left: 24px;
    background: pink;
  }

  &__input-text {
    color: #d42620;
    font-size: 12px;
    font-weight: 500;
    margin-top: -2rem;
    margin-bottom: 3.2rem;
  }

  &__grid {
    display: grid;
    grid-template-columns: 558px 135px;
    align-items: center;

    &__title {
      color: #141414;
      font-size: 16px;
      font-weight: 500;
      margin-bottom: 3.2rem;

      span {
        color: #00aed9;
      }
    }

    &__check-box {
      display: flex;
      justify-content: space-between;
      align-items: center;
      color: #898989;
      font-size: 14px;
      font-weight: 400;
      gap: 8px;
      margin-bottom: 3.2rem;

      & > div {
        display: flex;
        align-items: center;
        gap: 4px;

        input {
          width: 24px;
          height: 24px;
          border-radius: 6px;
          border: 2px solid #063;
        }
      }
    }
  }

  &__input-upload {
    width: 100%;
    border-radius: 12px;
    border: 0.5px solid var(--Grey-2, #b1b1b1);
    margin-bottom: 3.2rem;
    position: relative;

    &__flex {
      display: grid;
      grid-template-columns: 4fr 1.5fr;
      height: 72px;
      border-bottom: 0.5px solid #b1b1b1;

      &__title {
        width: 100%;
        height: 100%;
        border-radius: 12px 0px 0px 0px;
        display: flex;
        align-items: center;
        color: var(--Grey, #898989);
        padding-left: 24px;
        font-size: 14px;
        font-weight: 400;
      }

      &__button {
        width: 100%;
        height: 100%;
        border-radius: 0px 12px 0px 0px;
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 12px;
        color: #fff;
        background: #0d924f;
        color: var(--White, #fff);
        font-size: 12px;
        font-weight: 700;
      }
    }
    &__drag {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      padding: 32px 0;
      cursor: pointer;

      svg {
        margin-bottom: 1.2rem;
      }

      &__title {
        color: var(--Grey, #898989);
        font-size: 12px;
        font-weight: 500;

        span {
          font-weight: 700;
          text-decoration: underline;
        }
      }

      &__info {
        color: var(--Grey, #898989);
        font-size: 8px;
        font-weight: 400;
        margin-bottom: 0.3rem;
      }
    }
  }

  &__input-select {
    // padding-right: 1.5rem; /* Adjust the value as needed */
    appearance: none; /* Remove default dropdown icon */
    background-image: url("../../images/Vector.png"); /* Replace with your desired icon */
    background-repeat: no-repeat;
    background-position: right 2.5rem center; /* Adjust the position as needed */
    background-size: 1rem;
  }
  &__input-date {
    // padding-right: 1.5rem; /* Adjust the value as needed */
    appearance: none; /* Remove default dropdown icon */
    background-image: url("../../images/calendar-svgrepo-com.svg"); /* Replace with your desired icon */
    background-repeat: no-repeat;
    background-position: right 2.5rem center; /* Adjust the position as needed */
    background-size: 1.4rem;
  }
  &__input-date::-webkit-calendar-picker-indicator {
    width: 80%;
    opacity: 0;
    // display: none; /* Remove default calendar icon in WebKit-based browsers */
  }

  &__button {
    width: 100%;
    height: 72px;
    border-radius: 12px;
    background: #0d924f;
    display: flex;
    align-items: center;
    justify-content: center;
    border: none;
    outline: none;
    color: #ffffff;
    font-size: 1.6rem;
    font-weight: 600;
    cursor: pointer;
    transition: 0.7s;

    & > div {
      margin-right: 12px;
      color: #ffffff;
    }

    &:hover {
      transition: 0.7s;

      background: none;
      color: #006633;
      border: 1px solid #0d924f;

      & > div {
        color: #0d924f;
      }
      svg {
        path {
          fill: #0d924f;
        }
      }
    }
  }

  .errorModal {
    width: 100%;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    background: rgba(0, 0, 0, 0.7);
    z-index: 1;
  }

  .error_modal {
    width: 31.7rem;
    min-height: 16.5rem;
    background-color: #fff;
    position: absolute;
    top: 50%;
    left: 50%;
    z-index: 2;

    transform: translate(-50%, -50%);
    /* background-color: #ffffff; */
    box-shadow: 24;
    /* height: 641, */
    overflow: auto;
    padding: 18px;
    border-radius: 12px;
    border: none !important;
    display: flex;
    flex-direction: column;
    justify-content: center;

    .modal_title {
      font-size: 1.8rem;
      font-weight: 700;
      color: #141414;
      margin-bottom: 1.2rem;
    }
    .modal_subtitle {
      font-size: 1.2rem;
      font-weight: 400;
      margin-bottom: 1.2rem;
    }

    .error_modal_button {
      width: 100% !important;
      height: 3.6rem;
      border-radius: 12px;
      background: transparent;
      border: 1px solid var(--Gradient, #063);
      display: flex;
      align-items: center;
      justify-content: center;
      outline: none;
      color: #063;
      font-size: 1.6rem;
      font-weight: 600;
      cursor: pointer;
      transition: 0.7s;
      white-space: nowrap;
      padding: 0 36px;
    }
  }

  &__modal {
    width: 100%;
    height: 100vh;
    position: absolute;
    top: 0;
    left: 0;
    background: rgba(0, 0, 0, 0.7);
    z-index: 1;
  }

  &__modal-card {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: #fff;
    padding: 32px;
    z-index: 2;
  }

  &__stake1 {
    width: 294px;
    height: 171px;
    border-radius: 12px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    z-index: 2;


    &__title {
      font-size: 1.6rem;
      font-weight: 500;
      color: #141414;
      text-align: center;
      margin-bottom: 2.4rem;
    }

    &__button-group {
      display: flex;
      align-items: center;
      gap: 24px;
      justify-content: center;

      & > :first-child {
        width: 120px;
        height: 40px;
        border-radius: 8px;
        background: rgba(13, 146, 79, 1);
        border: none;
        outline: none;
        font-size: 1.4rem;
        font-weight: 500;
        color: #fff;
      }

      & > :last-child {
        border: none;
        outline: none;
        width: fit-content;
        text-decoration: underline;
        color: #0d924f;
        background: none;
        font-size: 1.6rem;
        font-weight: 500;
      }
    }
  }

  &__stake2 {
    width: 368px;
    height: 343px;
    border-radius: 12px;
    display: flex;
    flex-direction: column;
    z-index: 2;


    &__title {
      font-size: 1.6rem;
      font-weight: 500;
      color: #141414;
      text-align: center;
      margin-bottom: 0.6rem;
    }
    &__subtitle {
      font-size: 1.2rem;
      font-weight: 400;
      color: #898989;
      margin-bottom: 4.6rem;
      text-align: center;
    }

    &__form {
      width: 100%;
      height: 60px;
      border-radius: 12px;
      border: 0.5px solid #898989;
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 15px 24px;
      margin-bottom: 1.6rem;

      &__input {
        width: 100%;
        height: 100%;
        border: none;
        outline: none;
      }

      &__button {
        width: 50px;
        height: 30px;
        border-radius: 8px;
        background: rgba(13, 146, 79, 0.38);
        border: none;
        outline: none;
        font-size: 1.4rem;
        font-weight: 500;
        color: #0d924f;
      }
    }

    &__button {
      width: 304px;
      height: 52px;
      border-radius: 12px;
      background: #0d924f;
      outline: none;
      border: none;
      font-size: 1.4rem;
      font-weight: 500;
      color: #fff;
    }

    &__disclaimer {
      font-size: 1rem;
      font-weight: 400;
      margin-top: 1.2rem;
      color: #d42620;
    }
  }
  &__stake4 {
    width: 446px;
    height: 570px;
    border-radius: 12px;
    display: flex;
    flex-direction: column;
    background: #fff;
    z-index: 2;

    &__title {
      font-size: 1.6rem;
      font-weight: 500;
      color: #141414;
      text-align: center;
      margin-bottom: 0.6rem;
    }
    &__subtitle {
      font-size: 1.2rem;
      font-weight: 400;
      color: #898989;
      margin-bottom: 4.6rem;
      text-align: center;
    }

    &__group {
      display: flex;
      align-items: start;
      justify-content: space-between;

      &__available {
        font-size: 1.2rem;
        font-weight: 500;
        color: #141414;

        & > :last-child {
          color: #0d924f;
          font-size: 1.6rem;
          margin-left: 43px;
        }
      }

      &__address {
        width: 102px;
        height: 28px;
        border-radius: 24px;
        border: 0.5px solid #898989;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }

    &__group2 {
      display: flex;
      align-items: start;
      justify-content: space-between;

      &__available {
        font-size: 1.2rem;
        font-weight: 400;
        color: #141414;

        & > :last-child {
          color: #0d924f;
          font-weight: 500;
          font-size: 1.4rem;
        }
      }

      &__address {
        width: 102px;
        height: 28px;
        border-radius: 24px;
        border: 0.5px solid #898989;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }

    &__divider {
      height: 1px;
      width: 100%;
      background: #efefef;
      margin: 24px auto;
    }

    &__form {
      width: 100%;
      height: 60px;
      border-radius: 12px;
      border: 0.5px solid #898989;
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 15px 24px;
      margin-bottom: 1.6rem;

      &__input {
        width: 100%;
        height: 100%;
        border: none;
        outline: none;
      }

      &__button {
        width: 50px;
        height: 30px;
        border-radius: 8px;
        background: rgba(13, 146, 79, 0.38);
        border: none;
        outline: none;
        font-size: 1.4rem;
        font-weight: 500;
        color: #0d924f;
      }
    }

    &__button {
      width: 100%;
      height: 52px;
      border-radius: 12px;
      background: #0d924f;
      outline: none;
      border: none;
      font-size: 1.4rem;
      font-weight: 500;
      color: #fff;
    }

    &__disclaimer {
      font-size: 1rem;
      font-weight: 400;
      margin-top: 1.2rem;
      color: #d42620;
    }
  }

  &__stake5 {
    width: 247px;
    height: 170px;
    border-radius: 12px;
    background: #fff;
    padding: 16px;
    z-index: 2;

    &__close {
      width: 100%;
      display: flex;
      justify-content: end;
      margin-bottom: 1.2rem;
    }

    &__fail {
      width: 100%;
      margin-bottom: 1.6rem;
    }

    &__title {
      color: #141414;
      text-align: center;
      font-size: 1rem;
      font-weight: 500;
      margin-bottom: 0.6rem;
    }

    &__subtitle {
      color: var(--Grey, #898989);
      font-size: 1rem;
      font-weight: 400;
      text-align: center;
    }
  }
  &__stake6 {
    min-width: 247px;
    min-height: 212px;
    border-radius: 12px;
    background: #FFF;
    padding: 16px;
    z-index: 2;

    &__close {
      width: 100%;
      display: flex;
      justify-content: end;
      margin-bottom: 1.2rem;
    }

    &__success {
      width: 100%;
      margin-bottom: 2.4rem;
    }

    &__title {
      color: #141414;
      text-align: center;
      font-size: 1rem;
      font-weight: 500;
      margin-bottom: 0.6rem;
    }

    &__subtitle {
      color: var(--Grey, #898989);
      font-size: 1rem;
      font-weight: 400;
      text-align: center;
      margin-bottom: 2.4rem;
    }
    &__subsubtitle {
      color: var(--Green-1, #0D924F);
text-align: center;
font-size: 0.8rem;
font-weight: 400;
    }
  }

  &__stake7 {
    width: 446px;
    height: 459px;
    border-radius: 12px;
    display: flex;
    flex-direction: column;
    background: #fff;
    z-index: 2;

    &__title {
      font-size: 1.6rem;
      font-weight: 500;
      color: #141414;
      text-align: center;
      margin-bottom: 0.6rem;
    }
    &__subtitle {
      font-size: 1.2rem;
      font-weight: 400;
      color: #898989;
      margin-bottom: 4.6rem;
      text-align: center;
    }

    &__group {
      display: flex;
      align-items: start;
      justify-content: space-between;

      &__available {
        font-size: 1.2rem;
        font-weight: 500;
        color: #141414;

        
      }

      &__address {
        width: 102px;
        height: 28px;
        border-radius: 24px;
        border: 0.5px solid #898989;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }

    &__group2 {
      display: flex;
      align-items: start;
      justify-content: space-between;

      &__available {
        font-size: 1.4rem;
        font-weight: 400;
        color: #141414;

        & > :last-child {
          color: var(--Grey, #898989);
font-size: 1.2rem;
font-weight: 400;
margin-top: 1.2rem;
        }
      }

      &__amount{
        & > :first-child {
          color: var(--Grey, #898989);
text-align: right;
font-size: 1.2rem;
font-weight: 400;
        }

        & > :last-child {
          color: var(--Green-1, #0D924F);
          font-size: 1.4rem;
          font-weight: 500;
        }
      }
    }

    &__divider {
      height: 1px;
      width: 100%;
      background: #efefef;
      margin: 24px auto;
    }

  

    &__button-group {
      display: flex;
      gap: 24px;
      height: 52px;

      button {
        width: 100%;
        height: 100%;

        outline: none;
      border: none;
      border-radius: 12px;
      font-size: 1.4rem;
      font-weight: 500;


      }

      & > :first-child {
      background: #0d924f;
      
      color: #fff;
      }

      & > :last-child {
        border: 1px solid var(--Green-1, #0D924F);
        color: var(--Green-1, #0D924F);
        background: transparent;
      }
    }

  }

  &__stake8 {
    width: 322px;
    height: 226px;
    border-radius: 12px;
    display: flex;
    flex-direction: column;
    background: #fff;
    z-index: 2;
    padding: 32px;

    &__title {
      font-size: 1.6rem;
      font-weight: 500;
      color: #141414;
      text-align: center;
      margin-bottom: 0.6rem;
    }
    &__subtitle {
      font-size: 1.2rem;
      font-weight: 400;
      color: #898989;
      margin-bottom: 2.4rem;
      text-align: center;
    }

    &__grid {
      display: grid;
      margin: 0 auto;
      width: 200px;
      grid-template-columns: 1fr 1fr;
      align-items: center;
      gap: 12px 32px;
      color: var(--Grey, #898989);
font-size: 14px;
font-weight: 400;
text-decoration: underline;

& > div {
  cursor: pointer;

  &:hover {
    color: #0D924F;
    font-weight: 500;
  }
}


    }
  }

  &__stake9 {
    width: 279px;
    height: 191px;
    border-radius: 12px;
    background: #fff;
    padding: 16px;
    z-index: 2;

    &__close {
      width: 100%;
      display: flex;
      justify-content: end;
      margin-bottom: 1.2rem;
    }

    &__success {
      width: 100%;
      margin-bottom: 1.6rem;
    }

    &__title {
      color: #141414;
      text-align: center;
      font-size: 1rem;
      font-weight: 400;
      margin-bottom: 1.2rem;
    }

    &__subtitle {
      color: var(--Green-1, #0D924F);
text-align: center;
font-size: 1.4rem;
font-weight: 700;
text-decoration: underline;
    }
  }

  


  &__vv-card {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: #fff;
    padding: 32px;
    z-index: 2;
    border-radius: 12px;

    &__title {
      color: #063;
font-size: 24px;
font-weight: 700;
margin-bottom: 32px;
    }

    &__group {
      display: flex;
      align-items: center;
      gap: 24px;
      margin-bottom: 24px;

      color: var(--Black, #141414);
font-size: 16px;
font-weight: 400;
    }

    &__button {
      width: 100%;
      max-width: 380px;
      height: 64px;
      border-radius: 12px;
      color: #FFF;
font-size: 14px;
font-weight: 500;
background: #0D924F;
border: none;
outline: none;
margin: auto;
display: flex;
align-items: center;
justify-content: center;
margin-top: 8px;
    }
  }

}
