.aboutFeedback {
    width: 100%;
    max-width: 956px;
    padding: 48px 147px 121px 147px;
    margin: auto;
    border: 1px solid  #0D924F;
    border-radius: 12px;
    margin-bottom: 20.8rem;

    .title {
        text-align: center;
        font-size: 3.2rem;
        font-weight: 500;
        color: #141414;
        margin-bottom: 1.6rem;
    }

    .subtitle {
        font-size: 1.6rem;
        font-weight: 400;
        color: #3B3B3B;
        text-align: center;
        margin-bottom: 4.8rem;
    }
    form {
        display: flex;
        flex-direction: column;
        align-items: center;
        .input_group {
            display: grid;
            grid-template-columns: 1fr 8fr;
            grid-column-gap: 34px;
            grid-row-gap: 48px;
            // align-items: center;
            margin-bottom: 6.4rem;

            label {
                font-size: 1.2rem;
                font-weight: 500;
                color: #141414;
                white-space: nowrap;
                span {
                    color: rgba(243, 29, 29, 1);
                    font-size: 1.6rem;
                }
            }
            .first_label {
                align-self: center;
            }
            .input_group_inner {
                display: flex;
                align-items: center;
                width: 100%;
                height: 5.6rem;
                border: 0.5px solid #898989;
                border-radius: 12px;
                padding: 12px;


                input {
                    width: 100%;
                    height: 100%;
                    border: none;
                    outline: none;
                }
                textarea {
                    width: 100%;
                    height: 100%;
                    border: none;
                    outline: none;
                }
                
                .divider {
                    width: 0.05rem;
                    height: 1.4rem;
                    background-color: #063;
                    margin: 0 0.8rem;
                }
            }

            .inner_max {
                height: 17.5rem;
                align-items: start;

                .divider {
                    margin-top: 0.4rem;
                }
            }
        }
        button {
            width: 32.4rem;
            height: 6.7rem;
            background-color: #063;
            font-size: 1.6rem;
            font-weight: 500;
            border: none;
            outline: none;
            border-radius: 12px;
            color: #fff;
            transition: 0.4s;

            &:hover {
              background-color: #0D924F;
            }
        }
    }
}

@media (max-width: 600px) {
    .aboutFeedback {
        width: 90%;
        padding: 48px 16px;

        .title {
            font-size: 2.4rem;
            margin-bottom: 1.2rem;
        }
        .subtitle {
            font-size: 1.4rem;
            margin-bottom: 3.6rem;
        }

        form {
            .input_group {
                grid-column-gap: 6px;
                grid-row-gap: 32px;
.input_group_inner {
    input, textarea {
font-size: 0.8rem;
    }

   
}
  
.inner_max {
    textarea {
        padding: 6px 0;
    }
}

            }
        }
    }
}