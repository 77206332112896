
.kyc_modal_container {
    width: 100%;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    background: rgba(0, 0, 0, 0.7);
    z-index: 3;

    .kyc_modal {
      width: 90%;
      max-width: 990px;
      height: 90vh;
      overflow: auto;
      margin: auto;
      background-color: #fff;
      // box-shadow: -24px 0px 12px 0px #66718559;
      box-shadow: 0px 16px 6px 0px #00000040;
      border-radius: 24px;
      position: fixed;
      top: 50%;
      left: 50%;
      padding: 32px;
      z-index: 3;

      transform: translate(-50%, -50%);

      .title {
        font-size: 3.2rem;
        font-weight: 700;
        color: #282828;
        margin-bottom: 0.6rem;

        @media (max-width: 768px) {
          font-size: 2.4rem !important;
        }
      }

      .subtitle {
        margin-bottom: 4.3rem;

        @media (max-width: 768px) {
          margin-bottom: 2.4rem !important;
        }
      }

      .kyc_grid {
        // background-color: yellow;
        display: grid;
        grid-template-columns: 1fr;
        grid-column-gap: 24px;
        grid-row-gap: 42px;

        @media (max-width: 1024px) {
          grid-template-columns: 1fr;
        }
        @media (max-width: 768px) {
          grid-row-gap: 12px;
        }

        .kyc_input {
          color: #667185;
          border: 0.5px solid  #667185;
          border-radius: 12px;
          height: 5.5rem;
          width: 100%;
          outline: none !important;
          font-size: 1.2rem;
          margin-bottom: 0.6rem;
          display: flex;
          align-items: center;
          padding-left: 24px;
          cursor: pointer;
        }

        .kyc_input-date {
          display: unset;
          appearance: none; /* Remove default dropdown icon */
          background-image: url("../../images/calendar-svgrepo-com.svg"); /* Replace with your desired icon */
          background-repeat: no-repeat;
          background-position: right 2.5rem center; /* Adjust the position as needed */
          background-size: 1.4rem;
        }
        .kyc_input-date::-webkit-calendar-picker-indicator {
          width: 80%;
          opacity: 0;
          cursor: pointer;
        }
        


        .kyc-checkbox-grid {
          width: 100%;
          justify-content: space-between;
          display: grid;
          grid-template-columns: 4.13fr 1fr;
          align-items: center;

          @media (max-width: 565px) {
            grid-template-columns: 1fr;

          }
      
          &__title {
            color: #141414;
            font-size: 16px;
            font-weight: 500;
            margin-bottom: 3.2rem;
      
            span {
              color: #00aed9;
            }
          }
      
          &__check-box {
            display: flex;
            justify-content: space-between;
            align-items: center;
            color: #898989;
            font-size: 14px;
            font-weight: 400;
            gap: 8px;
            margin-bottom: 3.2rem;
      
            & > div {
              display: flex;
              align-items: center;
              gap: 4px;
      
              input {
                width: 24px;
                height: 24px;
                border-radius: 6px;
                border: 2px solid #063;
              }
            }
          }
        }

        .kyc_label {
          font-size: 1.6rem;
          font-weight: 600;
          color: #282828;
          margin-bottom: 1.2rem;
          display: flex;
          align-items: center;
          white-space: wrap;

          @media (max-width: 768px) {
            font-size: 1.2rem;
            font-weight: 700;
          }

          svg {
            margin-left: 5px;
            cursor: pointer;
          }
        }
        

        .kyc_input_flex {
          display: flex;
          align-items: center;

          .kyc_input {
            margin-bottom: 0 !important;
            border-radius: 12px;

            @media (max-width: 768px) {
              font-size: 0.8rem;
            }
          }
        }

        .kyc_instructions {
          &__title {
            color: #282828;
font-size: 1rem;
font-weight: 500;
margin-bottom: 4px;
          }
          &__subtitle {
            color: #667185;
font-size: 1rem;
font-weight: 400;
margin-bottom: 2px;
display: flex;
align-items: center;
gap: 4px;
          }
        }

        .kyc_group {
          width: 100%;
          display: flex;
          align-items: center;
          gap: 24px;

          & > div {
            width: 100%;
          }
        }

        .kyc_upload_button {
          width: 133px;
          height: 55px;
          background-color: #00aed9;
          outline: none;
          border: none;
          border-radius: 12px;
          color: #fff;
          margin-left: -133px;
          display: flex;
          align-items: center;
          justify-content: center;
          font-size: 1.4rem;
          font-weight: 700;
          cursor: pointer;
        }

        .drag_drop {
          border: 0.5px dashed #374957;
          width: 100%;
          height: 12.3rem;
          background: #fafafa;
          border-radius: 12px;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          margin-top: 8px;

          &>div {
            font-weight: 700;
            color: #667185;
            // background: yellow;
          }
        }

        .highlight {
          background: linear-gradient(116.29deg, #006633 0.76%, #00aed9 100%) !important;
          &>div {
            font-weight: 700;
            color: #fff ;
            // background: yellow;
          }

          svg {
            path {
              fill: #fff;
            }
          }
        }
      }

      .kyc_submit {
        width: 100%;
        height: 72px;
        border-radius: 12px;
        background: linear-gradient(116.29deg, #006633 0.76%, #00aed9 100%);
        display: flex;
        align-items: center;
        justify-content: center;
        margin: auto;
        border: none;
        outline: none;
        color: #ffffff;
        font-size: 1.6rem;
        font-weight: 600;
        cursor: pointer;
        margin-top: 6rem;
        transition: 0.7s;

        @media (max-width: 768px) {
          width: 100% !important;
        }

        &>div {
          margin-right: 12px;
          color: #ffffff;
        }

        &:hover {
          transition: 0.7s;

          background: none;
          color: #006633;
          border: 1px solid #006633;

          &>div {
            color: #006633;
          }
        }
      }
    }

    .kyc_mini {
      width: 74.1rem !important;
      max-width: 90%;

      .title {
        @media (max-width: 768px) {
          font-size: 1.8rem !important;
        }
      }

      .mini_grid_title {
        font-size: 1.8rem;
        font-weight: 700;
        color: #1a1a1a;
        margin-bottom: 1.2rem;

        @media (max-width: 768px) {
          font-size: 1.2rem;
        }
      }
      
      .kyc_mini_grid {
        display: grid;
        grid-template-columns: 54.5rem 2.4rem;
        grid-column-gap: 20px;
        font-size: 1.4rem;
        margin-bottom: 2.4rem;

        @media (max-width: 768px) {
          grid-template-columns: auto 1fr;
          font-size: 1rem;
          grid-column-gap: 16px;
          margin-bottom: 1.2rem;
        }

        input {
          width: 2.4rem;
          height: 2.4rem;

          @media (max-width: 768px) {
            width: 1.6rem;
          height: 1.6rem;
          }
        }
      }
    }
  }

  .upload_modal {
    width: 100%;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    // background: rgba(0, 0, 0, 0.7);
    z-index: 3;
  }


  #onfido-mount {
    width: 100%;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    background: #FFF;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 4;

    &__loader {
      font-size: 32px;
      font-weight: 700;
      color: #000;
    }
  }

  .failed-id {
    width: 100%;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    background: rgba(0, 0, 0, 0.7);
    z-index: 3;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 5;


    .failed-id__modal {
      width: 31.7rem;
      height: 16.5rem;
      background: #FFF;
      border-radius: 12px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      svg {
        margin-bottom: 1.2rem;
      }

      &__title {
        font-size: 1.8rem;
        font-weight: 700;
        color: #1A1A1A;
        margin-bottom: 1.2rem;
      }

      &__subtitle {
        font-size: 1.2rem;
        color: #98A2B3;
        font-weight: 400;
        margin-bottom: 1.4rem;
      }

      &__button {
        width: 11.6rem;
        height: 3.6rem;
        background: transparent;
        border: 1px solid #063;
        border-radius: 12px;
        font-size: 1.6rem;
        font-weight: 600;
        color: #063;
      }
    }
  }