.project_auth {
    width: 100%;
    max-width: 1440px;
    padding: 64px 144px 91px 144px;
    margin: auto;
    .project_image {
        width: 100%;
        height: auto;
        margin: auto;
        border-radius: 12px;
        margin-bottom: 4.8rem;
        object-fit: cover;
    }

    .project_title {
        font-size: 3.6rem;
        font-weight: 500;
        color: #141414;
        margin-bottom: 1.6rem;
    }
    .project_subtitle {
        font-size: 1.6rem;
        font-weight: 400;
        color: #3b3b3b;
        line-height: 24px;

        ol {
            margin-top: 0;
        }
    }
}

@media (max-width: 1200px) {
    .project_auth {
        padding: 32px 16px 91px 16px;

        .project_image {
            margin-bottom: 1.6rem;
            height: 33.6rem;
        }

        .project_title {
            font-size: 2.4rem;
            margin-bottom: 1.2rem;
        }
        .project_subtitle {
            font-size: 1.2rem;
        }
    }
}