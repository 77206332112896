.fgs {
    background: url("../../images/green-world-with-two-trucks\ 1.png");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    border: none;
    min-height: 100vh;
    display: flex;
    align-items: center;
  
    .fgs_inner {
      display: grid;
      grid-template-columns: 1fr 1fr;
      align-items: center;
      padding: 0 100px;
      grid-column-gap: 100px;
      width: 1446px;
      max-width: 100%;
      margin: auto;
      
      .title {
        color: #ffffff;
        font-size: 48px;
      }
     
  
      .thirdparty_box {
        width: 100%;
        background-color: #fff;
        border-radius: 0;
        padding: 32px;
        height: 100vh;
        position: relative;
        
        .box_inner {
          display: flex;
          flex-direction: column;
          
        }
  
        color: #667185;
  
        .box_title {
          font-size: 24px;
          font-weight: 700;
          text-align: center;
          margin-bottom: 12px;
        }
        .box_subtitle {
          font-size: 1.4rem;
          // margin-bottom: 36px;
          text-align: center;
        }
  
        .otp_box_group{
          display: grid;
          grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
          // background-color: yellow;
         grid-column-gap: 12px;
         margin: auto;
         margin-top: 68px;
         margin-bottom: 60px;
          width: fit-content;
          .otp_box {
            width: 32px;
            height: 32px;
            // background-color: yellow;
          
            input {
              width: 32px !important;
              height: 32px !important;
              height: auto;
              // background-color: black;
              outline: none;
              border: none;
              border: 1px solid;
              border-color: linear-gradient(116.29deg, #006633 0.76%, #00AED9 100%);
              border-radius: 6px;
              font-size: 32px;
              text-align: center;
              color: #006633;
            }
          }
        }
        .otp_error_text {
          font-size: 1.2rem;
          font-weight: 700;
          color: #d42620;
          margin-top: -4.4rem;
          // margin-bottom: 2.4rem;
          text-align: center;
          // width: fit-content;
          // background-color: yellow;
        }
        .fgs_button {
          width: 100%;
          height: 72px;
          border-radius: 12px;
          background: linear-gradient(116.29deg, #006633 0.76%, #00aed9 100%);
          display: flex;
          align-items: center;
          justify-content: center;
          border: none;
          outline: none;
          color: #ffffff;
          font-size: 1.6rem;
          font-weight: 600;
          cursor: pointer;
          margin-top: 6rem;
          transition: 0.7s;
    
          & > div {
            margin-right: 12px;
            color: #fff;
          }
    
          &:hover {
            transition: 0.7s;
    
            background: none;
            color: #006633;
            border: 1px solid #006633;
            
          & > div {
            color: #006633;
          }
            
          }
        }
  
        .resend {
          font-size: 12px;
          text-align: right;
          margin-top: 28px;
          span {
            color: #006633;
            font-weight: 700;
          }
        }
      .fgs_back {
        margin-bottom: 32px;
      }  
        
      }
    }
  
    .modal {
      width: 100%;
      height: 100vh;
      position: absolute;
      top: 0;
      left: 0;
      background: rgba(0, 0, 0, 0.7);
      z-index: 1;
  
     
    }
  
    .thirdparty_email_modal {
      width: 488px;
      min-height: 432px;
      background-color: #ffffff;
      position: absolute;
  top: 50%;
  left: 50%;
  z-index: 2;
  transform: translate(-50%, -50%);
  /* background-color: #ffffff; */
  box-shadow: 24;
  /* height: 641, */
  overflow: auto;
  padding: 32px;
  border-radius: 0;
  border: none !important;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  
  .confirm_svg {
    margin-bottom: 24px;
  }
  
  .modal_title {
    font-size: 32px;
    font-weight: 700;
    color: #141414;
    margin-bottom: 12px;
  }
  .modal_subtitle {
    font-size: 14px;
    font-weight: 400;
  }
  
  ul {
    font-size: 14px;
    font-weight: 700;
  }
  
  .modal_email {
    font-weight: 700;
    font-size: 12px;
    margin-top: 2.4rem;
  }
  
  .modal_label{
    font-size: 16px;
    font-weight: 500;
    color: #141414;
    width: 100%;
    margin-bottom: 12px;
    margin-top: 12px;
  }
  .modal_input {
    width: 100%;
    color: #667185;
            border: none;
            border-radius: 12px;
            height: 5.5rem;
            outline: none !important;
            font-size: 1.2rem;
            // margin-bottom: 2.4rem;
  }
  
  .modal_button {
    width: 100%;
    height: 72px;
    border-radius: 12px;
    background: linear-gradient(116.29deg, #006633 0.76%, #00aed9 100%);
    display: flex;
    align-items: center;
    justify-content: center;
    border: none;
    outline: none;
    color: #ffffff;
    font-size: 1.6rem;
    font-weight: 600;
    cursor: pointer;
    margin-top: 3.6rem;
    margin-bottom: 2.4rem;
    transition: 0.7s;
  
    & > div {
      margin-right: 12px;
    }
  
    &:hover {
      transition: 0.7s;
  
      background: none;
      color: #006633;
      border: 1px solid #006633;
    
    }
  }
  
  .didnt_get {
    font-size: 12px;
  }
  }
   
  }
  
  @media (max-width: 1025px) {
    .fgs {
      .fgs_inner {
        padding: 0 40px;
      }
    }
  }
  