.donation-pool {
  display: flex;
  .sidebar_container {
    // background-color: yellow;
    //  height: 100%;
    max-height: 700px;
    position: relative;

    @media (max-width: 1024px) {
      display: none;
    }
  }
  .main {
    max-width: 1800px;
    width: 100%;
    margin: auto;
    padding: 44px;
    height: 100vh;
    overflow: auto;

    @media (max-width: 565px) {
      padding: 24px;
    }

    .title {
      font-size: 2.4rem;
      font-weight: 700;
      color: #1a1a1a;
      margin-bottom: 2.4rem;

      @media (max-width: 768px) {
        font-size: 1.6rem;
        margin-bottom: 1.6rem;
      }
    }
    .subtitle {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 16px;
      // position: relative;
      & > :first-child {
        color: #1a1a1a;
        font-size: 1.6rem;
        font-weight: 500;
        margin-bottom: 1.6rem;
      }

      & > :nth-child(even) {
        display: flex;
        align-items: center;
        gap: 12px;
        color: #1a1a1a;
        font-size: 1.2rem;
        font-weight: 400;
        border-radius: 8px;
border: 1px solid var(--Success-50, #E7F6EC);
background: #FFF;
padding: 10px;
cursor: pointer;
      }

      &__modal {
        width: fit-content;
        height: 200px;
        overflow-y: auto;
        position: absolute;
        top: 140px;
        right: 60px;
        border-radius: 4px 0px 0px 4px;
        border: 1px solid #efefef;
        z-index: 2;
        background: #e7f6ec;

        &::-webkit-scrollbar {
          display: none;
        }

  
        &__flex {
          display: flex;
          height: 3.1rem;
          color: #1a1a1a;
          align-items: center;
          margin-top: 2rem;
          font-size: 1.2rem;
          font-weight: 400;
          gap: 12px;
          margin-bottom: 1.2rem;
          padding: 0 8px;
          border-radius: 4px 0px 0px 4px;
          cursor: pointer;

  
          &:hover {
            background: #fff;
            color: #1a1a1a;
          }
  
          input {
            width: 16px;
            height: 16px;
            border-radius: 4px;
          }
        }
      }
    }

    .donation-admin_card_group {
      max-width: 1440px;
      width: 100%;
      display: grid;
      grid-template-columns: 1fr 1fr 1fr;
      grid-column-gap: 32px;
      margin-bottom: 3.2rem;

      @media (max-width: 768px) {
        grid-template-columns: 1fr;
        gap: 40px;
      }

      .card {
        width: 100%;
        border-radius: 12px;
        background: rgba(0, 102, 51, 0.33);
        box-shadow: 4px 0px 4px 0px rgba(0, 0, 0, 0.25),
          0px 4px 4px 0px rgba(0, 0, 0, 0.25);

        &__inner {
          border-radius: 12px;
          border: 1px solid #abcdbc;
          background: var(--White, #fff);
          padding: 24px;
          .title {
            font-size: 1.8rem;
            font-weight: 500;
            color: #667185;
            margin-bottom: 1.6rem;

            @media (max-width: 565px) {
              font-size: 1rem;
            }
          }

          .subtitle {
            font-size: 1.6rem;
            font-weight: 500;
            color: #1a1a1a;
            display: flex;
            align-items: center;
            width: fit-content;
            gap: 10px;

            & > span {
              font-size: 2.4rem;
              font-weight: 700;
              margin-bottom: 0;
            }
          }
        }

        .value_group {
          display: flex;
          align-items: center;
          justify-content: space-between;
          padding: 20px 24px;

          & > :first-child {
            color: var(--success-400-base, #0f973d);
            font-size: 1.2rem;
            font-weight: 700;
            display: flex;
            align-items: center;
            gap: 4px;
          }
          & > :last-child {
            color: #667185;
            font-size: 1.2rem;
            font-weight: 400;
          }
        }
      }
    }
  }

  .history_grid {
    display: grid;
    grid-template-columns: 2fr 1fr;
    gap: 33px;
    // background: blue;

    @media (max-width: 1024px) {
      grid-template-columns: 1fr;
      grid-row-gap: 0;


    }

    .emission_history {
      position: relative;
      width: 100%;
      height: 34rem;
      border-radius: 16px;
      border: 1px solid #E5E5E5;
      // background-color: yellow;
      margin: auto !important;
      margin: 3.2rem 0;
      padding: 22px;
      margin-bottom: 3.2rem !important;
      // background: green;
      display: flex;
      align-items: center;
      justify-content: center;

      &__title {
        position: absolute;
        top: 10px;
        left: 10px;
        color: var(--Net-Black, #1A1A1A);

font-size: 16px;
font-weight: 400;
      }

      .title {
        font-size: 1.6rem;
      }

      .emission_grid {
        display: grid;
        grid-template-columns: 120px 400px;
        justify-content: space-between;

        @media (max-width: 565px) {
          grid-template-columns: 1fr;
  
        }
  

        & > :first-child {
          margin-top: 20px;
          .subtitle {
            font-size: 1rem;
            font-weight: 600;
            color: #667185;
          }

          .title {
            font-size: 3.2rem;
            font-weight: 700;
            color: #1a1a1a;
            margin-top: 1.6rem;
            margin-bottom: 1.6rem;
          }

          .analytics {
            display: flex;
            align-items: center;
            font-size: 1.2rem;

            svg {
              margin-right: 0.6rem;
            }

            span {
              color: #0F973D;
              font-weight: 700;
            }
          }

        }

        & > :last-child {
// background-color: pink;
        }
      }

      .chart_select {
        width: 59px;
        height: 24px;
        position: absolute;
        top: 15px;
        right: 15px;
        // font-size: 5px;

       
      }

#emission {
@media (max-width: 565px) {
  padding-top: 40px;
}
}      

      .history_table {
        height: fit-content;
        width: 100%;
        // background-color: green;
        .history_table_group {
          width: 100%;
          display: grid;
          grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
          grid-column-gap: 3rem;
          border-radius: 12px;
          align-items: center;
          text-align: center;
          font-weight: 500;

          @media (max-width: 565px) {
            grid-column-gap: 1.2rem;
          }
          & > div {
            width: fit-content;
            justify-self: center;
          }
        }

        .table_body_container {
          height: 25rem;
          overflow: auto;
          // background-color: blue;

          &::-webkit-scrollbar {
            display: none;
          }
        }

        .history_table_header {
          font-size: 1.2rem;
          color: #1a1a1a;
          margin-bottom: 2.4rem;

          @media (max-width: 565px) {
            font-size: 1rem;
            word-break: break-all;
            word-wrap: break-word;
            white-space: wrap;
          }
        }
        .history_table_body {
          font-size: 1rem;
          color: #667185;
          margin-bottom: 1.8rem;
        }
      }

      .view {
        width: 100%;
        height: 5.5rem;
        border-top: 0.5px solid #667185;
        font-size: 1.4rem;
        font-weight: 700;
        color: #1a1a1a;
        display: flex;
        align-items: center;
        justify-content: end;
      }

      .menu_click {
        position: absolute;
        top: 22px;
        right: 22px;
      }
    }

    .security {
      width: 100%;
      height: 34rem;
      // background: yellow;
      border-radius: 16px;
      border: 1px solid #E5E5E5;
      margin: auto !important;
      margin: 3.2rem 0;
      margin-bottom: 3.2rem !important;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      .security_header_group {
        width: 100%;
        display: grid;
        grid-template-columns: 1fr 1fr 1fr 1fr;
        grid-column-gap: 2.4px;
        border-radius: 12px;
        align-items: center;
        text-align: center;
        font-weight: 500;
        .security_header {
          width: fit-content;
          justify-self: center;
          font-size: 1rem;
          font-weight: 400;
          color: #667185;
          display: flex;
          flex-direction: column;
          align-items: center;
          cursor: pointer;

          @media (max-width: 565px) {
            font-size: 0.8rem;
          }

          .bar {
            width: 6.7rem;
            height: 1rem;
            margin-bottom: 1.2rem;
          }
        }

        .active {
          color: #7bc216;
          font-weight: 600;
          .bar {
            background-color: #7bc216;
          }
        }
      }

      .chart_container {
        width: 90%;
        padding: 20px;
        // background-color: green;
        height: 100%;
      }
    }
  }

  
  .liquidity-table {
    width: 100%;
    border: 1px solid #efefef;
    border-radius: 12px;
    position: relative;



@media (max-width: 565px) {
  overflow-x: auto;
}

   &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 24px;
    height: 49px;
    border-bottom: 1px solid #efefef;
    background: #f9fafb;
    border-radius: 12px 12px 0px 0px;
    color: var(--Net-Black, #1A1A1A);
font-size: 1.4rem;
font-weight: 500;


@media (max-width: 565px) {
  width: 800px;
}
   }

    &__label {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr 1fr;
      color: #1a1a1a;
      align-items: center;
      font-size: 1.2rem;
      font-weight: 500;
      height: 44px;
      padding: 0 24px;
      border-bottom: 1px solid rgba(177, 177, 177, 0.4);
      background: #fafafa;
      gap: 20px;

      @media (max-width: 565px) {
        width: 800px;
      }
    }
   &__inner {
    height: 600px;
    overflow-y: auto;
    @media (max-width: 565px) {
      width: 800px;
    }
    &__item {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr 1fr;
      align-items: center;
      height: 72px;
      background: #fff;
      padding: 0 24px;
      gap: 20px;
      cursor: pointer;
      margin-bottom: 0;

      &:hover {
        border-top: 1px solid #063;
        border-bottom: 1px solid #063;
      }

      & > div {
        color: #1a1a1a;
        font-size: 1.4rem;
        font-weight: 500;
        word-wrap: break-word;
        word-break: break-all;
      }

      & > button {
        border-radius: 24px;
        border: none;
        outline: none;
        width: fit-content;
        height: 3.2rem;
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 4px;
        border: 1px solid #e7f6ec;
        padding: 0 12px;
        font-size: 1.4rem;
        font-weight: 500;
      }

      &__active {
        background: #0F973D;
        color: #FFF;
        border: 1px solid var(--Success-50, #E7F6EC);
        text-transform: capitalize;

      }
      &__pause {
        background: #d0d5dd;
        color: #e4e7ec;
      }
    }
   }

   &__footer {
    display: flex;
      align-items: center;
      justify-content: center;
      padding: 0 24px;
      height: 64px;
      border-top: 1px solid #efefef;
      background: #f9fafb;
      border-radius: 0 0 12px 12px;

      &__pagination {
        display: flex;
        align-items: center;
        justify-content: space-between;
        font-size: 1.4rem;

        @media (max-width: 565px) {
         width: 100%;
         margin-bottom: 10rem;
        }

        & > div {
          cursor: pointer;
          color: #98a2b3;
          width: 10rem;
          height: 4.4rem;
          display: flex;
          align-items: center;
          justify-content: center;
          border-radius: 7px;
          transition: 0.2s;

          &:hover {
            background-color: #2a5bd7;
            color: #fff;
            transition: 0.2s;
          }
        }
      }
   }

    .ndclist-modal {
      width: 204px;
      height: 276px;
      background: #e7f6ec;
      position: absolute;
      top: 52px;
      right: 0;
      border-radius: 4px 0px 0px 4px;
      border: 1px solid #efefef;
      z-index: 2;

      &__search {
        width: 100%;
        height: 32px;
        background: #fff;
        display: grid;
        grid-template-columns: 16px 1fr;
        align-items: center;
        gap: 8px;
        color: #898989;
        font-size: 1.2rem;
        font-weight: 400;
        padding: 0 8px;
        margin-top: 12px;

        svg {
          width: 100%;
          height: 16px;
        }

        input {
          border: none;
          outline: none;
          width: 100%;
        }
      }

      &__list {
        height: 232px;
        overflow-y: auto;
        padding-left: 30px;

        &::-webkit-scrollbar {
          display: none;
        }

        &__flex {
          display: flex;
          height: 3.1rem;
          color: #1a1a1a;
          align-items: center;
          margin-top: 2rem;
          font-size: 1.2rem;
          font-weight: 400;
          gap: 12px;
          margin-bottom: 1.2rem;
          padding: 0 8px;
          border-radius: 4px 0px 0px 4px;
          cursor: pointer;

          &:hover {
            background: #fff;
            color: #1a1a1a;
          }

          input {
            width: 16px;
            height: 16px;
            border-radius: 4px;
          }
        }
      }
    }

    .adminndclist-modal {
      right: 100px;
    }

    .calendar-modal {
      min-width: 28.7rem;
      min-height: 28.7rem;
      background: #efefef;
      position: absolute;
      top: 52px;
      right: 0;
      border-radius: 4px 0px 0px 4px;
      border: 1px solid #efefef;
      background: #fff;
      padding: 24px 12px;

      .ndc-calendar-row {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
        // gap: 24px;
        align-items: center;
        justify-content: center;

        & > div {
          // background: green;
          width: fit-content;
          display: flex;
          align-items: center;
          justify-content: center;
          align-self: center;
          justify-self: center;
          margin-bottom: 16px;
          font-size: 12px;
          font-weight: 500;
          width: 100%;
          height: 20px;
          color: #1a1a1a;
        }
        .ndc-calendar-date {
          cursor: pointer;
        }
        .ndc-calendar-date:hover {
          background: #00aed9;
          color: #fff;
          border-radius: 50%;
          width: 20px;
        }

        .active-date {
          background: #00aed9;
          color: #fff;
          border-radius: 50%;
          width: 20px;
        }

        .subsidiary-date {
          background: #e3effc;
        }
      }

      .calendar-header {
        & > div {
          color: #1a1a1a;
        }
      }

      &__flex {
        display: flex;
        align-items: center;
        justify-content: space-between;
        color: #1a1a1a;
        font-size: 12px;
        font-weight: 500;
        margin-bottom: 1.6rem;

        & > div {
          display: flex;
          align-items: center;
          gap: 16px;
        }
      }

      &__button-group {
        display: flex;
        align-items: center;
        gap: 8px;
        justify-content: end;
        button {
          width: 7.2rem;
          height: 3.2rem;
          outline: none;
          border: none;
          border-radius: 6px;
          background: linear-gradient(116.29deg, #006633 0.76%, #02a4cc 100%);
          font-size: 10px;
          font-weight: 600;
          color: #fff;
          display: flex;
          align-items: center;
          justify-content: center;
        }

        & > :first-child {
          border: 1px solid #667185;
          color: #1a1a1a;
          background: transparent;
        }
      }
    }

    .ndclist-filter-modal {
      width: 108px;
      height: 112px;
      background: #e7f6ec;
      position: absolute;
      top: 52px;
      right: 0;
      border-radius: 4px 0px 0px 4px;
      border: 1px solid #efefef;
      z-index: 2;

      &__flex {
        display: flex;
        height: 3.1rem;
        color: #1a1a1a;
        align-items: center;
        margin-top: 2rem;
        font-size: 1.2rem;
        font-weight: 400;
        gap: 12px;
        margin-bottom: 1.2rem;
        padding: 0 8px;
        border-radius: 4px 0px 0px 4px;
        cursor: pointer;

        &:hover {
          background: #fff;
          color: #1a1a1a;
        }

        input {
          width: 16px;
          height: 16px;
          border-radius: 4px;
        }
      }
    }
  }
}
