.website_sidebar{
    width: 24.4rem;
    height: 100vh;
    background-color: #FAFAFA;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding-top: 6.1rem;
    padding-bottom: 2rem;
    font-size: 16px;

    @media (max-width: 1024px) {
        justify-content: space-around;
      }
    @media (max-width: 565px) {
        justify-content: space-around;
        padding-left: 3.2rem;

      }
        
    
    .logo_container {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        // background-color: yellow;

        
        @media (max-width: 1024px) {
            display: none;
        }
        .logo {
            width: 8.3rem;
            height: 8.077rem;
            margin: auto !important;
            margin-left: -1.6rem !important;
            // background-color: gray;
        }
    }

    .logged-user {
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        &__img {
            width: 6rem;
            height: 6rem;
            border-radius: 50%;
            margin-bottom: 0.8rem;
            border: 2px solid #063;
        }

        &__title {
            font-size: 1.6rem;
            font-weight: 500;
            margin-bottom: 0.4rem;
        }
        &__subtitle {
            font-size: 1rem;
            font-weight: 500;
        }
    }

    &__ipad-pro {
        display: none;
    
        @media (max-width: 1024px) {
            display: initial;
        }
    }


    
    .menu {
        
    padding-left: 3.2rem;

        // background-color: #f0f0f0;
        @media (max-width: 565px) {
            padding-left: 0;

        }
        .menu_item {
            display: flex;
            align-items: center;
            margin-bottom: 4.3rem;
            cursor: pointer;
            position: relative;

            @media (max-width: 768px) {
                margin-bottom: 4.8rem;
                font-size: 1.2rem;
            }
           
            
           
            & > div {
                 color: #98A2B3;
            }

            svg {
                margin-right: 1.2rem;

                @media (max-width: 768px) {
                    width: 16px;
                }
            }

            &__dropdown {
                width: 100%;
                height: 100px;
                background: #fff;
                position: absolute;
                top: 30px;
                right: 0;
                padding: 16px 24px;
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                border-radius: 8px;
                box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25),
          4px 0px 4px 0px rgba(0, 0, 0, 0.25);
            }
        }

        .menu_active {
            background-color: #063;
            padding: 16px;
            border-radius: 8px 0 0 8px;
            
            & > div {
                color: #fff;
            }
            svg {
                display: unset;
                path {
                    fill: white !important;
                }
            }
        }
        .active_iot {
            background-color: #063;
            padding: 16px;
            border-radius: 8px 0 0 8px;
            
            & > div {
                color: #fff;
            }
            svg {
                display: unset;
                path {
                    fill: none !important;
                    stroke: white !important;
                }
            }
        }
    }

    .logout {
        display: flex;
        align-items: center;
        padding-left: 3.2rem;
        @media (max-width: 565px) {
            padding-left: 0;

        }
       
        & > div {
             color: #98A2B3;
        }

        svg {
            margin-right: 1.2rem;
        }
    }
}