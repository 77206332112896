.userdetails {
  display: flex;

  .sidebar_container {
    // background-color: yellow;
    //  height: 100%;
    max-height: 700px;
    position: relative;

    @media (max-width: 1024px) {
      display: none;
    }
  }

  .main {
    padding: 24px 0 24px 24px;
    height: 100vh;
    overflow: auto;
    width: 100%;

    @media (max-width: 565px) {
      padding: 24px;
    }

    
    .main_inner {
      max-width: 1800px;
      width: 100%;
      margin: auto;
        padding: 44px;
        overflow: auto;
        border-radius: 24px;
        background: rgba(133, 215, 114, 0.4);

        @media (max-width: 1024px) {
          overflow: unset;
        }
       
        
        &::-webkit-scrollbar {
            display: none;
          }
    
        .input_container {
          // width: fit-content;
          // min-width: 79.3rem;
          display: grid;
          grid-template-columns: 235px 800px;
          grid-column-gap: 100px;
          align-items: center;
          font-size: 1.6rem;
          margin-bottom: 2.4rem;
          color: #667185;
          padding-left: 35px;
          // background-color: yellow;

          @media (max-width: 1024px) {
            grid-template-columns: 235px auto;
            grid-column-gap: 17px;
          }
          @media (max-width: 768px) {
            grid-template-columns: 120px auto;
            padding-left: unset;
          }
          @media (max-width: 565px) {
            font-size: 10px;
            grid-template-columns: 73px auto;

          }

          & > :first-child {
            white-space: nowrap;

            @media (max-width: 1024px) {
              white-space: unset !important;
            }
          }
          input {
            width: 52.8rem;
            height: 5.9rem;
            border: 1px solid #667185;
            border-radius: 12px;
            padding-left: 24px;
            background: transparent;

            @media (max-width: 1024px) {
              width: 100%;
            }
          }
          textarea {
            width: 52.8rem;
            height: 11.4rem;
            border: 1px solid #667185;
            border-radius: 12px;
            padding: 24px;

            @media (max-width: 1024px) {
              width: 100%;
            }
          }
    
          .document {
            display: flex;
            align-items: center;
            justify-content: space-between;
            width: 52.8rem;
            height: 5.9rem;
            border: 1px solid #667185;
            border-radius: 12px;
            padding: 0 24px;
            cursor: pointer;

            @media (max-width: 1024px) {
              width: 100%;
            }
            @media (max-width: 768px) {
              font-size: 10px;
            }
          }
    
          .input_group {
            width: 52.8rem;
            display: grid;
            grid-template-columns: 1fr 2.4fr;
            grid-column-gap: 12px;

            @media (max-width: 1024px) {
              width: 100%;
            }
    
            input {
              width: 100% !important;
              
            }
          }
          .input_flex {
            display: flex;
            align-items: center;
    
            .input,
            input {
            }
    
            .button_group {
              display: flex;
              align-items: center;
              margin-left: 24px !important;

              @media (max-width: 768px) {
                margin-left: 8px !important;
              }

    
              .transparent {
                background: transparent;
                border: 0.5px solid #063;
                border-radius: 8px;
                color: rgba(2, 164, 204, 0.4);
                min-width: 11.8rem;
                padding: 0 16px;
                height: 4rem;
                outline: none;
                margin-right: 1.2rem;
                white-space: nowrap;
                
                @media (max-width: 768px) {
                  font-size: 0.8rem;
                  border: none;
                  padding: 6px;
                  margin-right: 0;
                  min-width: unset;
                  width: fit-content;
                }


              }
    
              .filled {
                border-radius: 8px;
                background: linear-gradient(116deg, #063 0.76%, #02a4cc 100%);
                height: 4rem;
                min-width: 11.8rem;
                padding: 0 16px;
                outline: none;
                border: none;
                color: #fff;
                white-space: nowrap;

                @media (max-width: 768px) {
                  font-size: 8px;
                  padding: 0 8px;
                  height: 30px;
                  min-width: unset;
                  width: fit-content;
                }
              }
            }
          }
          .sensor_group {
            width: 52.8rem;
            display: flex;
            align-items: center;
            overflow: auto;
    
            &::-webkit-scrollbar {
              display: none;
            }
    
            .input {
              width: fit-content;
              margin-right: 1.2rem;
              border: 1px solid #667185;
              border-radius: 12px;
              background: transparent;
              padding: 20px 24px;
              display: flex;
              align-items: center;
              justify-content: center;
              white-space: nowrap;

            }
          }
        }
      }
  }
}
