.privacy {
    text-align: justify;
    .hero {
            height: 37rem;
            width: 100%;
            max-width: 1440px;
            margin: auto;
            display: grid;
            grid-template-columns: 1fr 1fr;
            gap: 119px;
            align-items: center;
            background-color: #fafafa;

            div {
                width: 100%;
                // height: 37rem;
                img {
                    width: 100%;
                    height: auto;
                }
            }

            &>:first-child {
                // background-color: yellow;
                display: flex;
                flex-direction: column;
                justify-content: center !important;
                align-items: center !important;
                padding: 0 0 0 144px;

                .title {
                    margin-bottom: 2.4rem;
                    font-size: 6.4rem;
                    font-weight: 700;
                    color: #063;
                }
                .subtitle {
                    font-size: 2.4rem;
                    font-weight: 400;
                    // background: blue;
                }
            }


    }

    .content {
        width: 100%;
        max-width: 1440px;
        padding: 0 144px;
        margin: 6.4rem auto 21.2rem auto;
        .contentTitle {
            font-size: 3.2rem;
            font-weight: 500;
            margin-bottom: 1.6rem;
        }
        .contentSubtitle {
font-size: 2.4rem;
font-weight: 400;
margin-bottom: 1.6rem;
        }
        .contentParagraph {
font-size: 1.6rem;
font-weight: 400;
margin-bottom: 2.4rem;
line-height: 150%;
& > ul {
    margin-top: 0;
}

&__bold {
    font-weight: 600;
}

& > a {
    color: #063;
}
        }
        .subtitleBold {
            font-weight: 500;
        }

        .contentFaq {
            margin-bottom: 2.4rem;
            .faqMainTitle {
                font-size: 3.2rem;
            font-weight: 500;
            // margin-bottom: 1.6rem;
            }
            .faqCard{
                margin-top: 2.4rem;
                border-bottom: 1px solid #b1b1b1;
                padding-bottom: 16px;

                .faqTitle {
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    font-size: 1.6rem;
                    font-weight: 500;
                    margin-bottom: 1.85rem;
                }

                .faqSubtitle {
                    font-size: 1.4rem;
                    font-weight: 400;
                   line-height: 150%;

                }
            }
        }
    }
}

@media (max-width: 1200px) {
    .privacy {
        .hero {
            grid-template-columns: 1fr;
            height: unset;
            gap: 12px;
& > div {
    padding: 0 16px;
}
            & > :first-child {
                text-align: center;
                padding: 0 16px;
                margin-top: 3.2rem;
               .title {
                font-size: 2.4rem;
                font-weight: 400;
               }
               .subtitle {
                font-size: 1.6rem;
               }
            }
        }

        .content {
            padding: 0 16px;
            margin: 3.2rem auto 21.2rem auto;

            .contentTitle {
                font-size: 2.4rem;
                margin-bottom: 1.2rem;
                line-height: normal;
            }
            .contentSubtitle {
                font-size: 1.6rem;
                margin-bottom: 1.2rem;
            }
            .contentParagraph {
                font-size: 1.2rem;
                margin-bottom: 1.6rem;
            }
        }
    }
}