.evaluation {
  display: flex;

  .sidebar_container {
    // background-color: yellow;
    //  height: 100%;
    max-height: 700px;
    position: relative;

    @media (max-width: 1024px) {
      display: none;
    }
  }

  &__main {
    max-width: 1440px;
    width: 100%;
    margin: auto;
    padding: 44px;
    height: 100vh;
    overflow: auto;

    @media (max-width: 565px) {
      padding: 24px;
    }

    &__title {
        font-size: 2.4rem;
        font-weight: 700;
        color: #1a1a1a;
        margin-bottom: 24px;
  
        @media (max-width: 565px) {
          font-size: 1.4rem;
          margin-bottom: 0.4rem;
        }
      }
      
      &__subtitle {
        font-size: 1.4rem;
        font-weight: 400;
        color: #667185;
        margin-bottom: 64px;
      }

      &__cards {
        &__title {
            font-size: 1.6rem;
            font-weight: 500;
            color: #1A1A1A;
            margin-bottom: 16px;

            @media (max-width: 565px) {
              font-size: 1.4rem;
            }
        }

        &__card {
width: 100%;
height: 92px;
border-radius: 16px;
background: #FAFAFA;
display: flex;
align-items: center;
justify-content: space-between;
padding: 0 24px;

@media (max-width: 565px) {
  padding: 0 10px;
}

& >:first-child {
    display: flex;
    align-items: center;
    gap: 8px;
    font-size: 1.4rem;
    color: #1A1A1A;

    @media (max-width: 565px) {
      font-size: 1rem;
    }
}

& > button {
    width: 135px;
    height: 52px;
    border-radius: 8px;
background: var(--Gradient, linear-gradient(116deg, #063 0.76%, #02A4CC 100%));
border: none;
outline: none;
font-size: 1.6rem;
color: #FFF;
font-weight: 600;

@media (max-width: 565px) {
  font-size: 1.2rem;
  height: 32px;
  width: fit-content;
  white-space: nowrap;
}
}
        }

        &__card1 {
            background: #FAFAFA;
            margin-bottom: 32px;
        }
        &__card2 {
            background: rgba(133, 215, 114, 0.40);

        }
      } 
}

&__test {
    position: fixed;
    top: 0;
    right: 0;
    width: 701px;
    height: 100vh;
    border: 1px solid var(--Grey-500, #667185);
background: var(--colors-common-white, #FFF);
padding: 84px 42px 0 42px;
overflow-y: auto;

@media (max-width: 768px) {
  width: 100%;
  padding: 20px;
}

&__title {
    font-size: 2.4rem;
    font-weight: 500;
    color: #063;
    margin-bottom: 12px;
}
&__subtitle {
    font-size: 1.4rem;
    font-weight: 400;
    color: #667185;
    margin-bottom: 32px;
}

&__card {
    margin-bottom: 24px;
    &__question {
        font-size: 1.4rem;
        font-weight: 700;
        color: #1A1A1A;
        margin-bottom: 16px;
    }

    &__options {
        display: flex;
        flex-direction: column;
        gap: 16px;

        &__option {
            display: flex;
            align-items: center;
            gap: 16px;
            font-size: 1.4rem;
            font-weight: 400;
            color: #1A1A1A;

            & > span {
                font-weight: 700;
            }

            & > svg {
                cursor: pointer;
            }

            
        }
    }
}

&__button {
  width: 100%;
  max-width: 512px;
  height: 82px;
  border-radius: 8px;
background: var(--Gradient, linear-gradient(116deg, #063 0.76%, #02A4CC 100%));
outline: none;
border: none;
font-size: 1.6rem;
color: #FFF;
font-weight: 600;
margin: auto;
margin-top: 48px;
display: flex;
align-items: center;
justify-content: center;
}
}

&__done {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background: rgba(255, 255, 255, 0.40);
  display: flex;
  align-items: center;
  justify-content: center;
  &__inner {
    border-radius: 24px;
border: 1px solid var(--Grey-500, #667185);
background: var(--colors-common-white, #FFF);
width: 826px;
height: 600px;
display: flex;
flex-direction: column;
align-items: center;
justify-content: center;

& > svg {
  margin-bottom: 48px;
}

&__title {
  font-size: 3.2rem;
  font-weight: 700;
  color: #1A1A1A;
  margin-bottom: 12px;
}

&__subtitle {
  font-size: 1.2rem;
  color: #667185;
  margin-bottom: 48px;
}

&__button {
  width: 100%;
  max-width: 562px;
  height: 97px;
  border-radius: 12px;
border: 1px solid var(--Gradient, #063);
background: #FFF;

& > div {
  background: var(--Gradient, linear-gradient(116deg, #063 0.76%, #02A4CC 100%));
background-clip: text;
-webkit-background-clip: text;
-webkit-text-fill-color: transparent;
font-size: 1.6rem;
font-weight: 600;
}
}
  }
}
}
