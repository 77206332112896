
.contact_us_modal {
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    // background: rgb(0, 0, 0);
    z-index: 3;
    .contact_us {
      //   display: flex;
      //   flex-direction: column;
      //   align-items: center;
      //   justify-content: center;
      width: 72rem;
      height: 100%;
      overflow: auto;
      position: fixed;
      padding: 24px;
      box-shadow: 0px 20px 40px 0px rgba(0, 0, 0, 0.1);
      &::-webkit-scrollbar {
        display: none;
      }
  
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      background-color: #fff;
  
      .close {
        width: 100%;
        display: flex;
        justify-content: end;
        margin-bottom: 1.6rem;
      }
      .illustraton {
        width: 100%;
        display: flex;
        justify-content: center;
        margin-bottom: 2.4rem;
      }
      .title {
        font-size: 3.2rem;
        font-weight: 400;
        color: #141414;
        margin-bottom: 1.2rem;
        text-align: center;
  
        span {
          font-weight: 700;
          color: #0d924f;
          text-decoration: underline;
        }
      }
  
      .subtitle {
        font-weight: 400;
        font-size: 1.6rem;
        color: #3b3b3b;
        text-align: center;
        margin-bottom: 3.267rem;
      }
      .contact_us_group {
        // overflow: auto;
        display: flex;
        height: 100%;
        width: 100%;
  
        & > :first-child {
          width: 29.2rem;
          height: 42.9rem;
          margin-right: 5.7rem;
          color: #fff;
          padding: 36px 10px 10px 18px;
          background: url("../../images/Frame\ 23.png");
          background-size: cover;
          background-position: center;
          background-repeat: no-repeat;
          border-radius: 12px;
  
          .title {
            color: #fff;
            font-size: 1.6rem;
            font-weight: 500;
            margin-bottom: 0.4rem;
            text-align: start;
          }
          .subtitle {
            font-size: 1.2rem;
            font-weight: 400;
            color: #fff;
            text-align: start;
            margin-bottom: 2.4rem;
          }
  
          .contact_us_grid {
            display: grid;
            grid-template-columns: 24px auto;
            grid-column-gap: 12px;
            grid-row-gap: 18px;
            align-items: center;
            width: 100%;
          }
        }
        & > :last-child {
          .contact_us_form {
            width: 100%;
            max-width: 68.2rem;
            // padding: ;
            margin: auto;
            display: flex;
            // margin-top: 6.4rem;
            // margin-bottom: 21.6rem;
            flex-direction: column;
            align-items: center;
  
            .contact_us_input_group {
              width: 100%;
  
              .contact_us_form_label {
                font-size: 1.2rem;
                font-weight: 500;
                color: #141414;
                white-space: nowrap;
  
                span {
                  color: rgba(243, 29, 29, 1);
                  font-size: 1.6rem;
                }
              }
              .contact_us_input_group_inner {
                display: flex;
                align-items: center;
                width: 100%;
                height: 5.6rem;
                border: 0.5px solid #898989;
                border-radius: 12px;
                padding: 12px;
                margin-bottom: 1.8rem;
                margin-top: 1.2rem;
  
                .contact_us_input {
                  width: 100%;
                  height: 100%;
                  border: none;
                  outline: none;
  
                  &::placeholder {
                    font-size: 0.8rem;
                    position: absolute;
                    top: 50%;
                    transform: translateY(-50%);
                  }
                  //   background-color: yellow;
                }
  
                .contact_us_divider {
                  width: 0.05rem;
                  height: 1.4rem;
                  background-color: #063;
                  margin: 0 0.8rem;
                }
              }
  
              .input_textarea {
                height: 13.5rem;
                align-items: start;
                textarea {
                  width: 100%;
                  height: 100%;
                  border: none;
                  outline: none;
                  // background-color: yellow;
                  padding: 0;
  
                  &::placeholder {
                    font-size: 0.8rem;
                    position: absolute;
                    top: 3px;
                  }
                }
              }
            }
  
            .contact_us_button {
              width: 32.4rem;
              height: 6.7rem !important;
              background-color: #063;
              font-size: 1.6rem;
              font-weight: 500;
              border: none;
              outline: none;
              border-radius: 12px;
              color: #fff;
              margin-top: 0.6rem;
  
              transition: 0.4s;
  
              &:hover {
                background-color: #0d924f;
              }
            }
          }
        }
      }
    }
  }

  @media (max-width: 600px) {
    .contact_us_modal {
      .contact_us {
        width: 100%;
        padding: 24px 16px;
  
        .firstContactTitle, .firstContactSubTitle {
          display: none;
        }
  
        .contact_us_group {
          flex-direction: column;
  
        
          & > :first-child {
            padding: 16px 10px 10px 18px;
            background: url('../../images/Frame\ 1352.png');
            background-size: cover;
          background-position: center;
          background-repeat: no-repeat;


          width: 100%;
          margin-bottom: 2.4rem;

          .subtitle {
            margin-bottom: 1.2rem;
          }
          }
        }
      }
    }
  }