.admin_history {
  display: flex;

  .sidebar_container {
    // background-color: yellow;
    //  height: 100%;
    max-height: 700px;
    position: relative;

    @media (max-width: 1024px) {
      display: none;
    }
  }

  .main {
    max-width: 1800px;
      width: 100%;
      margin: auto;
    padding: 44px;
    height: 100vh;
    overflow: auto;

    @media (max-width: 1024px) {
      overflow: unset !important;
    }

    @media (max-width: 565px) {
      padding: 24px;
    }


    .history_grid {
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-column-gap: 32px;
      grid-row-gap: 32px;
      // background: blue;

      @media (max-width: 1024px) {
        grid-template-columns: 1fr;
        grid-row-gap: 0;


      }

      .emission_history {
        position: relative;
        width: 100%;
        height: 34rem;
        border-radius: 16px;
        border: 1px solid #E5E5E5;
        // background-color: yellow;
        margin: auto !important;
        margin: 3.2rem 0;
        padding: 22px;
        margin-bottom: 3.2rem !important;
        // background: green;

        .title {
          font-size: 1.6rem;
        }

        .emission_grid {
          display: grid;
          grid-template-columns: 120px 400px;
          justify-content: space-between;

          @media (max-width: 565px) {
            grid-template-columns: 1fr;
    
          }
    

          & > :first-child {
            margin-top: 20px;
            .subtitle {
              font-size: 1rem;
              font-weight: 600;
              color: #667185;
            }

            .title {
              font-size: 3.2rem;
              font-weight: 700;
              color: #1a1a1a;
              margin-top: 1.6rem;
              margin-bottom: 1.6rem;
            }

            .analytics {
              display: flex;
              align-items: center;
              font-size: 1.2rem;

              svg {
                margin-right: 0.6rem;
              }

              span {
                color: #0F973D;
                font-weight: 700;
              }
            }

          }

          & > :last-child {
// background-color: pink;
          }
        }

        .chart_select {
          width: 110px;
          height: 30px;
          position: absolute;
          top: 15px;
          right: 15px;

         
        }

#emission {
  @media (max-width: 565px) {
    padding-top: 40px;
  }
}      

        .history_table {
          height: fit-content;
          width: 100%;
          // background-color: green;
          .history_table_group {
            width: 100%;
            display: grid;
            grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
            grid-column-gap: 3rem;
            border-radius: 12px;
            align-items: center;
            text-align: center;
            font-weight: 500;

            @media (max-width: 565px) {
              grid-column-gap: 1.2rem;
            }
            & > div {
              width: fit-content;
              justify-self: center;
            }
          }

          .table_body_container {
            height: 25rem;
            overflow: auto;
            // background-color: blue;

            &::-webkit-scrollbar {
              display: none;
            }
          }

          .history_table_header {
            font-size: 1.2rem;
            color: #1a1a1a;
            margin-bottom: 2.4rem;

            @media (max-width: 565px) {
              font-size: 1rem;
              word-break: break-all;
              word-wrap: break-word;
              white-space: wrap;
            }
          }
          .history_table_body {
            font-size: 1rem;
            color: #667185;
            margin-bottom: 1.8rem;
          }
        }

        .view {
          width: 100%;
          height: 5.5rem;
          border-top: 0.5px solid #667185;
          font-size: 1.4rem;
          font-weight: 700;
          color: #1a1a1a;
          display: flex;
          align-items: center;
          justify-content: end;
        }

        .menu_click {
          position: absolute;
          top: 22px;
          right: 22px;
        }
      }

      .security {
        width: 100%;
        height: 34rem;
        // background: yellow;
        border-radius: 16px;
        border: 1px solid #E5E5E5;
        margin: auto !important;
        margin: 3.2rem 0;
        margin-bottom: 3.2rem !important;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        .security_header_group {
          width: 100%;
          display: grid;
          grid-template-columns: 1fr 1fr 1fr 1fr;
          grid-column-gap: 2.4px;
          border-radius: 12px;
          align-items: center;
          text-align: center;
          font-weight: 500;
          .security_header {
            width: fit-content;
            justify-self: center;
            font-size: 1rem;
            font-weight: 400;
            color: #667185;
            display: flex;
            flex-direction: column;
            align-items: center;
            cursor: pointer;

            @media (max-width: 565px) {
              font-size: 0.8rem;
            }

            .bar {
              width: 6.7rem;
              height: 1rem;
              margin-bottom: 1.2rem;
            }
          }

          .active {
            color: #7bc216;
            font-weight: 600;
            .bar {
              background-color: #7bc216;
            }
          }
        }

        .chart_container {
          width: 90%;
          padding: 20px;
          // background-color: green;
          height: 100%;
        }
      }
    }

    .carbon-credit-table {
      width: 100%;
      height: 1200px;
      border: 1px solid #efefef;
      border-radius: 12px;
      position: relative;

      
    @media (max-width: 565px) {
      overflow-x: auto;
    }

      &__header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0 24px;
        height: 49px;
        border-bottom: 1px solid #efefef;
        background: #f9fafb;
        border-radius: 12px 12px 0px 0px;
        color: var(--Net-Black, #1A1A1A);
font-size: 1.4rem;
font-weight: 500;

@media (max-width: 565px) {
  width: 800px;
}
      }

      &__label {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
        color: #1a1a1a;
        align-items: center;
        font-size: 1.2rem;
        font-weight: 500;
        height: 44px;
        padding: 0 24px;
        border-bottom: 1px solid rgba(177, 177, 177, 0.4);
        background: #fafafa;
        gap: 20px;

        @media (max-width: 565px) {
          width: 800px;
        }
      }
      &__item {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
        align-items: center;
        height: 72px;
        background: #fff;
        padding: 0 24px;
        gap: 20px;
        cursor: pointer;

        @media (max-width: 565px) {
          width: 800px;
        }

        &:hover {
          border-top: 1px solid #063;
          border-bottom: 1px solid #063;
        }

        & > div {
          color: #1a1a1a;
          font-size: 1.4rem;
          font-weight: 500;
          word-wrap: break-word;
          word-break: break-all;
        }

       & >  button {
        border: none;
        outline: none;
        width: 15.4rem;
        height: 4.1rem;
          border-radius: 8px;
background: var(--Gradient, linear-gradient(116deg, #063 0.76%, #02A4CC 100%));
color: #FFF;
font-size: 1.4rem;
font-weight: 600;
        }
      }
    }
  }
}
