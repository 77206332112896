.iot_container {
    display: flex;

    .sidebar_container {
        @media (max-width: 1024px) {
          display: none;
        }
      }
    .iot_main{
        position: relative;
        width: 1440px;
        max-width: 100%;
        padding: 44px;
        height: 100vh;
        overflow: auto;
        margin: auto;

        @media (max-width: 565px) {
            padding: 24px;
          }
    
        .iot_back {
            margin-bottom: 2.7rem;
        }
    
        .iot_grid {
            display: grid;
            grid-template-columns: auto auto;
            // width: 48.9rem;
            // max-width: 60rem;
            width: fit-content;
            // background-color: yellow;
            grid-column-gap: 16px;
            grid-row-gap: 32px;
            font-size: 1.6rem;

            @media (max-width: 565px) {
                font-size: 1rem;
              }
    
            & > div {
                width: fit-content;
                display: flex;
                align-items: center;
    
                svg {
                    margin-right: 1.6rem;
                }
            }
            
        }
    
        .iot_table {
            width: 100%;
            max-width: 111.2rem;
            height: 49.8rem;
            // background: rgba(133, 215, 114, 0.4);
            margin-top: 3.2rem;
            border-radius: 12px;
            // padding: 24px;
            overflow: auto;
            border: 1px solid #E5E5E5;
            @media (max-width: 768px) {
                padding: 16px;
              }
    
    // &::-webkit-scrollbar {
    //     display: none;
    // }
           
            
            .table_header_container {
                width: 100%;
                display: grid;
                grid-template-columns: 1fr 1fr 1fr 1fr;
                margin: auto;
                background-color: #2C9963;
                height: 52px;
                align-items: center;
                font-size: 1.4rem;
                color: #FFF;
                border-radius: 12px 12px 0 0;
                padding: 0 80px;
                margin-bottom: 2.4rem;

                @media (max-width: 768px) {
                    padding: 0 16px;
                  }

                @media (max-width: 565px) {
                    font-size: 0.8rem;
                  }

                .table_header {
                  width: fit-content;
                  justify-self: center;
                }
    
               
              }
            .table_body_container {
                width: 100%;
                display: grid;
                grid-template-columns: 1fr 1fr 1fr 1fr;
                margin: auto;
                height: 52px;
                align-items: center;
                font-size: 1.4rem;
                color: #101828;
                padding: 0 80px;
                margin-bottom: 2.4rem;

                @media (max-width: 768px) {
                    padding: 0 16px;
                  }

                @media (max-width: 565px) {
                    font-size: 0.8rem;
                  }

                .table_header {
                  width: fit-content;
                  justify-self: center;
                }
    
               
              }


        }
    }
}