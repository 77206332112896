.signup {
  background: url("../../images/pexels-marcin-jozwiak-3641377 1.png");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  border: none;
  min-height: 100vh;
  display: flex;
  align-items: center;

  .signup_inner {
    display: grid;
    grid-template-columns: 1fr 1fr;
    align-items: center;
    padding: 123px 100px;
    grid-column-gap: 100px;
    width: 1446px;
    max-width: 100%;
    margin: auto;

    @media (max-width: 768px) {
      grid-template-columns: 1fr;
    }

    @media (max-width: 565px) {
      padding: 123px 16px !important;
    }

    .title {
      font-size: 4.8rem;
      color: #ffffff;
      margin-bottom: 1.6rem;
      font-weight: 700;

      @media (max-width: 565px) {
        font-size: 2.4rem;
      }
    }

    .dots {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr 1fr;
      align-items: center;
      grid-column-gap: 12px;
      width: fit-content;

      @media (max-width: 768px) {
        margin-bottom: 2.4rem;
      }

      

      .dot {
        width: 32px;
        height: 32px;
        border-radius: 50%;
        background-color: #e4e7ec;
        cursor: pointer;
        transition: 1s;

        @media (max-width: 565px) {
          width: 16px;
        height: 16px;
        }
      }

      .dot_active {
        transition: 1s;
        background: linear-gradient(116deg, #006633 0.76%, #00aed9 100%);
      }
    }

    .box {
      width: 100%;
      background-color: #fff;
      border-radius: 24px;
      padding: 65px 32px;
      min-height: 500px;
      position: relative;

      @media (max-width: 565px) {
        padding: 46px 32px;

      }

      .signup_link {
        font-size: 1.2rem;
        color: #006633;
        text-align: right;
        margin-bottom: 3.6rem;

        span {
          font-weight: 700;
        }
      }

      .box_title {
        font-size: 3.2rem;
        font-weight: 700;
        color: #141414;
        // margin-bottom: 1.2rem;

        @media (max-width: 565px) {
          font-size: 2.4rem;
        }
      }
      .box_subtitle {
        font-size: 1.2rem;
        color: #667185;
        margin-bottom: 6px;

        @media (max-width: 565px) {
          font-size: 1rem;
        }
      }

      .box_title_verify {
        text-align: center;
      }
      .box_subtitle_verify {
        text-align: center;
        margin-top: 12px;
      }

      .otp_box_group {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
        // background-color: yellow;
        grid-column-gap: 12px;
        margin: auto;
        margin-top: 68px;
        margin-bottom: 60px;
        width: fit-content;

        @media (max-width: 565px) {
        margin-top: 3.2rem;
        margin-bottom: 2rem;
        }

        .otp_box {
          width: 32px;
          height: 32px;

          // @media (max-width: 565px) {
          //   width: 16px;
          // height: 16px;
          // }

          // background-color: yellow;

          input {
            width: 32px !important;
            height: 32px !important;
            height: auto;
            // background-color: black;
            outline: none;
            border: none;
            border: 1px solid;
            border-color: linear-gradient(
              116.29deg,
              #006633 0.76%,
              #00aed9 100%
            );
            border-radius: 6px;
            font-size: 32px;
            text-align: center;
            color: #006633;

            // @media (max-width: 565px) {
            //   width: 16px !important;
            // height: 16px !important;
            // font-size: 12px;

            // }
          }
        }
      }

      
      .otp_error_text {
        font-size: 1.2rem;
        font-weight: 700;
        color: #d42620;
        margin-top: -4.4rem;
        // margin-bottom: 2.4rem;
        text-align: center;
        // width: fit-content;
        // background-color: yellow;
      }

      .steps {
        display: flex;
        align-items: center;
        width: fit-content;
        margin-bottom: 24px;
        .circle {
          width: 24px;
          height: 24px;
          border: 1px solid #006633;
          border-radius: 50%;
          margin: 0 !important;
          display: flex;
          align-items: center;
          justify-content: center;

          @media (max-width: 565px) {
            width: 16px;
          height: 16px;
          }
          svg {
            opacity: 0;
          }
        }

        .circle_active {
          background: linear-gradient(116deg, #006633 0.76%, #00aed9 100%);
          svg {
            opacity: 1;
            transition: 1.7s;
          }
        }
        .line {
          width: 35px;
          height: 1px;
          background-color: #006633;
          margin: 0 !important;

          @media (max-width: 565px) {
            height: 0.5px;
            width: 33px;
          }
        }
      }
      form {

        .signup_input_group {
          display: grid;
          grid-template-columns: 1fr 4fr;
          grid-column-gap: 1.2rem;
          align-items: center;

          .signup_input_mini {
            color: #667185;
          border: none;
          border-radius: 12px;
          height: 5.5rem;
          width: 100%;
          outline: none !important;
          font-size: 1.2rem;
          margin-bottom: 2.4rem;
          }
        }

        .signup_label {
          font-size: 1.6rem;
          font-weight: 600;
          color: #282828;
          margin-bottom: 1.2rem;

          @media (max-width: 565px) {
            font-size: 1.2rem;
          }
        }
        .signup_input {
          color: #667185;
          border: none;
          border-radius: 12px;
          height: 5.5rem;
          width: 100%;
          outline: none !important;
          font-size: 1.2rem;
          margin-bottom: 2.4rem;
        }

        .passowrd_input {
          margin-bottom: 1.2rem;
        }

        .instructions_title {
          font-size: 1.2rem;
          font-weight: 700;
          margin-bottom: 0.8rem;
        }

        .instructions {
          font-size: 1rem;
          margin-bottom: 0.2rem;
          display: flex;
          align-items: center;
          color: #1A1A1A;
          
          svg {
            width: 10px;
            margin-right: 0.4rem;
          }

          
        }

        .last-inst {
          margin-bottom: 2.4rem;
        }

       
        .notFilled {
          color: #D50000;
        }
        .isFilled {
          color: #17AB13;
        }

        .signup_error {
          border: 1px solid #d42620 !important;
        }

        .signup_error_text {
          font-size: 12px;
          color: #d42620;
          margin-top: -2rem;
          margin-bottom: 2.4rem;
        }




        .signup_remember {
          display: flex;
          align-items: center;
          font-size: 12px;
        }

        .checkbox {
          width: 24px;
          height: 24px;
          border-radius: 50px !important;
          outline: 1px solid yellow;
          border: none !important;
        }
      }

      .signup_checkbox_group {
        display: flex;
        align-items: center;
        font-size: 14px;
        margin-bottom: 32px;
        gap: 8px;

        input {
          width: 24px;
          height: 24px;
          margin-right: 16px;
        }

        span {
          color: #00aed9;
          cursor: pointer;
          font-weight: 700;

          &:hover {
            text-decoration: underline;
          }
        }
      }

      .didnt_get {
        text-align: center;
        margin-top: 24px;
        font-size: 12px;

        // &:hover {
        //   text-decoration: underline;
        // }

        & > span {
          text-decoration: underline;
          font-weight: 600;
          cursor: pointer;
        }

        &__error {
          color: #D42620;
          text-decoration: none !important;
        }
      }

      
    }

    .signup_flex {
      display: flex;
      width: 100%;
      justify-content: space-between;
      align-items: center;
    }

    .forgot {
      color: #006633;
      text-decoration: underline;
      font-size: 14px;
      font-weight: 700;
    }

    .signup_submit {
      width: 100%;
      height: 72px;
      border-radius: 12px;
      background: linear-gradient(116.29deg, #006633 0.76%, #00aed9 100%);
      display: flex;
      align-items: center;
      justify-content: center;
      border: none;
      outline: none;
      color: #ffffff;
      font-size: 1.6rem;
      font-weight: 600;
      cursor: pointer;
      margin-top: 4rem;
      transition: 0.7s;

      @media (max-width: 565px) {
        height: 58px;
        margin-top: 1.2rem;

      }

      & > div {
        margin-right: 12px;
        color: #ffffff;
      }

      &:hover {
        transition: 0.7s;

        background: none;
        color: #006633;
        border: 1px solid #006633;

        & > div {
          color: #006633;
        }
        svg {
          path {
            fill: #006633;
          }
        }
      }
    }
  }
  .modal_none {
    display: none;
  }
  .modal {
    width: 100%;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    background: rgba(0, 0, 0, 0.7);
    z-index: 1;
  }
  .errorModal {
    width: 100%;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    background: rgba(0, 0, 0, 0.7);
    z-index: 1;
  }

  .email_modal {
    width: 48.8rem;
    min-height: 43.2rem;
    background-color: #fff;
    position: absolute;
    top: 50%;
    left: 50%;
    z-index: 2;

    transform: translate(-50%, -50%);
    /* background-color: #ffffff; */
    box-shadow: 24;
    /* height: 641, */
    overflow: auto;
    padding: 32px;
    border-radius: 24px;
    border: none !important;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    .confirm_svg {
      margin-bottom: 24px;
    }

    .modal_title {
      font-size: 3.2rem;
      font-weight: 700;
      color: #141414;
      margin-bottom: 12px;
    }
    .modal_subtitle {
      font-size: 1.4rem;
      font-weight: 400;
    }

    ul {
      font-size: 14px;
      font-weight: 700;
    }

    .modal_email {
      font-weight: 700;
      font-size: 12px;
      margin-top: 2.4rem;
    }

    .modal_label {
      font-size: 16px;
      font-weight: 500;
      color: #141414;
      width: 100%;
      margin-bottom: 12px;
      margin-top: 12px;
    }
    .modal_input {
      width: 100%;
      color: #667185;
      border: none;
      border-radius: 12px;
      height: 5.5rem;
      outline: none !important;
      font-size: 1.2rem;
      // margin-bottom: 2.4rem;
    }

    .modal_button {
      width: 100%;
      height: 7.2rem;
      border-radius: 12px;
      background: linear-gradient(116.29deg, #006633 0.76%, #00aed9 100%);
      display: flex;
      align-items: center;
      justify-content: center;
      border: none;
      outline: none;
      color: #ffffff;
      font-size: 1.6rem;
      font-weight: 600;
      cursor: pointer;
      margin-top: 3.6rem;
      margin-bottom: 2.4rem;
      transition: 0.7s;

      & > div {
        margin-right: 12px;
      }

      &:hover {
        transition: 0.7s;

        background: none;
        color: #006633;
        border: 1px solid #006633;
      }
    }

    .didnt_get {
      font-size: 12px;
    }
  }
  .error_modal {
    width: 31.7rem;
    min-height: 16.5rem;
    background-color: #fff;
    position: absolute;
    top: 50%;
    left: 50%;
    z-index: 2;

    transform: translate(-50%, -50%);
    /* background-color: #ffffff; */
    box-shadow: 24;
    /* height: 641, */
    overflow: auto;
    padding: 18px;
    border-radius: 12px;
    border: none !important;
    display: flex;
    flex-direction: column;
    justify-content: center;

    .modal_title {
      font-size: 1.8rem;
      font-weight: 700;
      color: #141414;
      margin-bottom: 1.2rem;
    }
    .modal_subtitle {
      font-size: 1.2rem;
      font-weight: 400;
      margin-bottom: 1.2rem;
    }

   

    .error_modal_button {
      width: 100% !important;
      height: 3.6rem;
      border-radius: 12px;
      background: transparent;
      border: 1px solid var(--Gradient, #063);
      display: flex;
      align-items: center;
      justify-content: center;
      outline: none;
      color: #063;
      font-size: 1.6rem;
      font-weight: 600;
      cursor: pointer;
      transition: 0.7s;
      white-space: nowrap;
      padding: 0 36px;

    
     
    }

  }

  &__signature-modal {
    width: 100%;
    height: 100vh;
    position: absolute;
    top: 0;
    left: 0;
    background: rgba(0, 0, 0, 0.7);
    z-index: 3;
    display: flex;
    align-items: center;
    justify-content: center;

    &__inner {
      width: 31.7rem;
      min-height: 14.5rem;
      background: #fff;
      border-radius: 12px;
      padding: 10px;

      &__title {
        font-size: 18px;
        font-weight: 500;
        color: #000;
        text-align: center;
        margin-bottom: 16px;
      }

      & > button {
        width: 100%;
        height: 7.2rem;
        border-radius: 12px;
        background: linear-gradient(116.29deg, #006633 0.76%, #00aed9 100%);
        display: flex;
        align-items: center;
        justify-content: center;
        border: none;
        outline: none;
        color: #ffffff;
        font-size: 1.6rem;
        font-weight: 600;
        cursor: pointer;
        margin-top: 3.6rem;
        transition: 0.7s;
      }
    }

  }
}

@media (max-width: 1025px) {
  .signup {
    .signup_inner {
      padding: 123px 40px;
    }
  }
}
