.partners {
    .partners_inner {
        width: 100%;
        max-width: 1440px;
        padding: 149px 144px 170px 144px;
        display: grid;
    grid-template-columns: 200px 760px;
    grid-column-gap: 100px;
    grid-row-gap: 32px;

    img {
        width: 100%;
        height: auto;
    }

    & > div {
        .title {
            font-size: 3.2rem;
            font-weight: 700;
            color: #3B3B3B;
            margin-bottom: 1.6rem;
        }

        .subtitle {
            font-size: 1.6rem;
            font-weight: 500;
            color: #898989;
        }
    }
    }

    
}

@media (max-width: 1200px) {
    .partners {
        .partners_inner {
            padding: 32px 16px 170px 16px;
            grid-template-columns: 200px auto;
            align-items: center;
        }
    }
}

@media (max-width: 600px) {
    .partners {
        .partners_inner {
            grid-column-gap: 32px;
            grid-template-columns: 1fr 3.8fr;

            & > div {
                .title {
                    font-size: 2.4rem ;
                }
                .subtitle {
                    font-size: 1.4rem;
                }
            }

        }
    }
}