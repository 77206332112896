.iot {
  display: flex;

  .sidebar_container {
    @media (max-width: 1024px) {
      display: none;
    }
  }

  .main {
    position: relative;
    width: 1440px;
    max-width: 100%;
    padding: 44px;
    height: 100vh;
    overflow: auto;
    margin: auto;

    @media (max-width: 565px) {
      padding: 24px;
    }

    .title {
      font-size: 2.4rem;
      font-weight: 700;
      color: #1a1a1a;
      margin-bottom: 24px;
    }

    .grid {
      display: grid;
      grid-template-columns: 1fr 1fr;
      gap: 32px;

      @media (max-width: 768px) {
        gap: 20px;
      }
      .card {
        width: 100%;
        border-radius: 8px;
        padding: 24px;
        font-size: 1.6rem;
        position: relative;
        border: 1px solid #E5E5E5;

        @media (max-width: 768px) {
          padding: 16px 12px;
          font-size: 1.2rem;

        }

        &__indicator {
          width: 20px;
          height: 20px;
          position: absolute;
          top: 20px;
          right: 20px;
          border-radius: 50%;
          cursor: pointer;

          @media (max-width: 565px) {
            top: unset;
            right: 10px;
            bottom: 10px;
          }
        }

        &__indicator-active {
          background: #099137;
        }
        &__indicator-inactive {
          background: #D42620;
        }

        &__battery-level {
          position: absolute;
          bottom: 20px;
          right: 20px;
          font-size: 1.6rem;
          font-weight: 400;
          color: #000;

          @media (max-width: 565px) {
            display: none;
          }


          & > span {
            font-weight: 700;
          }
        } 

        .grid_inner {
          display: grid;
          grid-template-columns: 1fr 1.6fr;
            grid-row-gap: 24px;
            margin-bottom: 24px;
            @media (max-width: 768px) {
              font-size: 1.2rem;
              grid-row-gap: 12px;
              margin-bottom: 12px;
            }

          .subtitle {
            // text-align: start;

            
          }
        }

        .flex {
            display: flex;
            align-items: center;
            flex-direction: column;
                gap: 16px;
                width: fit-content;
            @media (max-width: 768px) {
              font-size: 1rem;
            }
            & > :first-child {
                margin-right: 48px;

                @media (max-width: 768px) {
                  margin-right: 20px;
                }
            }
            .flex_inner {
                display: flex;
                align-items: center;
                

                @media (max-width: 565px) {
                  font-size: 0.8rem;
                }

                svg {
                    margin-right: 16px;

                    @media (max-width: 768px) {
                      width: 16px;
                      height: 16px;
                      margin-right: 4px;
                    }
                }
            }
        }

        .more {
            text-decoration: underline;
            margin-top: 24px;
            color: #063;
        }
      }
    }

    .empty_list {
      font-size: 3.2rem;
      font-weight: 500;
      text-align: center;
      color: #063;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      width: 100%;
      color: #1a1a1a;
      text-align: center;
      font-size: 1.8rem;
      font-weight: 700;
      margin-top: 248px;

      svg {
        margin-bottom: 2.4rem;
      }
    }
  }
}
