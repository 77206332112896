.scheduling {
  display: flex;

  .sidebar_container {
    max-height: 700px;
    position: relative;

    @media (max-width: 1024px) {
      display: none;
    }
  }

  .scheduling-main {
    max-width: 1800px;
    width: 100%;
    margin: auto;
    padding: 44px;
    height: 100vh;
    overflow: auto;

    @media (max-width: 1024px) {
      overflow: unset !important;
    }

    @media (max-width: 565px) {
      padding: 24px;
    }

    &__title {
      font-size: 2.4rem;
      font-weight: 700;
      color: #1a1a1a;
      margin-bottom: 1.6rem;
    }

    &__subtitle-group {
      display: flex;
      align-items: center;
      justify-content: space-between;
      color: #1a1a1a;
      font-size: 1.6rem;
      font-weight: 500;
      margin-bottom: 3.2rem;

      & > :last-child {
        display: flex;
        gap: 12px;
        position: relative;

        button {
          display: flex;
          min-width: 103px;
          height: 37px;
          padding: 10px;
          justify-content: center;
          align-items: center;
          border-radius: 8px;
          background: linear-gradient(116deg, #063 0.76%, #02a4cc 100%);
          border: none;
          outline: none;
          color: #fff;
          font-size: 1.4rem;
          font-weight: 400;
        }

        .calendar-confirm {
          position: absolute;
          top: 0;
          right: 0;
          width: 29rem;
          height: 18.6rem;
          border-radius: 8px;
          border: 1px solid #efefef;
          background: #FFF;
          z-index: 2;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;

          .calendar-confirm-title {
            color: #1a1a1a;
            font-size: 1.4rem;
            font-weight: 700;
            margin-bottom: 1.2rem;
          }
          .calendar-confirm-subtitle {
            color: #667185;
            text-align: center;
            font-size: 1rem;
            font-weight: 400;
            margin-bottom: 2.4rem;

          }
          .calendar-confirm-button-group {
            display: flex;
            align-items: center;
            justify-content: center;
            gap: 12px;

            & > :first-child {
              background: transparent;
              border: 1px solid  #063;
              background: linear-gradient(116deg, rgba(0, 102, 51, 0.40) 0.76%, rgba(2, 164, 204, 0.40) 100%);
background-clip: text;
-webkit-background-clip: text;
-webkit-text-fill-color: transparent;
            }
          }
        }

        & > :last-child {
          justify-content: space-between;
          background: transparent;
          color: #98a2b3;
          padding: 0 15px;
          border: 1px solid #063;
          position: relative;

          .ndclist-modal {
            width: 204px;
            height: 276px;
            background: #e7f6ec;
            position: absolute;
            top: 52px;
            right: 0;
            border-radius: 4px 0px 0px 4px;
            border: 1px solid #efefef;
            z-index: 2;

            &__search {
              width: 100%;
              height: 32px;
              background: #fff;
              display: grid;
              grid-template-columns: 16px 1fr;
              align-items: center;
              gap: 8px;
              color: #898989;
              font-size: 1.2rem;
              font-weight: 400;
              padding: 0 8px;
              margin-top: 12px;

              svg {
                width: 100%;
                height: 16px;
              }

              input {
                border: none;
                outline: none;
                width: 100%;
              }
            }

            &__list {
              height: 232px;
              overflow-y: auto;
              padding-left: 30px;

              &::-webkit-scrollbar {
                display: none;
              }

              &__flex {
                display: flex;
                height: 3.1rem;
                color: #1a1a1a;
                align-items: center;
                margin-top: 2rem;
                font-size: 1.2rem;
                font-weight: 400;
                gap: 12px;
                margin-bottom: 1.2rem;
                padding: 0 8px;
                border-radius: 4px 0px 0px 4px;

                &:hover {
                  background: #fff;
                  color: #1a1a1a;
                }

                input {
                  width: 16px;
                  height: 16px;
                  border-radius: 4px;
                }
              }
            }
          }
        }
      }
    }

    &__calendar-months {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
      gap: 36px;
      align-items: center;
      margin-bottom: 1.2rem;
      overflow-x: auto;

      & > div {
        width: 100%;
        text-align: center;
        color: #667185;
        text-align: center;
        font-size: 1.4rem;
        font-weight: 400;
        cursor: pointer;
      }

      &__month-active {
        color: #1a1a1a !important;
        font-weight: 700 !important;
      }
    }

    &__calendar-row {
      display: flex;
      gap: 29px;
      align-items: center;
      overflow-x: auto;

      &::-webkit-scrollbar {
        display: none;
      }

      &__days {
        color: #667185;
        font-size: 12px;
        font-weight: 500;
        display: flex;
        flex-direction: column;
        gap: 0.4rem;
        align-items: center;
        // background: green;

        & > :first-child {
          width: 40px;
          height: 40px;
          display: flex;
          align-items: center;
          justify-content: center;
          border-radius: 6px;
        }

        .available {
          color: #1a1a1a;
          cursor: pointer;
        }

        .available:hover {
          background: #97d9ea;
        }

        .active-date {
          background: #00aed9;
          color: #fff;
        }

        .inactive-date {
          color: #d0d5dd;
        }
      }
    }

    &__calendar-header {
      & > div {
        color: #1a1a1a;
      }
    }

    &__table {
      width: 100%;
      height: 1200px;
      border: 1px solid #efefef;
      border-radius: 12px;
      position: relative;
      margin-top: 3.2rem;

      
    @media (max-width: 565px) {
      overflow-x: auto;
    }

      &__header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0 24px;
        height: 64px;
        border-bottom: 1px solid #efefef;
        background: #f9fafb;
        border-radius: 12px 12px 0px 0px;

        
    @media (max-width: 565px) {
      width: 800px;
    }

        & > :first-child {
          display: flex;
          align-items: center;
          gap: 10px;
          background: #f0f2f5;
          border-radius: 8px;
          padding: 0 4px;
          height: 50px;

          button {
            min-width: 10.3rem;
            height: 4.2rem;
            outline: none;
            border-radius: 8px;
            background: none;
            border: none;
            color: #898989;
            font-size: 1.6rem;
            font-weight: 400;
          }

          .active {
            border: 1px solid #063;
            background: #fff;
            box-shadow: -2px 2px 2px 0px rgba(0, 102, 51, 0.05);
          }

          & > div {
            width: 1px;
            height: 19px;
            background: #e4e7ec;
          }
        }

        & > :last-child {
          position: relative;
          button {
            min-width: 10.3rem;
            height: 3rem;
            outline: none;
            border-radius: 8px;
            border: none;
            color: #98a2b3;
            font-size: 1rem;
            font-weight: 400;
            background: #fff;
            border: 1px solid #063;
            padding: 0 15px;

            display: flex;
            align-items: center;
            justify-content: space-between;
          }

          .active {
            border: 1px solid #063;
            background: #fff;
            box-shadow: -2px 2px 2px 0px rgba(0, 102, 51, 0.05);
          }

          & > div {
            position: absolute;
            top: 3.4rem;
            left: 0;
            width: 10.3rem;
            height: 12.4rem;
            border-radius: 4px;
            border: 0.2px solid #063;
            background: #fff;
            overflow-y: auto;
            font-size: 1rem;
            color: #667185;

            &::-webkit-scrollbar {
              display: none;
            }

            & > div {
              height: 2rem;
              padding: 0 8px;
              display: flex;
              align-items: center;
              cursor: pointer;
              &:hover {
                background: #063;
                color: #fff;
              }
            }
          }
        }
      }

      &__label {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr 1fr;
        color: #1a1a1a;
        align-items: center;
        font-size: 1.2rem;
        font-weight: 500;
        height: 44px;
        padding: 0 24px;
        border-bottom: 1px solid rgba(177, 177, 177, 0.4);
        background: #fafafa;
        gap: 20px;

        
    @media (max-width: 565px) {
      width: 800px;
    }
      }
      &__item {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr 1fr;
        align-items: center;
        height: 72px;
        background: #fff;
        padding: 0 24px;
        gap: 20px;
        cursor: pointer;

        
    @media (max-width: 565px) {
      width: 800px;
    }

        &:hover {
          border-top: 1px solid #063;
          border-bottom: 1px solid #063;
        }

        & > div {
          color: #1a1a1a;
          font-size: 1.4rem;
          font-weight: 500;
          word-wrap: break-word;
          word-break: break-all;
        }

        button {
          border-radius: 24px;
          border: none;
          outline: none;
          width: fit-content;
          height: 3.2rem;
          display: flex;
          align-items: center;
          justify-content: center;
          gap: 4px;
          border: 1px solid #e7f6ec;
          padding: 0 12px;
          font-size: 1.4rem;
          font-weight: 500;
        }

        &__active {
          background: #5fc381;
          color: #2a633d;
        }
        &__pause {
          background: #d0d5dd;
          color: #e4e7ec;
        }
        &__completed {
          background: #0f973d;
          color: #fff;
        }
      }
    }
  }
}
