.search_container {
  position: relative;
  width: 1100px;
  max-width: 100%;
  display: flex;
  align-items: end;
  justify-content: space-between;
  margin-bottom: 30px;

  .mobile_menu {
    display: none;
    margin-bottom: 2.457rem;

   @media (max-width: 1024px) {
    display: flex;
    align-items: center;
    img {
      width: 32px;
      height: auto;
      margin-left: 2.9rem;
    }
   }
   @media (max-width: 565px) {
    img {
      width: 16px;
    }
   }


  }

  .search_form {
    background-color: #fafafa;
    display: flex;
    align-items: center;
    width: 38.4rem;
    height: 4rem;
    padding: 0 24px;
    border-radius: 8px;
    position: relative;

    @media (max-width: 565px) {
      width: 17.3rem;
      height: 2.4rem;
    }

    svg {
      margin-right: 8px;
      
      @media (max-width: 565px) {
        width: 16px;
        margin-right: 4px;
      }
    }

    .search_input {
      width: 100%;
      border: none;
      outline: none;
      background: none;
      height: 4rem;
      z-index: 2;

      @media (max-width: 565px) {
        font-size: 0.8rem;
      }
    }
   
    .search-list {
      width: 38.4rem;
      height: 30rem;
      background: #FFF;
      position: absolute;
      top: 4.8rem;
      left: 0;
      color: black;
      overflow: auto;
      z-index: 2;
      box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25),
          4px 0px 4px 0px rgba(0, 0, 0, 0.25);

          @media (max-width: 565px) {
            width: 100%;
            height: 100vh;
            position: fixed;
            top: 10rem;

          }

          &::-webkit-scrollbar {
            display: none;
          }

      &__flex {
        display: flex;
        align-items: center;
        // justify-content: space-between;
        padding: 20px;
        font-size: 1.8rem;
        cursor: pointer;

        @media (max-width: 565px) {
          font-size: 1.4rem;
        }

        svg {
          margin-right: 2rem;
        }

        &:hover {
          background: #bbb;

          
        }
      }
    }
  }

  .search_flex {
    display: flex;
    align-items: center;

    .notification {
      margin-right: 1.025rem;
      position: relative;

      @media (max-width: 565px) {
        margin-right: 0.4rem;
      }

      & > :last-child {
        @media (max-width: 565px) {
          width: 16px;
        }
      }

      .badge {
        position: absolute;
        top: 3px;
        right: 0;
      }
    }
    .profile {
      display: flex;
      align-items: center;
      font-weight: 700;
      cursor: pointer;
  
      .search_name {
        width: 4.2rem;
        height: 4.2rem;
        border-radius: 50%;
        background-color: #063;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 1.4rem;
        color: #fff;
        margin-right: 0.8rem;
        position: relative;

        @media (max-width: 565px) {
          width: 2.4rem;
        height: 2.4rem;
        font-size: 1.2rem;
        margin-right: 0.4rem;
        }
  
        .kyc_svg{
          position: absolute;
          bottom: 1px;
          right: -7px;

          @media (max-width: 565px) {
            display: none;
          }
        }
        
        .tag {
          position: absolute;
          width: 17.5rem;
          min-height: 4.8rem;
          background-color: #fff;
          top: 41px;
          left: 34px;
          border-radius: 6px;
          border: 1px solid #E5E5E5;
          font-size: 1.2rem;
          font-weight: 400;
          color: #1a1a1a;
          z-index: 3;
          display: flex;
          align-items: center;
          justify-content: center;
          padding: 16px 10px;
          transition: 1s;
          color: #063;

          @media (max-width: 565px) {
            display: none;
          }
        }
  
        .tag_progress {
          color: #00AED9;
        }
      }
  
      .profile_title {
        font-size: 1.2rem;
        color: #98A2B3;

        @media (max-width: 565px) {
        font-size: 1rem;
        }
      }
    }
  
  }
 
}

.search-list-modal {

  width: 100%;
  height: 100vh;
  position: absolute;
  top: 0;
  left: 0;
  // background: rgba(0, 0, 0, 0.7);
  z-index: 1;
}
