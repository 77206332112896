.verification {
  display: flex;
  .sidebar_container {
    // background-color: yellow;
    //  height: 100%;
    max-height: 700px;
    position: relative;

    @media (max-width: 1024px) {
      display: none;
    }
  }
  .main {
    width: 1440px;
    max-width: 100%;
    height: 100vh;
    background-color: #fff;
    padding: 44px;
    overflow-x: auto;
    margin: auto;

    @media (max-width: 565px) {
      padding: 24px;
    }

    &__no-data {
      width: 100%;
      height: 500px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      &__title {
        color: #1A1A1A;
text-align: center;
font-size: 1.8rem;
font-weight: 700;
line-height: 27px;
margin-top: 24px;
      }
    }

    .tab_label_container {
      width: 82.7rem;
      //   background-color: teal;
      border-bottom: 1px solid #d0d5dd;
      margin-bottom: 4.8rem;

      @media (max-width: 768px) {
        width: 100%;
        margin-left: 0;
      }

      .tab_label {
        width: 27.5rem;
        text-transform: none;
        font-size: 1.8rem;
        font-weight: 700;
        font-family: "Inter" !important;
        color: #d0d5dd !important;
        // background-color: tomato;

        @media (max-width: 768px) {
          width: unset;
          font-size: 1.2rem;
        }
      }

      .tab_active {
        color: #00AED9 !important;

      }

      .tabs {
        display: flex;
        align-items: center;
        width: 100%;
      }
    }

    .verification_label {
      font-size: 1.6rem;
      font-weight: 500;
      margin-bottom: 0.8rem;
      display: flex;
      align-items: center;

      .progress {
        width: 10px;
        height: 10px;
        background-color: #00aed9;
        border-radius: 50%;
        margin: 0 5px;
      }
      .completed {
        width: 10px;
        height: 10px;
        background-color: #0f973d;
        border-radius: 50%;
        margin: 0 5px;
      }
    }

    .progress {
      color: #00aed9;
    }

    .completed {
      color: #0f973d;
      margin-top: 2.4rem;
    }

    .failed {
      color: #d42620;
      margin-top: 2.4rem;
    }
    .verification_box_container {
      width: 100%;
      display: grid;
      grid-template-columns: 1fr;
      grid-column-gap: 50px;
      grid-row-gap: 12px;

      .verification_box {
        max-width: 1200px;
        width: 100%;
        // background-color: blue;
        min-height: 149px;
        border-radius: 16px;
        border: 1px solid #E5E5E5;
        padding: 0 20px 20px 20px;

        .verification_bar {
          width: 100%;
          color: #00aed9;
          height: 12px;
        }
        .verification_bar_completed {
          width: 100%;
          color: #0f973d;
          height: 12px;
        }
        .verification_bar_failed {
          width: 100%;
          color: #d42620;
          height: 12px !important;
        }

        .verification_box_flex {
          display: flex;
          align-items: center;
          @media (max-width: 768px) {
            flex-direction: column;
          }
          .verification_progress {
            // width: 100px;
            // height: 100px;
            // background: rgba(0, 174, 217, 0.3);
            // border-radius: 50%;
            // margin-top: 0 !important;
            // font-size: 1.8rem;
            // font-weight: 700;
            // color: #282828;
            // display: flex;
            // align-items: center;
            // justify-content: center;
            // position: relative;
            // border: 12px solid #00AED9;
            margin-right: 9.7rem;

            @media (max-width: 768px) {
              margin-right: 0;
              margin-bottom: 12px;
            }
          }

          .verification_error {
            border: 12px solid #d42620 !important;
            background: none;
          }

          .verification_tick_group {
            display: flex;
            align-items: center;

            @media (max-width: 768px) {
              width: 100%;
              justify-content: space-between;
            }
            .verification_tick {
              margin-right: 3.65rem;
              width: fit-content;
              display: flex;
              flex-direction: column;
              align-items: center;
              justify-content: center;

              @media (max-width: 768px) {
                margin-right: 0;
              }

              & > :first-child {
                width: 32px;
                height: 32px;
                background-color: #00aed9;
                border-radius: 50%;
                display: flex;
                align-items: center;
                justify-content: center;
                margin-bottom: 4px;

                @media (max-width: 565px) {
                  width: 16px;
                  height: 16px;
                }

                svg {
                  @media (max-width: 565px) {
                    width: 10px;
                    height: 10px;
                  }
                }
              }

              & > :last-child {
                text-align: center;
                font-weight: 500;
                color: #1a1a1a;
              }
            }

            .progress_tick {
              opacity: 0.3;
            }

            .verification_tick_completed {
              & > :first-child {
                background-color: #0f973d;
              }
            }

            .verification_tick_error {
              & > :first-child {
                background-color: #d42620;
              }
            }
          }
        }

        .verification-box__divider {
          width: 100%;
          height: 0.5px;
          background: #E4E7EC;
          margin: 1.6rem 0 3.2rem 0;
        }
        .verification-box__link {
          width: 100%;
          color: #0D924F;
justify-content: right;
font-size: 1.6rem;
font-weight: 500;
display: flex;
align-items: center;
gap: 12px;
margin-bottom: 1.2rem;
        }
      }
    }

    .empty_list {
      font-size: 3.2rem;
      font-weight: 500;
      text-align: center;
      color: #063;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      width: 100%;
      color: #1a1a1a;
      text-align: center;
      font-size: 1.8rem;
      font-weight: 700;
      margin-top: 248px;

      svg {
        margin-bottom: 2.4rem;
      }
    }
  }
}
