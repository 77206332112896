.support-hub {
  display: flex;

  .sidebar_container {
    // background-color: yellow;
    //  height: 100%;
    max-height: 700px;
    position: relative;

    @media (max-width: 1024px) {
      display: none;
    }
  }

  .support-hub__main {
    max-width: 1800px;
    width: 100%;
    margin: 0 auto;
    padding: 44px;
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    overflow: auto;

    @media (max-width: 1024px) {
      overflow: unset !important;
    }

    @media (max-width: 565px) {
      padding: 24px;
    }
    .support-hub__main-inner  {
      &__table-header {
        width: 100%;
        display: grid;
        grid-template-columns: 1fr 1fr 1fr 1fr;
        grid-column-gap: 3rem;
        border-radius: 16px;
        padding: 0;
        border: 1px solid var(--Gradient, #063);
        margin-bottom: 3.2rem;
        height: 8.2rem;
        align-items: center;
        text-align: center;
        font-size: 1.8rem;
        font-weight: 500;
        color: #d0d5dd;

        @media (max-width: 768px) {
          grid-column-gap: 24px;
          padding: 0 4px;
        }
        @media (max-width: 565px) {
          font-size: 10px;
        }
    
        
        & > div {
          width: fit-content;
          justify-self: center;
          cursor: pointer;
        }
  
        .active {
          background: #00AED9;
          width: 14.2rem;
          padding: 8px 0;
          color: #fff;
          font-weight: 700;
          border-radius: 8px;
          white-space: nowrap;
          @media (max-width: 768px) {
            width: fit-content;
            padding: 8px;
          }
        }
      }
      &__table-body {
        width: 100%;
        display: grid;
        grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
        grid-column-gap: 3rem;
        border-radius: 16px;
        border: 1px solid var(--Gradient, #063);
        min-height: 7.7rem;
        align-items: center;
        text-align: center;
        margin-bottom: 2.4rem;
        font-size: 1.6rem;
        font-weight: 500;
        color: #1a1a1a;
        cursor: pointer;
        padding: 0 89px;
        white-space: nowrap;

        @media (max-width: 1024px) {
          padding: 0 12px;
        }
        @media (max-width: 768px) {
          font-size: 12px;
        }
  
        @media (max-width: 565px) {
          font-size: 8px;
          grid-column-gap: 8px;
          white-space: unset;
        }
  
        & > div {
          width: fit-content;
          justify-self: center;
          word-wrap: break-word;
          word-break: break-all;
  
          .table-body__filled {
            width: 11.8rem;
            height: 4rem;
            border: none;
            background: linear-gradient(116.29deg, #006633 0.76%, #00aed9 100%);
            border-radius: 8px;
            color: #fff;
            font-size: 1.2rem;
            font-weight: 700;
            cursor:unset;

            @media (max-width: 565px) {
              width: 69px;
              font-size: 8px;
            }
          }
          .table-body__transaprent {
            width: 11.8rem;
            height: 4rem;
            border: none;
            background: transparent;
            border-radius: 8px;
            color: #fff;
            font-size: 1.2rem;
            font-weight: 700;
            color: #063;
            border: 1px solid #063;
            text-decoration: underline;
            cursor: unset;

            @media (max-width: 565px) {
              width: 69px;
              font-size: 8px;
            }
          }
        }
      }
    }
    
  }
}
