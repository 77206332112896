.solutions {
    .hero {
        position: relative;
        min-height: 65.1rem;
    background: url('../../images/image\ 42.png');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 6.4rem;
    font-weight: 400;
    color: #141414;
    text-align: center;
    color: #FFF;

    span {
        font-weight: 700;
        // color: #063;
    }

}


.ourSolution {
    width: 100%;
    max-width: 1440px;
    padding: 64px 144px;
    
    margin: auto;

    .title {
        font-size: 3.6rem;
        font-weight: 500;
        color: #141414;
        margin-bottom: 2.4rem;
    }

    .group {
        display: grid;
        align-items: start;
        grid-template-columns: 1fr 1fr;
        grid-column-gap: 24px;
        grid-row-gap: 24px;


        .box {
            // width: 36.8rem;
            min-height: 21.6rem;
            padding: 24px;
            border: 1px solid #80D6EC;
            box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
            border-radius: 12px;
            position: relative;
            cursor: pointer;
            color: #3B3B3B;
            text-decoration: none;

            .title {
                font-size: 1.6rem;
                font-weight: 500;
                color: #141414;
                margin: 1.2rem 0;
            }

            
        .shadow {
            background: rgba(94, 182, 138, 0.30);
            width: 100%;
            height: 100%;
            position: absolute;
            top: 0;
            left: 0;
            display: none;
            border-radius: 12px;
          }

          &:hover {
            .shadow {
              display: initial;
            }
          }
        }
    }
}

.solution_feature {
    width: 100%;
    max-width: 1440px;
    padding: 32px 144px 0 144px;
    text-align: center;
    margin: auto;
    scroll-margin-top: 6.4rem;



    .title {
        font-size: 3.6rem;
        font-weight: 700;
        margin-bottom: 2.4rem;
        color: #0D924F;
    }

    .subtitle {
        width: 100%;
    max-width: 956px;
        font-size: 2.4rem;
        color: #3B3B3B;
        font-weight: 400;
        margin: auto;
        margin-bottom: 2.4rem;
    }

    .button_group {
        display: grid;
        align-items: center;
        display: flex;
        max-width: 81rem;
        justify-content: center;
        margin: auto;
        margin-bottom: 2.4rem;


        & > div {
            width: 125px;
            height: 3.9rem;
            border: 1px solid #0D924F;
            border-radius: 8px;
            outline: none;
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 1.4rem;
            font-weight: 400;
            color: #3B3B3B;
            margin-right: 12px;
        }
    }

    img {
        width: 100%;
        height: auto;
    }
}

.end {
    margin-bottom: 19.3rem;
}

}


@media (max-width: 600px) {
    .solutions {
        .hero {
            min-height: 42.2rem;

            font-size: 2.4rem;
        }

        .ourSolution{
            padding: 16px 16px;

            .group {
                grid-template-columns: 1fr;
                grid-row-gap: 16px;
            }
        }

        .solution_feature {
            padding: 16px 16px 0 16px;
            
            .title {
font-size: 2.4rem;
margin-bottom: 1.2rem;
            }

            .subtitle {
                font-size: 1.4rem;
                margin-bottom: 1.6rem;

            }

            .button_group {
                max-width: 100%;
                margin-bottom: 1.6rem;


                & > div {
                    width: 9.2rem;
                    height: 2.8rem;
                    font-size: 0.8rem;
                }
            } 

        }
    }
}