.ndc-requests {
    display: flex;
    .sidebar_container {
      // background-color: yellow;
      //  height: 100%;
      max-height: 700px;
      position: relative;
  
      @media (max-width: 1024px) {
        display: none;
      }
    }

.main {
    max-width: 1800px;
    width: 100%;
    margin: auto;
    padding: 44px;
    height: 100vh;
    overflow: auto;

    @media (max-width: 565px) {
      padding: 24px;
    }

    .title {
      font-size: 2.4rem;
      font-weight: 700;
      color: #1a1a1a;
      margin-bottom: 2.4rem;

      @media (max-width: 768px) {
        font-size: 1.6rem;
        margin-bottom: 1.6rem;
      }
    }
    .subtitle {
      color: #1a1a1a;
      font-size: 1.6rem;
      font-weight: 500;
      margin-bottom: 1.6rem;
    }

    

      .requestNdc-table {
        width: 100%;
        max-width: 86.6rem;
        height: 1200px;
        border: 1px solid #efefef;
        border-radius: 12px;
        position: relative;
        padding: 0 10px;
        background: #F9FAFB;
    
        &__header {
          display: flex;
          align-items: center;
          justify-content: space-between;
          padding: 0 24px;
          height: 64px;
          border-bottom: 1px solid #efefef;
          background: #f9fafb;
          border-radius: 12px 12px 0px 0px;
          margin-bottom: 2rem;
    
          & > :first-child {
            display: flex;
            align-items: center;
            gap: 10px;
            background: #f0f2f5;
            border-radius: 8px;
            padding: 0 4px;
            height: 50px;
    
            button {
              min-width: 10.3rem;
              height: 4.2rem;
              outline: none;
              border-radius: 8px;
              background: none;
              border: none;
              color: #898989;
              font-size: 1.8rem;
              font-weight: 400;
            }
    
            .active {
              border: 1px solid #063;
              background: #fff;
              box-shadow: -2px 2px 2px 0px rgba(0, 102, 51, 0.05);
            }
    
            & > div {
              width: 1px;
              height: 19px;
              background: #e4e7ec;
            }
          }
    
          & > :last-child {
            button {
              min-width: 10.3rem;
              height: 4.2rem;
              outline: none;
              border-radius: 8px;
              border: none;
              color: #98A2B3;
              font-size: 1.4rem;
              font-weight: 400;
              background: #f0f2f5;
            }
    
            .active {
              border: 1px solid #063;
              background: #fff;
              box-shadow: -2px 2px 2px 0px rgba(0, 102, 51, 0.05);
              color: #1A1A1A;
            }
          }
        }
    
        &__card {
          width: 100%;
          height: fit-content;
          padding: 24px;
          background: #FFF;
          border-radius: 16px;
        margin-bottom: 2.4rem;


          &__flex {
            display: flex;
            align-items: center;
            justify-content: space-between;
            color: #1A1A1A;
font-size: 1.6rem;
font-weight: 500;

svg {
  transform: rotate(180deg);
  cursor: pointer;
}
&__svg {
  transform: rotate(0deg) !important;
}

&__accepted {
  width: 118px;
  height: 32px;
  outline: none;
  border-radius: 8px;
border: 1px solid  #E7F6EC;
background: linear-gradient(116deg, #063 0.76%, #02A4CC 100%);
background-clip: text;
-webkit-background-clip: text;
-webkit-text-fill-color: transparent;
}

&__denied {
  width: 118px;
  height: 32px;
  outline: none;
  border-radius: 8px;
border: 1px solid  #E7F6EC;
color: #98A2B3;
background: none;
}
          }

          &__divider {
            width: 100%;
            height: 0.5px;
            background: #D0D5DD;
            margin: 16px 0;
          }

          &__reason {
            &__title {
              color:  #1A1A1A;
font-size: 1.4rem;
font-weight: 500;
margin-bottom: 0.8rem;
            }
            &__subtitle {
              color:  #1A1A1A;
font-size: 1.2rem;
font-weight: 400;
margin-bottom: 1.6rem;
            }

            &__button-group {
              display: flex;
              align-items: center;
              gap: 12px;
              button {
                width: 145px;
                height: 45px;
                display: flex;
                align-items: center;
                justify-content: center;
                border: none;
                outline: none;
                color: #fff;
                border-radius: 12px;
                font-size: 1.6rem;
                font-weight: 600;
                background: linear-gradient(116.29deg, #006633 0.76%, #02a4cc 100%);
                @media (max-width: 565px) {
                  width: fit-content;
                  padding: 0 24px;
                  font-size: 1.8rem;
                }
              }

              & > :first-child {
                background: transparent;
border: 0.5px solid #063;
color: #063;
              }
            }
          }
        }
      }
}

}