.partnership {
  width: 100%;
  max-width: 1440px;
  margin: auto;
  background-color: #fafafa;
  padding: 32px 28px 82px 28px;
  margin-bottom: 6.4rem;

  .title {
    font-size: 3.2rem;
    font-weight: 500;
    color: #3b3b3b;
    margin-bottom: 3.6rem;
    text-align: center;

    span {
      font-weight: 700;
      color: #0d924f;
    }
  }
  .partners_container {
    width: 100%;
    overflow: hidden;

    .partners {
      width: 100%;
      display: flex;
      align-items: center;
      animation: marquee 18s linear infinite;

      &::-webkit-scrollbar {
        display: none;
      }

      img {
        width: 200px;
        height: auto;
        margin-right: 7.8rem;
      }
    }
  }
}



@keyframes marquee {
  100% {
    transform: translateX(-276%);
  }
}