.glossary {
  .hero {
    background: url("../../images/glossary_hero.png");
    background-size: cover;
    background-position: center;
    height: 56.1rem;
  }

  .content {
    max-width: 1440px;
    width: 100%;
    padding: 48px 144px 135px 144px;
    .content_title {
      font-size: 3.2rem;
      font-weight: 700;
      color: #0d924f;
      margin-bottom: 3.2rem;
    }

    .content_body {
      .body_title {
        font-size: 2.4rem;
        font-weight: 500;
        color: #141414;
        margin-bottom: 1.6rem;
      }
      .body_content {
        font-size: 1.6rem;
        font-weight: 400;
        color: #141414;
        margin-bottom: 2.4rem;
      }
    }
  }
}

@media (max-width: 768px) {
  .glossary {
    .content {
        padding: 32px 16px 158px 16px;
      }
  }
}

@media (max-width: 600px) {
    .glossary {
        .hero {
            height: 15.9rem;
        }
        .content {
            .content_title {
                font-size: 2.4rem;
                margin-bottom: 1.6rem;
            }
            .content_body {
                .body_title {
                    font-size: 1.6rem;
                    margin-bottom: 1.2rem;
                }
                .body_content {
                    font-size: 1.2rem;
                    margin-bottom: 1.6rem;
                }
            }
        }
    }
}