.terms {
    text-align: justify;
    .hero {
            height: 37rem;
            width: 100%;
            max-width: 1440px;
            min-height: 47.8rem;
            margin: auto;
            display: flex;
            flex-direction: column;
            justify-content: center;
            // align-items: center;
            background: url('../../../images/image 97 (1).png');
            background-size: cover;
            background-position: center;
            padding: 0 144px;
            color: #FFF;

            .title {
                margin-bottom: 2.4rem;
                font-size: 6.4rem;
                font-weight: 700;
            }
            .subtitle {
                font-size: 2.4rem;
                font-weight: 400;
                width: 56.4rem;
                // background: blue;
            }


    }

    .content {
        width: 100%;
        max-width: 1440px;
        padding: 0 144px;
        margin: 6.4rem auto 21.2rem auto;
        .contentTitle {
            font-size: 2.4rem;
            font-weight: 500;
            margin-bottom: 1.6rem;
        }
        .contentSubtitle {
font-size: 2.4rem;
font-weight: 400;
margin-bottom: 1.6rem;
        }
        .contentParagraph {
font-size: 1.6rem;
font-weight: 400;
margin-bottom: 2.4rem;
line-height: 150%;
ul, ol {
    margin-top: 0;
}
        }
        .subtitleBold {
            font-weight: 500;
        }

        .contentFaq {
            margin-bottom: 2.4rem;
            .faqMainTitle {
                font-size: 3.2rem;
            font-weight: 500;
            // margin-bottom: 1.6rem;
            }
            .faqCard{
                margin-top: 2.4rem;
                border-bottom: 1px solid #b1b1b1;
                padding-bottom: 16px;

                .faqTitle {
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    font-size: 1.6rem;
                    font-weight: 500;
                    margin-bottom: 1.85rem;
                }

                .faqSubtitle {
                    font-size: 1.4rem;
                    font-weight: 400;
                   line-height: 150%;

                }
            }
        }
    }
}

@media (max-width: 1200px) {
    .terms {
        .hero {
            grid-template-columns: 1fr;
            height: unset;
            gap: 12px;
            padding: 0 16px;
            min-height: 26.4rem;

.title {
font-size: 2.4rem;
margin-bottom: 1.6rem;
}

.subtitle {
    width: 100%;
    font-size: 1.6rem;
}


        }

        .content {
            padding: 0 16px;
            margin: 3.2rem auto 21.2rem auto;

            .contentTitle {
                margin-bottom: 1.2rem;
                line-height: normal;
            }
            .contentSubtitle {
                font-size: 1.6rem;
                margin-bottom: 1.2rem;
            }
            .contentParagraph {
                font-size: 1.2rem;
                margin-bottom: 1.6rem;
            }
        }
    }
}