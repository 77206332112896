.plans {
  .hero {
    width: 100%;
    // height: 820px;
    background: linear-gradient(
      100deg,
      rgba(198, 232, 215, 0.3) 0%,
      rgba(22, 161, 89, 0.3) 50.09%,
      rgba(5, 102, 53, 0.3) 98.75%
    );

    &__inner {
      max-width: 1440px;
      width: 100%;
      padding: 100px 144px 0 144px;
      margin: 0 auto;
      position: relative;
      height: 100%;

      @media (max-width: 1024px) {
        padding: 100px 20px 0 20px;
      }
      &__title {
        font-size: 6.4rem;
        color: #000;
        font-weight: 700;
        text-align: center;
        margin-bottom: 1.2rem;

        @media (max-width: 565px) {
          font-size: 4.8rem;
        }

        span {
          color: #0d924f;
        }
      }

      &__subtitle {
        font-size: 1.8rem;
        font-weight: 400;
        color: #3b3b3b;
        text-align: center;
        width: 100%;
        max-width: 75ch;
        margin: auto;
        margin-bottom: 2.4rem;
      }

      &__price {
        font-size: 1.8rem;
        font-weight: 400;
        color: #3b3b3b;
        text-align: center;
        margin: auto;
        margin-bottom: 1.2rem;
        display: flex;
        align-items: center;
        width: fit-content;

        span {
          background: #eb5017;
          font-size: 8px;
          color: #fff;
          margin-left: 0.6rem;
          padding: 0 3px;
          border-radius: 10px;
        }
      }

      &__button-group {
        display: flex;
        align-items: center;
        gap: 16px;
        margin: 0 auto;

        width: fit-content;

        button {
          width: 129px;
          height: 40px;
          border-radius: 12px;
          background: #0d924f;
          border: none;
          color: #fff;
          display: flex;
          align-items: center;
          justify-content: center;
        }

        & > :first-child {
          transition: 0.24s ease-in;
          &:hover {
            background: transparent;
            border: 1px solid #0d924f;
            color: #0d924f;
          }
        }

        & > :last-child {
          background: transparent;
          border: 1px solid #0d924f;
          color: #0d924f;
          font-weight: 500;

          svg {
            margin-right: 0.6rem;
          }
        }
      }

      &__img {
        max-width: 820px;
        width: 100%;
        height: auto;
        margin: 0 auto;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 48px;
      }
    }
  }

  .solution {
    max-width: 1440px;
    width: 100%;
    padding: 0 144px;
    margin: 64px auto 64px auto;
    @media (max-width: 1024px) {
      padding: 0 20px;
    }
    &__title {
      font-size: 3.6rem;
      font-weight: 700;
      color: #3b3b3b;
      text-align: center;
      margin-bottom: 1.2rem;

      span {
        color: #0d924f;
      }
    }

    &__subtitle {
      font-weight: 500;
      font-size: 16px;
      text-align: center;
      margin: auto;
      color: #898989;
      margin-bottom: 4.8rem;
      span {
        font-style: italic;
        font-weight: 600;
      }
    }

    &__grid {
      width: 100%;
      display: grid;
      grid-template-columns: 466px 1fr;
      gap: 24px;

      @media (max-width: 768px) {
        grid-template-columns: 1fr;
      }

      &__nav {
        width: 100%;
        display: flex;
        flex-direction: column;
        gap: 72px;
        &__item {
          padding-left: 32px;
          border-left: 4px solid #cddad3;
          // margin-bottom: 7.2rem;
          cursor: pointer;
          &__title {
            font-size: 2.4rem;
            font-weight: 500;
            color: #3b3b3b;
            margin-bottom: 1.2rem;
          }
          &__subtitle {
            font-size: 1.4rem;
            font-weight: 400;
            color: #3b3b3b;
          }
        }

        &__item-active {
          border-left: 4px solid #0d924f;

          & > :first-child {
            color: #0d924f;
          }
        }
      }

      &__img {
        width: 100%;
        height: auto;
        // background: rgba(183, 221, 202, 0.30);
      }
    }
  }

  .feedback {
    width: 100%;
    background: #fafafa;
    &__inner {
      max-width: 1440px;
      width: 100%;
      padding: 64px 144px;
      margin: auto;

      @media (max-width: 1024px) {
        padding: 64px 20px;
      }

      &__group {
        width: 100%;
        position: relative;

        &__title {
          font-size: 3.6rem;
          font-weight: 500;
          color: #141414;
          margin-bottom: 1.2rem;
        }
        &__subtitle {
          font-size: 1.4rem;
          font-weight: 400;
          color: #3b3b3b;
          margin-bottom: 3.6rem;
        }
        &__svg-group {
          display: flex;
          align-items: center;
          gap: 24px;
          position: absolute;
          top: 0;
          right: 0;

          & > div {
            width: 48px;
            height: 48px;
            border-radius: 50%;
            display: flex;
            align-items: center;
            justify-content: center;
            background: rgba(183, 221, 202, 0.3);
            cursor: pointer;

            &:hover {
              background: #00aed9;

              & > svg {
                fill: #fff;
              }
            }

            & > svg {
              fill: #3b3b3b;
            }
          }
        }
      }
      &__cards {
        overflow-x: auto;
        scroll-behavior: smooth;

        &::-webkit-scrollbar {
          display: none;
        }

          width: 100%;
          display: flex;
          gap: 24px;
          align-items: start;
          &__card {
            padding: 24px;
            min-width: 368px;
            min-height: 180px;
            border-radius: 12px;
            border: 1px solid #0d924f;
            position: relative;
            z-index: 1;

            @media (max-width: 565px) {
              min-width: 300px;
            }

            &__title {
              margin-bottom: 3.2rem;
              font-size: 14px;
              font-weight: 400;
              color: #141414;
              z-index: 1;
              transition: 0.24s ease-in;
            }
            &__subtitle {
              color: #0d924f;
              font-size: 12px;
              font-weight: 400;
              margin-bottom: 0.6rem;
              z-index: 1;
              transition: 0.24s ease-in;
            }
            & > :last-child {
              color: #3b3b3b;
              font-size: 12px;
              font-weight: 500;
              z-index: 1;
              transition: 0.24s ease-in;
            }

            &__cover {
              position: absolute;
              bottom: -1px;
              right: -1px;
              background: #0d924f;
              z-index: -1;
              width: 42.038px;
              height: 20.562px;
              transition: 0.24s ease-in;
              border-radius: 1.37px;
            }

            &:hover {
              .feedback__inner__cards__card__cover {
                width: 100%;
                height: 100%;
                border-radius: 12px;
                bottom: 0px;
              right: 0px;
              }
              .feedback__inner__cards__card__title,
              .feedback__inner__cards__card__subtitle {
                color: #fff;
              }

              & > :last-child {
                color: #fff;
              }
            }
          }

       
      }
    }
  }

  .seamless {
    max-width: 1440px;
    width: 100%;
    padding: 64px 144px;
    margin: auto;
    display: grid;
    grid-template-columns: 1fr 1fr;
    align-items: start;
    gap: 76px;

    @media (max-width: 1024px) {
      padding: 0 20px;
    }

    @media (max-width: 768px) {
      grid-template-columns: 1fr;
    }

    &__card1 {
      &__title {
        font-size: 3.2rem;
        font-weight: 700;
        color: #141414;
        margin-bottom: 1.2rem;
      }
      &__subtitle {
        font-size: 1.4rem;
        font-weight: 400;
        color: #3b3b3b;
        margin-bottom: 1.8rem;
      }
      &__button-group {
        display: flex;
        align-items: center;
        gap: 16px;

        width: fit-content;

        button {
          width: 148px;
          height: 52px;
          border-radius: 12px;
          background: #0d924f;
          border: none;
          color: #fff;
          display: flex;
          align-items: center;
          justify-content: center;
          font-size: 1.4rem;
        }

        & > :first-child {
          transition: 0.24s ease-in;
          &:hover {
            background: transparent;
            border: 1px solid #0d924f;
            color: #0d924f;
          }
        }

        & > :last-child {
          background: transparent;
          border: 1px solid #0d924f;
          color: #0d924f;
          font-weight: 500;

          svg {
            margin-right: 0.6rem;
          }
        }
      }
    }

    &__card2 {
      width: 100%;

      &__group {
        display: flex;
        align-items: start;
        gap: 12px;
        margin-bottom: 2.4rem;
        & > :first-child {
          min-width: 3.2rem;
          min-height: 3.2rem;
          display: flex;
          align-items: center;
          justify-content: center;
          color: #fff;
          border-radius: 50%;
          background: #0d924f;
        }
        & > :last-child {
          & > :first-child {
            color: #0d924f;
            font-size: 2.4rem;
            font-weight: 500;
            margin-bottom: 0.6rem;
          }
          & > :last-child {
            color: #3b3b3b;
            font-size: 1.4rem;
            font-weight: 400;
          }
        }
      }
    }
  }

  .seamless-verifier {
    background: #fafafa;
  }

  .unlock {
    max-width: 1440px;
    width: 100%;
    padding: 64px 144px;
    margin: auto;
    display: grid;
    grid-template-columns: 1fr 1fr;
    align-items: start;
    gap: 76px;

    @media (max-width: 1024px) {
      padding: 64px 20px;
    }

    @media (max-width: 768px) {
      grid-template-columns: 1fr;
    }

    &__card1 {
      &__title {
        font-size: 3.1rem;
        font-weight: 500;
        color: #141414;
        margin-bottom: 1.2rem;
      }
      &__subtitle {
        font-size: 1.4rem;
        font-weight: 400;
        color: #3b3b3b;
        margin-bottom: 3.6rem;
      }

      &__img {
        width: 100%;
        max-width: 430px;
        height: auto;
      }
    }

    &__card2 {
      width: 100%;
      min-height: 516px;
      border-radius: 12px;
      border: 2px solid #0d924f;
      box-shadow: 0px 4px 12px 0px rgba(13, 146, 79, 0.1);
      padding: 36px;

      &__flex {
        display: flex;
        & > :first-child {
          margin-right: 1.2rem;
          width: 40px;
          height: 40px;
        }

        & > :last-child {
          & > :first-child {
            font-size: 1.6rem;
            color: #141414;
            margin-bottom: 0.6rem;
            font-weight: 500;
          }
          & > :last-child {
            font-size: 1rem;
            color: #898989;
            white-space: nowrap;

            @media (max-width: 565px) {
              white-space: wrap;
            }
          }
        }
      }

      &__divider {
        width: 100%;
        height: 1px;
        margin: 18px 0;
        background: #efefef;
      }

      &__price {
        & > :first-child {
          font-size: 3.6rem;
          color: #0d924f;
          font-weight: 700;
        }
      }
      &__more-info {
        display: flex;
        flex-direction: column;

        &__title {
          font-size: 1.4rem;
          color: #3b3b3b;
          margin-bottom: 1.6rem;
          font-weight: 500;
        }
        &__flex {
          display: grid;
          grid-template-columns: 12px 1fr;
          align-items: center;
          gap: 12px;
          margin-bottom: 1.2rem;
          font-size: 12px;
        }

        &__button {
          width: 136px;
          height: 37px;
          border-radius: 12px;
          border: none;
          background: #149554;
          color: #fff;
          transition: 0.4s;
          margin-top: 24px;

          &:hover {
            background: transparent;
            border: 1px solid #149554;
            color: #149554;
          }
        }
      }
    }
  }

  .faq {
    width: 100%;
    background: #d3eee0;
    &__inner {
      max-width: 1440px;
      width: 100%;
      padding: 64px 144px;
      margin: auto;
      display: grid;
      grid-template-columns: 1fr 1fr;
      align-items: start;
      gap: 76px;

      @media (max-width: 1024px) {
        padding: 64px 20px;
      }
      @media (max-width: 768px) {
        grid-template-columns: 1fr;
      }

      &__card1 {
        &__title {
          color: #141414;
          font-size: 4.8rem;
          font-weight: 700;

          span {
            color: #0d924f;
          }
        }
      }

      &__card2 {
        &__faq-card {
          width: 100%;
          max-width: 74.4rem;
          min-height: 9.4rem;
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          padding: 18px 18px;
          font-size: 1.8rem;
          color: #141414;
          font-weight: 500;
          margin: auto;
          border-radius: 12px;
          border: 1px solid #0d924f;
          background: #fff;
          margin-bottom: 3.2rem;

          .card_inner {
            display: grid;
            align-items: start;
            grid-template-columns: auto 3.2rem;
            justify-content: space-between;
            gap: 40px;

            .plus {
              background-color: rgba(13, 146, 79, 0.3);
              width: 32px;
              height: 32px;
              border-radius: 50%;
              position: relative;
              cursor: pointer;

              .item {
                width: 8.5px;
                height: 0.5px;
                background-color: #141414;
                border-radius: 1px;
                position: absolute;
                top: 50%;
                right: 50%;
                transform: translate(50%, 50%);
                transition: 0.3s;
              }

              .item2 {
                transform: translate(50%, 50%) rotate(90deg);
              }

              .itemOpaque {
                opacity: 0;
              }
            }
          }

          .subtitle {
            font-size: 1.4rem;
            font-weight: 400;
            color: #b1b1b1;
            margin-top: 2rem;
            margin-bottom: 1.5rem;
          }

          .line {
            height: 0.1rem;
            width: 96%;
            background-color: #b1b1b1;
          }
        }
      }
    }
  }

  .partnership {
    width: 100%;
    max-width: 1440px;
    margin: auto;
    background-color: #fafafa;
    padding: 32px 28px 82px 28px;
    margin-bottom: 6.4rem;

    @media (max-width: 1024px) {
      padding: 32px 20px 82px 20px;
    }

    .title {
      font-size: 3.2rem;
      font-weight: 500;
      color: #3b3b3b;
      margin-bottom: 3.6rem;
      text-align: center;

      span {
        font-weight: 700;
        color: #0d924f;
      }
    }
    
  }
}
