.tfa-modal-container {
    width: 100%;
height: 100vh;
position: absolute;
top: 0;
left: 0;
background: rgba(0, 0, 0, 0.7);
z-index: 4;

.tfa-modal {
    position: absolute !important;
    top: 50%;
    left: 50%;
    padding: 48px;
    z-index: 5;
    transform: translate(-50%, -50%);
    max-width: 71rem;
    width: 85%;
    min-height: 67.4rem;
    // overflow: auto;
    // height: 29.5rem;
    background-color: #fff;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25),
      4px 0px 4px 0px rgba(0, 0, 0, 0.25);
      display: flex;
      flex-direction: column;
      text-align: center;

      @media (max-width: 768px) {
        min-height: 324px;
        padding: 24px;
      }


      &__svg {
        width: 100%;
        display: flex;
        justify-content: end;
        margin-bottom: 2.4rem;

        svg {
          cursor: pointer;
        }
      }

      &__title {
        font-size: 3.2rem;
        font-weight: 700;
        color: #1a1a1a;
        margin-bottom: 1.6rem;

        @media (max-width: 768px) {
          font-size: 1.6rem;
        }

      }
      &__subtitle {
        font-size: 1.6rem;
        font-weight: 400;
        color: #1a1a1a;
        width: 45ch;
        margin: 0 auto;

        @media (max-width: 768px) {
          font-size: 1.2rem;
          width: unset;
        }
      }

      .otp_box_group {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
        // background-color: yellow;
        grid-column-gap: 32px;
        margin: auto;
        margin-top: 7.2rem;
        margin-bottom: 7.4rem;
        width: fit-content;

        @media (max-width: 768px) {
          grid-column-gap: 16px;
          margin-top: 2.4rem;
          margin-bottom: 3.6rem;
        }

        .otp_box {
          width: 5.7rem;
          height: 5.7rem;

          @media (max-width: 768px) {
            width: 3.2rem;
            height: 3.2rem;
            border-radius: 6px;
          }

          // background-color: yellow;

          input {
            width: 5.7rem !important;
            height: 5.7rem !important;
            height: auto;
            // background-color: black;
            outline: none;
            border: none;
            border: 1px solid;
            border-color: linear-gradient(
              116.29deg,
              #006633 0.76%,
              #00aed9 100%
            );
            border-radius: 6px;
            font-size: 32px;
            text-align: center;
            color: #006633;

            @media (max-width: 768px) {
              width: 3.2rem !important;
              height: 3.2rem !important;
              border-radius: 6px;
              font-size: 12px !important;
            }
  
          }
        }
      }
      .password_modal_submit {
        width: 25.4rem;
        height: 8rem;
        border-radius: 12px;
        background: linear-gradient(116.29deg, #006633 0.76%, #00aed9 100%);
        display: flex;
        align-items: center;
        justify-content: center;
        border: none;
        outline: none;
        color: #ffffff;
        font-size: 1.4rem;
        font-weight: 700;
        cursor: pointer;
        transition: 0.7s;
        margin: 0 auto;

        @media (max-width: 768px) {
          height: 6rem;
        }
  
        & > div {
          margin-right: 12px;
          color: #ffffff;
        }
  
        &:hover {
          transition: 0.7s;
  
          background: none;
          color: #006633;
          border: 1px solid #006633;
  
          & > div {
            color: #006633;
          }
          
        }
      }

    }

  }