.ndclist {
  display: flex;

  .sidebar_container {
    @media (max-width: 1024px) {
      display: none;
    }
  }

  .ndclist_main {
    position: relative;
    width: 1440px;
    max-width: 100%;
    padding: 44px;
    height: 100vh;
    overflow: auto;
    // margin: auto;

    &::-webkit-scrollbar {
      display: none;
    }

    @media (max-width: 565px) {
      padding: 24px;
    }

    .ndclist_title {
      font-size: 2.4rem;
      font-weight: 700;
      color: #1a1a1a;
      margin-bottom: 2.4rem;
    }

    .ndclist-table {
      width: 100%;
      height: 1200px;
      border: 1px solid #efefef;
      border-radius: 12px;
      position: relative;

      @media (max-width: 565px) {
        overflow-x: auto;
      }

      &__header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0 24px;
        height: 64px;
        border-bottom: 1px solid #efefef;
        background: #f9fafb;
        border-radius: 12px 12px 0px 0px;

        @media (max-width: 565px) {
          width: 600px;
        }

        & > :first-child {
          color: #1a1a1a;
          font-size: 1.4rem;
          font-weight: 500;
        }

        & > :last-child {
          display: flex;
          align-items: center;
          gap: 8px;
        }
        &__select {
          min-width: 77px;
          height: 32px;
          background: #fff;
          border-radius: 4px;
          display: flex;
          align-items: center;
          justify-content: space-between;
          color: #2c2c2c;
          font-size: 0.8rem;
          font-weight: 400;
          padding: 0 12px;

          svg {
            margin-right: 8px;
          }

          & > :last-child {
            margin-left: 8px;
            margin-right: 0;
          }
        }
      }

      &__label {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
        color: #1a1a1a;
        align-items: center;
        font-size: 1.2rem;
        font-weight: 500;
        height: 44px;
        padding: 0 24px;
        border-bottom: 1px solid rgba(177, 177, 177, 0.4);
        background: #fafafa;

        @media (max-width: 565px) {
          width: 600px;
        }
      }
      &__item {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
        align-items: center;
        height: 72px;
        border-bottom: 1px solid #efefef;
        background: #fff;
        padding: 0 24px;

        @media (max-width: 565px) {
          width: 600px;
        }
        &__title {
          color: #0f973d;
          font-size: 1.4rem;
          font-weight: 500;
        }
        &__subtitle {
          color: #1a1a1a;
          font-size: 1.4rem;
          font-weight: 500;
        }
        &__tags {
          display: flex;
          align-items: center;
          gap: 12px;

          & > div {
            color: #444;
            font-size: 0.8rem;
            font-weight: 400;
            padding: 0 4px;
            border-radius: 4px;
            height: 24px;
            display: flex;
            align-items: center;
            justify-content: center;
          }

          &__green {
            background: #91d6a8;
          }
          &__non-green {
            background: #FCB59A;
          }
        }

        &__status {
          height: 2.4rem;
          border-radius: 4px;
          display: flex;
          align-items: center;
          width: fit-content;
          gap: 12px;
          padding: 8px 10px 8px 8px;
          border: 1px solid #999;
          color: #475467;
          font-size: 1.4rem;
          font-weight: 500;

          &__active {
            width: 8px;
            height: 8px;
            border-radius: 50%;
            background: #0f973d;
          }
          &__inactive {
            width: 8px;
            height: 8px;
            border-radius: 50%;
            background: #d42620;
          }
        }
      }

      &__item-inactive {
        background: #fafafa;
      }

      .ndclist-modal {
        width: 204px;
        height: 276px;
        background: #e7f6ec;
        position: absolute;
        top: 52px;
        right: 0;
        border-radius: 4px 0px 0px 4px;
        border: 1px solid #efefef;
        z-index: 2;

        &__search {
          width: 100%;
          height: 32px;
          background: #fff;
          display: grid;
          grid-template-columns: 16px 1fr;
          align-items: center;
          gap: 8px;
          color: #898989;
          font-size: 1.2rem;
          font-weight: 400;
          padding: 0 8px;
          margin-top: 12px;

          svg {
            width: 100%;
            height: 16px;
          }

          input {
            border: none;
            outline: none;
            width: 100%;
          }
        }

        &__list {
          height: 232px;
          overflow-y: auto;
          padding-left: 30px;

          &::-webkit-scrollbar {
            display: none;
          }

          &__flex {
            display: flex;
            height: 3.1rem;
            color: #1a1a1a;
            align-items: center;
            margin-top: 2rem;
            font-size: 1.2rem;
            font-weight: 400;
            gap: 12px;
            margin-bottom: 1.2rem;
            padding: 0 8px;
            border-radius: 4px 0px 0px 4px;
            cursor: pointer;

            &:hover {
              background: #FFF;
              color: #1A1A1A;
            }

            input {
              width: 16px;
              height: 16px;
              border-radius: 4px;
            }
          }
        }
      }

      .adminndclist-modal {
        // right: 100px;
      }

      .calendar-modal {
        min-width: 28.7rem;
        min-height: 28.7rem;
        background: #efefef;
        position: absolute;
        top: 52px;
        right: 0;
        border-radius: 4px 0px 0px 4px;
        border: 1px solid #efefef;
        background: #fff;
        padding: 24px 12px;

        .ndc-calendar-row {
          display: grid;
          grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
          // gap: 24px;
          align-items: center;
          justify-content: center;

          & > div {
            // background: green;
            width: fit-content;
            display: flex;
            align-items: center;
            justify-content: center;
            align-self: center;
            justify-self: center;
            margin-bottom: 16px;
            font-size: 12px;
            font-weight: 500;
            width: 100%;
            height: 20px;
            color: #1a1a1a;
          }
          .ndc-calendar-date {
            cursor: pointer;
          }
          .ndc-calendar-date:hover {
            background: #00aed9;
            color: #fff;
            border-radius: 50%;
            width: 20px;
          }

          .active-date {
            background: #00aed9;
            color: #fff;
            border-radius: 50%;
            width: 20px;
          }

          .subsidiary-date {
            background: #e3effc;
          }
        }

        .calendar-header {
          & > div {
            color: #1a1a1a;
          }
        }

        &__flex {
          display: flex;
          align-items: center;
          justify-content: space-between;
          color: #1a1a1a;
          font-size: 12px;
          font-weight: 500;
          margin-bottom: 1.6rem;

          & > div {
            display: flex;
            align-items: center;
            gap: 16px;
          }
        }

        &__button-group {
          display: flex;
          align-items: center;
          gap: 8px;
          justify-content: end;
          button {
            width: 7.2rem;
            height: 3.2rem;
            outline: none;
            border: none;
            border-radius: 6px;
            background: linear-gradient(116.29deg, #006633 0.76%, #02a4cc 100%);
            font-size: 10px;
            font-weight: 600;
            color: #fff;
            display: flex;
            align-items: center;
            justify-content: center;
          }

          & > :first-child {
            border: 1px solid  #667185;
            color: #1a1a1a;
            background: transparent;
          }
        }
      }

      .ndclist-filter-modal {
        width: 108px;
        height: 112px;
        background: #e7f6ec;
        position: absolute;
        top: 52px;
        right: 0;
        border-radius: 4px 0px 0px 4px;
        border: 1px solid #efefef;
        z-index: 2;

        &__flex {
          display: flex;
          height: 3.1rem;
          color: #1a1a1a;
          align-items: center;
          margin-top: 2rem;
          font-size: 1.2rem;
          font-weight: 400;
          gap: 12px;
          margin-bottom: 1.2rem;
          padding: 0 8px;
          border-radius: 4px 0px 0px 4px;
          cursor: pointer;

          &:hover {
            background: #FFF;
            color: #1A1A1A;
          }

          input {
            width: 16px;
            height: 16px;
            border-radius: 4px;
          }
        }
      }
    }
  }
}
