.suggestion {
  display: flex;

  .sidebar_container {
    // background-color: yellow;
    //  height: 100%;
    max-height: 700px;
    position: relative;

    @media (max-width: 1024px) {
      display: none;
    }
  }

  .suggestion_main {
    max-width: 1440px;
    width: 100%;
    margin: auto;
    height: 100vh;
    background-color: #fff;
    padding: 44px;
    overflow-x: auto;

    @media (max-width: 565px) {
      padding: 24px;
    }

    .suggestion_form {
      max-width: 812px;
      width: 100%;
      display: flex;
      flex-direction: column;

      label {
        font-size: 1.6rem;
        font-weight: 400;
        color: #141414;
        margin-bottom: 1.6rem;

        @media (max-width: 565px) {
          font-size: 1.2rem;
          margin-bottom: 1.2rem;
        }
      }

      .suggestion_input {
        width: 100%;
        height: 7.3rem;
        border-radius: 12px;
        border: 1px solid #E5E5E5;
        margin-bottom: 3.2rem;
        padding-left: 24px;
        outline: none;

        @media (max-width: 565px) {
          height: 5.9rem;
        }
      }

      textarea {
        border-radius: 12px;
        padding-top: 24px;
        padding-left: 24px;
        outline: none;
        height: 30.3rem;
        border: 1px solid #E5E5E5;
      }

      button {
        width: 100%;
        height: 72px;
        border-radius: 12px;
        background: linear-gradient(116.29deg, #006633 0.76%, #00aed9 100%);
        display: flex;
        align-items: center;
        justify-content: center;
        border: none;
        outline: none;
        color: #ffffff;
        font-size: 1.6rem;
        font-weight: 600;
        cursor: pointer;
        margin-top: 5.2rem;
        transition: 0.7s;

        @media (max-width: 768px) {
          margin-top: 4rem;
        }

        &:hover {
          transition: 0.7s;

          background: none;
          color: #006633;
          border: 1px solid #006633;
        }
      }
    }
  }
}
