header {
  width: 100%;
  // background: linear-gradient(
  //   90deg,
  //   rgba(255, 255, 255, 0.5) 0.88%,
  //   rgba(255, 255, 255, 0.2) 100%
  // );
  box-shadow: 0px 20px 40px 0px rgba(0, 0, 0, 0.1);
  // backdrop-filter: blur(10px);
  background: #FFF;
  position: fixed;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  min-height: 6.4rem;
  z-index: 4 !important;
  .header_inner {
    max-width: 1440px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: auto;
    padding: 0 144px;
    .logo {
      cursor: pointer;
      img {
        width: 6.363rem;
        height: 6.138rem;
      }
    }

    .menu {
      display: flex;
      align-items: start;
      font-size: 1.6rem;
      font-weight: 500;
      color: #898989;

      & > div {
        margin-right: 4.2rem;
        height: 2.8rem;
        position: relative;
        cursor: pointer;
        position: relative;
        // background-color: green;

        &:hover {
          color: #063 !important;
      }

        .active_bar {
          width: 1.2rem;
          height: 0.4rem;
          border-radius: 4px;
          background-color: #063;
          position: absolute;
          bottom: 0;
          right: 50%;
          transform: translateX(50%);
        }

        svg {
          margin-left: 0.6rem;
        }

        .menuVisible {
          display: flex !important;
          flex-direction: column;
        }
      }
      & > :last-child {
        margin-right: 0;
      }
      .active {
        color: #063;
      }
    }

    .contact {
      display: flex;
      align-items: center;
      // height: 4rem;
      .header_contact {
        display: flex;
        align-items: center;
        background-color: #063;
        border: none;
        outline: none;
        border-radius: 12px;
        color: #fff;
        padding: 12px 18px;
        transition: 0.4s;

        &:hover {
          background-color: #0d924f;
        }

        svg {
          margin-left: 1rem;
        }
      }
      .header_login {
        background-color: transparent;
        outline: none;
        border: none;
        color: #898989;
        width: 7.5rem;
        // height: 4.3rem;
        margin-right: 1.6rem;
        font-size: 1.6rem;
        font-weight: 500;
        &:hover {
          color: #063;
          transition: 0.4s;
        }
      }
    }

    .hamburger {
      display: none;
    }
  }

  .mobileNav {
    width: 100%;
    height: 100vh !important;
    height: fit-content;
    background-color: #FFF;
    position: fixed;
    top: 0;
    right: -2000px;
    opacity: 0;
  
    color: #141414;
    padding: 32px 16px;
  
    font-size: 1.6rem;
    font-weight: 400;
    transition: 0.7s;
    z-index: 25;
  
  //     clip-path: polygon(0 100%, 100% 100%, 100% 100%, 0% 100%);
  // display: none;
  
    & > div {
      margin-bottom: 2.4rem;
    }

    .mobileNav_svg_container {
      width: 100%;
      display: flex;
      justify-content: end;
      margin-bottom: 6.4rem;
      
    }

    button {
      display: flex;
      align-items: center;
      background-color: #063;
      border: none;
      outline: none;
      border-radius: 12px;
      color: #fff;
      padding: 12px 18px;
      transition: 0.4s;

      &:hover {
        background-color: #0d924f;
      }

      svg {
        margin-left: 1rem;
      }
    }
  }

  .mobileNav_active{
    right: 0 !important;
    opacity: 1;

  }
}

.headerDivider {
  margin-bottom: 6.4rem;
}


@media (max-width: 1053px) {
  header {
    .header_inner {
      padding: 0 18px;
      .menu {
        display: none;
      }
      .contact {
        display: none;
      }

      .hamburger {
        display: initial;
      }
    }
  }
}


