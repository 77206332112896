.not_found {
    width: 100%;
    max-width: 1440px;
    padding: 64px 144px 91px 144px;
    margin: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .not_found-title{
        font-size: 2.4rem;
        font-weight: 500;
        text-align: center;
        margin-top: 6.4rem;
    }

    .not_found-button {
        margin-top: 1.6rem;
        background: transparent;
        outline: none;
        padding: 12px 18px;
        border-radius: 8px;
        border: 1px solid #063;
        display: flex;
        align-items: center;
        font-size: 1.2rem;
        color: #063;
        font-weight: 500;
        transition: 0.4s;

        svg {
            margin-left: 8px;
        }

        &:hover {
            background-color: #B1B1B1;
            border-radius: 12px;
            border: none;
            
        }
    }
}

@media (max-width: 1200px) {
    .not_found {
        padding: 32px 16px 190px 16px;
        .not_found_svg {
            width: 35.8rem;
            height: 23.3673rem;
        }

        .not_found-title {
            font-size: 1.6rem;
            margin-top: 3.2rem;
        }
        .not_found-button {

        }
    }
}