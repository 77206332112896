.admin_iot {
  display: flex;

  .sidebar_container {
    // background-color: yellow;
    //  height: 100%;
    max-height: 700px;
    position: relative;

    @media (max-width: 1024px) {
      display: none;
    }
  }


  .main_container {
    max-width: 1800px;
      width: 100%;
      margin: auto;
    height: 100vh;
    overflow: auto;
    padding: 40px;

    @media (max-width: 565px) {
      padding: 24px;
    }
    .main {
      width: 100%;
      padding: 20px 0;
      height: 100vh;
      overflow: auto;

      @media (max-width: 565px) {
        padding: 16px;
      }

      &::-webkit-scrollbar {
        display: none;
      }

      .title {
        font-size: 2.4rem;
        font-weight: 700;
        color: #1a1a1a;
        margin-bottom: 2.4rem;
      }
    }
  }

  .tab_label_container {
    width: 82.7rem;
    border-bottom: 1px solid #D0D5DD;
    display: flex !important;
    justify-content: space-between !important;

    @media (max-width: 768px) {
      width: fit-content;
    }

    .tab_label {
        width: 27.5rem;
        text-transform: none;
        font-size: 1.8rem;
        font-weight: 700;
        color: #1a1a1a;
        font-family: "Inter" !important;
        color: rgba(208, 213, 221, 1);
        // background-color: tomato;

        @media (max-width: 768px) {
          font-size: 1.2rem;
          width: fit-content
        }
        
    }
    
    .tab_active {
      color:rgba(0, 174, 217, 1) ;
    }
    .last_tab {
      margin-left: 15rem;

      @media (max-width: 768px) {
        margin-left: 0;
      }
    }
}


  .iot_table_container {
    background: rgba(133, 215, 114, 0.4);
    padding: 24px;
    border-radius: 12px;
    overflow: auto;
    height: 58rem;

    @media (max-width: 768px) {
      height: 80vh;
    }

    &::-webkit-scrollbar {
      display: none;
    }

    .iot_table_header {
      width: 100%;
      display: grid;
      // grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
      grid-template-columns: 1fr 1fr 1fr 1fr;
      grid-column-gap: 3rem;
      border-radius: 12px;
      background: #fff;
      height: 4.9rem;
      align-items: center;
      text-align: center;
      font-size: 1.2rem;
      font-weight: 500;
      color: #1a1a1a;
      padding: 0 120px;
      gap: 64px;

      
      @media (max-width: 768px) {
        padding: 0 16px;
        gap: 16px;

      }

      @media (max-width: 565px) {
        font-size: 0.8rem;
        height: 4rem;
      }

      & > div {
        width: 100%;
        width: fit-content;
        justify-self: center;
      }
    }
    .iot_table_body {
      width: 100%;
      display: grid;
      // grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
      grid-template-columns: 1fr 1fr 1fr 1fr;
      grid-column-gap: 3rem;
      border-radius: 12px;
      background: #fff;
      height: 4.9rem;
      align-items: center;
      text-align: center;
      margin-top: 1.3rem;
      font-size: 1.2rem;
      font-weight: 500;
      color: #1a1a1a;
      cursor: pointer;
      padding: 0 120px;
      gap: 64px;

      @media (max-width: 768px) {
        padding: 0 16px;
        gap: 16px;

      }

      @media (max-width: 565px) {
        font-size: 0.8rem;
        height: 4rem;
      }

      & > div {
        width: 100%;
        justify-self: center;
        word-wrap: break-word;
        word-break: break-all;

        .ping {
          width: 12px;
          height: 12px;
          margin: auto;
          border-radius: 50%;
          cursor: pointer;
        }

        .ping-active {
          background-color: #099137;

        }

        .ping-inactive {
          background-color: #D42620;

        }
      }
    }

    .blockchain {
      grid-template-columns: 1fr 1fr 1fr 1fr;

      @media (max-width: 565px) {
        .address {
        font-size: 6px;
      }
      }
    }
  }
}
