.template-submission {
    display: flex;
  
    .main {
    //   padding: 24px 0 24px 24px;
      height: 100vh;
      overflow: auto;
      width: 100%;
      .main_inner {
        max-width: 1800px;
        width: 100%;
        margin: auto;
          padding: 44px;
          overflow: auto;
        //   border-radius: 24px;
        //   background: rgba(133, 215, 114, 0.4);
  
         svg {
            margin-bottom: 2.4rem;
         }
          
          &::-webkit-scrollbar {
              display: none;
            }
      
          .input_container {
            // width: fit-content;
            // min-width: 79.3rem;
            display: grid;
            grid-template-columns: 235px 800px;
            grid-column-gap: 100px;
            align-items: center;
            font-size: 1.4rem;
            margin-bottom: 2.4rem;
            color: #1a1a1a;
            padding-left: 35px;
            // background-color: yellow;
  
            & > :first-child {
              white-space: nowrap;
            }
            input {
              width: 52.8rem;
              height: 5.9rem;
              border: 1px solid #E5E5E5;
              border-radius: 12px;
              padding-left: 24px;
              background: transparent;
            }
            textarea {
              width: 52.8rem;
              height: 11.4rem;
              border: 1px solid #E5E5E5;
              border-radius: 12px;
              padding: 24px;
            }
      
            .document {
              display: flex;
              align-items: center;
              justify-content: space-between;
              width: 52.8rem;
              height: 5.9rem;
              border: 1px solid #E5E5E5;
              border-radius: 12px;
              padding: 0 24px;
              cursor: pointer;
            }
      
            .input_group {
              width: 52.8rem;
              display: grid;
              grid-template-columns: 1fr 2.4fr;
              grid-column-gap: 12px;
      
              input {
                width: 100% !important;
              }
            }
            .input_flex {
              display: flex;
              align-items: center;
      
              .input,
              input {
                margin-right: 2.4rem;
              }
      
              .button_group {
                display: flex;
                align-items: center;
      
                .transparent {
                  background: transparent;
                  border: 0.5px solid #063;
                  border-radius: 8px;
                  color: rgba(2, 164, 204, 0.4);
                  min-width: 11.8rem;
                  padding: 0 16px;
                  height: 4rem;
                  outline: none;
                  margin-right: 1.2rem;
                  white-space: nowrap;
  
                }
      
                .filled {
                  border-radius: 8px;
                  background: linear-gradient(116deg, #063 0.76%, #02a4cc 100%);
                  height: 4rem;
                  min-width: 11.8rem;
                  padding: 0 16px;
                  outline: none;
                  border: none;
                  color: #fff;
                  white-space: nowrap;
                }
              }
            }
            .sensor_group {
              width: 52.8rem;
              display: flex;
              align-items: center;
              overflow: auto;
      
              &::-webkit-scrollbar {
                display: none;
              }
      
              .input {
                width: fit-content;
                margin-right: 1.2rem;
                border: 0.5px solid #667185;
                border-radius: 12px;
                background: transparent;
                padding: 20px 24px;
                display: flex;
                align-items: center;
                justify-content: center;
                white-space: nowrap;
  
              }
            }
          }
        }
    }
  }
  