.thirdparty_validation {
  display: flex;

  .sidebar_container {
    // background-color: yellow;
    //  height: 100%;
    max-height: 700px;
    position: relative;

    @media (max-width: 1024px) {
      display: none;
    }
  }

  .main {
    max-width: 1440px;
    width: 100%;
    margin: auto;
    padding: 44px;
    height: 100vh;
    overflow: auto;

    @media (max-width: 565px) {
      padding: 24px;
    }
    .title {
      font-size: 2.4rem;
      font-weight: 700;
      color: #1a1a1a;
      margin-bottom: 24px;

      @media (max-width: 565px) {
        text-align: center;
        font-size: 1.4rem;
        margin-bottom: 0.4rem;
      }
    }

    .empty_list {
      font-size: 3.2rem;
      font-weight: 500;
      text-align: center;
      color: #063;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      @media (max-width: 565px) {
        font-size: 1.6rem;
      }

      img {
        width: 50rem;
        height: auto;
        margin-bottom: 2.4rem;

        @media (max-width: 565px) {
          width: 35rem;
        }
      }
    }

    .category {
      font-size: 1.6rem;
      font-weight: 700;
      color: #1a1a1a;
      margin-top: 4rem;
      margin-bottom: 1rem;

      @media (max-width: 565px) {
        font-size: 1.2rem;
        margin-bottom: 0.8rem;
        margin-top: 1.2rem;
      }
    }

    .validation_card {
      padding: 24px;
      border-radius: 16px;
      border: 1px solid #E5E5E5;
      margin-bottom: 1.2rem;

      @media (max-width: 565px) {
        padding: 12px;
      }

      .validation_grid {
        // backgsround-color: yellow;
        display: grid;
        align-items: start;
        // grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
        grid-template-columns: 1fr 1fr 1fr;
        grid-column-gap: 24px;
        margin-bottom: 32px;

        @media (max-width: 768px) {
          overflow-x: auto;
        }

        & > div {
          // width: fit-content;
          & > :first-child {
            font-size: 1.4rem;
            color: #1a1a1a;
            margin-bottom: 12px;
            white-space: nowrap;

            @media (max-width: 768px) {
              font-size: 1.2rem;
              white-space: nowrap;
            }
            // @media (max-width: 565px) {
            //   font-size: 0.8rem;
            //   margin-bottom: 0.8rem;
            // }
          }
          & > :last-child {
            font-size: 14px;
            color: #98a2b3;
            font-weight: 700;
            white-space: wrap;
            word-break: break-all;
            word-wrap: break-word;
            @media (max-width: 768px) {
              font-size: 1.2rem;
            }
          }
        }
      }

      .confirm {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;

        & > :first-child {
          font-size: 1.6rem;
          font-weight: 500;
          color: #1a1a1a;

          @media (max-width: 768px) {
            font-size: 1.2rem;
          }
        }
        & > :last-child {
          width: 40rem;
          display: flex;
          justify-content: space-between;
          align-items: center;
          outline: none;
          @media (max-width: 768px) {
            width: 24.8rem;
          }
          @media (max-width: 565px) {
            width: 12.8rem;
          }
          button {
            width: 19.4rem;
            height: 5.9rem;
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 12px;
            font-size: 1.6rem;
            font-weight: 600;

            @media (max-width: 768px) {
              height: 3.6rem;
              font-size: 1.2rem;
              width: 10rem;
            }

            // @media (max-width: 565px) {
            //   width: 6rem;
            //   height: 2.2rem;
            //   border-radius: 6px;
            //   font-size: 1rem;
            // }
          }

          & > :first-child {
            border: 0.5px solid #063;
            background: linear-gradient(
              116deg,
              rgba(0, 102, 51, 0.4) 0.76%,
              rgba(0, 174, 217, 0.4) 100%
            );
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
          }
          & > :last-child {
            background: linear-gradient(116deg, #063 0.76%, #02a4cc 100%);
            color: #fff;
            border: none;
          }
        }
      }

      .log {
        font-size: 1.2rem;
        margin-top: 2.4rem;

        @media (max-width: 768px) {
          font-size: 1rem;
        }
        @media (max-width: 565px) {
          font-size: 0.6rem;
          margin-top: 1.6rem;
        }

        span {
          font-weight: 700;
          color: #02a4cc;
        }
      }
    }

    .modal {
      width: 100%;
      height: 100vh;
      position: absolute;
      top: 0;
      left: 0;
      background: rgba(0, 0, 0, 0.7);
      z-index: 1;
      display: flex;
      align-items: center;
      justify-content: center;
      ::-webkit-scrollbar {
        display: none;
      }

      .modal_inner {
        width: 562px;
        // height: 433px;
        max-width: 100%;
        background-color: #fff;
        box-shadow: -24px 0px 12px 0px rgba(102, 113, 133, 0.35),
          0px 16px 6px 0px rgba(0, 0, 0, 0.25);
        border-radius: 24px;
        max-height: 96%;
        overflow: auto;

        padding: 32px;
        .title {
          font-size: 3.2rem;
          font-weight: 700;
          color: #1a1a1a;
          margin-bottom: 5px;
        }

        .subtitle {
          color: #667185;
        }

        .modal_grid {
          margin-top: 4.4rem;
          display: grid;
          grid-template-columns: 1fr;
          grid-column-gap: 12px;
          grid-row-gap: 22px;

          @media (max-width: 565px) {
            grid-template-columns: 1fr;
          }

          .grid_item {
            width: 100%;
            .label {
              font-size: 1.6rem;
              font-weight: 700;
              color: #1a1a1a;
              margin-bottom: 1.2rem;

              @media (max-width: 565px) {
                font-size: 1.2rem;
              }
            }
            .document {
              width: 100%;
              border-radius: 12px;
              border: 1px solid #E5E5E5;
              color: #667185;
              padding: 24px;
              font-size: 12px;
              display: flex;
              align-items: center;
              justify-content: space-between;
            }
          }
        }

        .button_group {
          display: flex;
          align-items: center;
          margin: auto;
          width: fit-content;
          margin-top: 6rem;

          button {
            width: 247px;
            height: 69px;
            display: flex;
            align-items: center;
            justify-content: center;
            border: none;
            outline: none;
            color: #fff;
            border-radius: 12px;
            font-size: 2.7rem;
            font-weight: 600;

            @media (max-width: 565px) {
              width: fit-content;
              padding: 0 24px;
              font-size: 1.8rem;
            }
          }

          & > :first-child {
            background: linear-gradient(
              116.29deg,
              rgba(0, 102, 51, 0.4) 0.76%,
              rgba(0, 174, 217, 0.4) 100%
            );

            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
          }
          & > :last-child {
            background: linear-gradient(116.29deg, #006633 0.76%, #02a4cc 100%);
          }
        }
      }
    }

    .reject-modal {
      width: 100%;
      height: 100vh;
      position: absolute;
      top: 0;
      left: 0;
      background: rgba(0, 0, 0, 0.7);
      z-index: 7;
      display: flex;
      align-items: center;
      justify-content: center;
      ::-webkit-scrollbar {
        display: none;
      }

      &__inner {
        width: 989px;
        height: 549px;
        overflow-y: auto;
        max-width: 100%;
        background-color: #fff;
        box-shadow: -24px 0px 12px 0px rgba(102, 113, 133, 0.35),
          0px 16px 6px 0px rgba(0, 0, 0, 0.25);
        border-radius: 24px;
        max-height: 96%;
        overflow: auto;

        padding: 32px;
        &__title {
          font-size: 3.2rem;
          font-weight: 700;
          color: #1a1a1a;
          margin-bottom: 3.2rem;
        }

        &__subtitle {
          color: #1a1a1a;
          font-size: 1.8rem;
          font-style: normal;
          font-weight: 500;
          margin-bottom: 2.4rem;
        }

        &__field {
          width: 100%;
          height: 60px;
          border-radius: 12px;
          margin-bottom: 12px;
        }

        &__flex {
          display: flex;
          align-items: center;
          gap: 24px;
          font-size: 1.8rem;
          font-weight: 600;
          margin-bottom: 8px;
          & > input  {
            width: 24px;
            height: 24px;
            border-radius: 16px;

          }
        }

        .quill > .ql-container > .ql-editor.ql-blank::before{
          font-style: normal;
      }

        &__button {
          width: 100%;
          height: 69px;
          display: flex;
          align-items: center;
          justify-content: center;
          border: none;
          outline: none;
          color: #fff;
          border-radius: 12px;
          font-size: 1.6rem;
          font-weight: 600;
          background: linear-gradient(116.29deg, #006633 0.76%, #02a4cc 100%);
margin-top: 7rem;
          @media (max-width: 565px) {
            margin-top: 9rem;

            // width: fit-content;
            padding: 0 24px;
            font-size: 1.8rem;
          }
        }
      }
    }

    .tab_label_container {
      width: 778px;
      //   background-color: teal;
      border-bottom: 1px solid #d0d5dd;
      margin-bottom: 31.65px;

      @media (max-width: 768px) {
        width: 100%;
        margin-left: 0;
      }

      .tab-label-content {
        width: 389px;
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 9px;
        font-size: 1.8rem;
        font-weight: 700;
        font-family: "Inter";
        color: #d0d5dd;
        text-transform: capitalize;

        @media (max-width: 768px) {
          width: 50%;
          font-size: 1.6rem;
          white-space: nowrap;
        }
  

        &__count {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 24px;
          height: 24px;
          border-radius: 50%;
          background: #98A2B3;
          color: #FFF;
font-size: 16px;
font-weight: 500;
        }
      }
     

      .tabs {
        display: flex;
        align-items: center;
        width: 100%;
      }

      .tab_active {
        .tab-label-content {
          color: #063;

          &__count {
            background: #063;
          }
        }
      }
            .MuiTabs-indicator {
        background-color: #063;
      }
    }

    .validation-table {
      width: 100%;
      max-width: 996px;
      min-height: 1200px;
      border: 1px solid #efefef;
      border-radius: 12px;
      position: relative;
      padding: 0 10px;
      background: #F9FAFB;
  
      &__header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0 24px;
        height: 64px;
        border-bottom: 1px solid #efefef;
        background: #f9fafb;
        border-radius: 12px 12px 0px 0px;
        margin-bottom: 2rem;
  
        & > :first-child {
          display: flex;
          align-items: center;
          gap: 10px;
          background: #f0f2f5;
          border-radius: 8px;
          padding: 0 4px;
          height: 50px;
  
          button {
            min-width: 10.3rem;
            height: 4.2rem;
            outline: none;
            border-radius: 8px;
            background: none;
            border: none;
            color: #898989;
            font-size: 1.8rem;
            font-weight: 400;
          }
  
          .active {
            border: 1px solid #063;
            background: #fff;
            box-shadow: -2px 2px 2px 0px rgba(0, 102, 51, 0.05);
          }
  
          & > div {
            width: 1px;
            height: 19px;
            background: #e4e7ec;
          }
        }
  
        & > :last-child {
          button {
            min-width: 10.3rem;
            height: 4.2rem;
            outline: none;
            border-radius: 8px;
            border: none;
            color: #98A2B3;
            font-size: 1.4rem;
            font-weight: 400;
            background: #f0f2f5;
          }
  
          .active {
            border: 1px solid #063;
            background: #fff;
            box-shadow: -2px 2px 2px 0px rgba(0, 102, 51, 0.05);
            color: #1A1A1A;
          }
        }
      }
  
    }
  }

  
}
