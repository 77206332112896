.sidebar{
    width: 24.4rem;
    height: 100vh;
    // padding: 65px 0;
    // max-height: 700px;
    // background-color: pink;
    background-color: #fafafa;
    overflow: auto;

    // display: flex;
    // flex-direction: column;
    // justify-content: center;
    padding-left: 3.2rem;
    padding-top: 6.5rem;
    padding-bottom: 6.5rem;
    font-size: 16px;

    &::-webkit-scrollbar {
        display: none;
    }

    @media (max-width: 1024px) {
        justify-content: space-around;
    }
    
    .logo_container {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;


        @media (max-width: 1024px) {
            display: none;
        }
        .logo {
            width: 8.3rem;
            height: 8.077rem;
            margin: auto !important;
            margin-left: -1.6rem !important;
            // background-color: gray;
        }
    }

    &__ipad-pro {
        display: none;

        @media (max-width: 1024px) {
            display: initial;
        }
    }

    .menu {
        margin-top: 7.5rem;

        margin-bottom: 12.6rem;
    }
    
    .menu_item {
        display: flex;
        align-items: center;
        margin-bottom: 43px;
        cursor: pointer;
        position: relative;
        height: 40px;

        @media (max-width: 768px) {
            margin-bottom: 4.8rem;
            font-size: 1.2rem;
        }
        
       
        & > div {
             color: #98A2B3;
        }

        svg {
            margin-right: 1.2rem;

            @media (max-width: 768px) {
                width: 16px;
            }
        }

        .sidebar_tag {
            position: absolute;
            width: 250px;
            height: 62px;
            background-color: #fff;
            top: 25px;
            left: 24px;
            border-radius: 6px;
            border: 1px solid #E5E5E5;
            font-size: 1.2rem;
            color: #1a1a1a;
            z-index: 3;
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 16px 24px;
          }
    }

    .active {
        background-color: #063;
        padding: 16px;
        border-radius: 8px 0 0 8px;
        height: 59px;
        
        & > div {
            color: #fff;
        }
        svg {
            display: unset;
            path {
                fill: white !important;
            }
        }
    }
    .active_iot {
        background-color: #063;
        padding: 16px;
        border-radius: 8px 0 0 8px;
        height: 59px;

        
        & > div {
            color: #fff;
        }
        svg {
            display: unset;
            path {
                fill: none !important;
                stroke: white !important;
            }
        }
    }
    
   
    .logout {
        display: flex;
        align-items: center;
       
        & > div {
             color: #98A2B3;
        }

        svg {
            margin-right: 1.2rem;
        }

        
    }

  
  
      
}

