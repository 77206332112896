.donate {
  &__hero {
    width: 100%;
    height: 487px;
    background: url("../../images/626265.png");
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 12px;

    &__title {
      color: var(--White, #fff);
      text-align: center;
      font-size: 6.4rem;
      font-weight: 700;
    }

    &__subtitle {
      width: 100%;
      max-width: 88ch;
      color: var(--White, #fff);
      text-align: center;
      font-size: 1.8rem;
      font-weight: 400;
      margin: 0 auto;
    }
  }

  &__card {
    width: 100%;
    max-width: 956px;
    margin: 0 auto;
    min-height: 1363px;
    border-radius: 12px;
    border: 2px solid #0d924f;
    margin-top: 64px;
    background: #fff;
    padding: 40px;

    @media (max-width: 565px) {
      padding: 40px 20px;
    }

    &__title {
      color: var(--Green-1, #0d924f);
      font-size: 3.2rem;
      font-weight: 500;
      margin-bottom: 48px;

      @media (max-width: 565px) {
        font-size: 2.4rem;
      }
    }

    &__subtitle {
      color: var(--Black, #141414);
      font-size: 3.6rem;
      font-weight: 400;
      display: flex;
      align-items: center;
      gap: 24px;
      margin-bottom: 16px;
    }

    &__subsubtitle {
      color: var(--Black-2, #3b3b3b);
      font-size: 2.4rem;
      font-weight: 400;
      margin-bottom: 14.5px;
    }

    &__check-group {
      display: flex;
      align-items: center;
      gap: 24px;
      margin-bottom: 40px;

      & > div {
        display: flex;
        align-items: center;
        gap: 6px;
        color: var(--Black-2, #3b3b3b);
        font-size: 1.6rem;
        font-weight: 400;

        @media (max-width: 565px) {
          font-size: 1.3rem;
        }
      }
    }

    &__amount {
      width: 100%;
      margin-bottom: 48px;


     &__inner {
        width: 100%;
        border-radius: 12px;
      border: 0.5px solid #b1b1b1;
      height: 60px;
      display: grid;
      grid-template-columns: 1fr 7fr;
        &__currency {
            width: 100%;
            height: 100%;
            background: #E7F6EC;
            position: relative;
    
            display: flex;
            justify-content: center;
            align-items: center;
            gap: 4px;
            border-radius: 12px 0 0 12px;
            color: var(--Black-2, #3B3B3B);
    font-size: 24px;
    font-weight: 500;
    cursor: pointer;


    &__info {
      position: absolute;
      top: 60px;
      width: 100%;
      display: flex;
      flex-direction: column;
      gap: 10px;
      padding: 10px 0 ;
      background: #FFF;
      border: 2px solid #E7F6EC;


      & > div {
        padding: 0 10px;
        height: 32px;
        display: flex;
        align-items: center;
      &:hover {
        background: #E7F6EC;
      }
      }
      
    }
    
          }

          & > input {
            width: 100%;
            height: 100%;
            color: var(--Grey, #898989);
    font-size: 2.4rem;
    font-weight: 400;
    border: none;
    padding-left: 16px;
    border-radius: 0 12px 12px 0;
          }
     } 

      &__info {
        width: 100%;
        min-height: 81px;
        border-radius: 0px 0px 12px 12px;
border: 1px solid #063;
background: var(--Success-75, #B5E3C4);
display: flex;
align-items: start;
gap: 26px;
padding: 15px 24px 15px 18px;

color: var(--Green-1, #0D924F);
font-size: 2.2rem;
font-weight: 400;
line-height: 120%; /* 26.4px */

& > svg {
    min-width: 24px;
}

& > div {
  & > span {
    font-weight: 500;
  }
}
      }

      
    }

    &__amount-active {
        .donate__card__amount__inner {
            border-radius: 12px 12px 0 0 !important;

            .donate__card__amount__inner__currency {
                border-radius: 12px 0 0 0;

            }
            
          & > input {
          
    border-radius: 0 12px 0 0;
          }
        }
    }

    &__input-grid {
        width: 100%;
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: 24px;
        width: 100%;
        margin-bottom: 26.5px;

        @media (max-width: 768px) {
          grid-template-columns: 1fr;
        }
        & > div {
            width: 100%;
            & > label {
                display: block;
                color: var(--Black, #141414);
    font-size: 1.6rem;
    font-weight: 500;
    margin-bottom: 12px;
            }

            & > input, & > select {
width: 100%;
height: 57px;
border-radius: 12px;
border: 0.5px solid var(--Grey-2, #B1B1B1);
padding-left: 14px;
            }
        }
    }

    &__single-grid {
        grid-template-columns: 1fr !important;
    }

    &__term {
        display: flex;
        align-items: center;
        gap: 12px;
        color: var(--Black-2, #3B3B3B);
font-size: 1.6rem;
font-weight: 400;

& > svg {
    cursor: pointer;
} 
& > div {
  & > span {
    color: #0D924F;
    font-weight: 500;
    cursor: pointer;

    &:hover {
        text-decoration: underline;
    }
}
}

    }

    &__button {
width: 100%;
max-width: 364px;
height: 80px;
border-radius: 12px;
background: #0D924F;
color: var(--White, #FFF);
font-size: 1.6rem;
font-weight: 500;
border: none;
margin: auto;
display: flex;
align-items: center;
justify-content: center;
margin-top: 78.5px;
    }
  }

  &__modal {
    width: 100%;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    background: rgba(0, 0, 0, 0.24);

    backdrop-filter: blur(4px);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 3;

    &__inner {
      width: 488px;
      height: 430px;
      background: #fff;
      border-radius: 12px;
      padding: 32px;
      display: flex;
      flex-direction: column;
      align-items: start;
      justify-content: center;

      &__close {
        width: 100%;
        display: flex;
        justify-content: end;
        height: fit-content;

        & > svg {
            cursor: pointer;
        }
      }

      &__logo {
        margin: auto;
        margin-top: 16px;
        margin-bottom: 24px;
      }
     
      &__title {
        color: var(--Green-1, #0D924F);
text-align: center;
margin: auto;
font-size: 2.4rem;
font-weight: 700;
margin-bottom: 12px;
      }
      &__subtitle {
        color: var(--Grey, #898989);
text-align: center;
font-size: 1.4rem;
font-weight: 400;
margin-bottom: 32px;
      }

      &__button {
        width: 100%;
        height: 64px;
        border-radius: 12px;
        border: none;
        color: var(--White, #FFF);
font-size: 1.6rem;
font-weight: 500;
margin-bottom: 12px;
background: #0D924F;
      }

      &__link {
        width: 100%;
        color: var(--Primary-color, #063);
        text-align: center;
        font-size: 12px;
        font-weight: 400;
        text-decoration-line: underline;
        margin: auto;
        text-align: center;
        cursor: pointer;
      }
    }
  }

  &__loading-modal {
    width: 100%;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    background: rgba(0, 0, 0, 0.54);
    z-index: 10;

    backdrop-filter: blur(4px);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 3;

    & > div {
      font-size: 32px;
      font-weight: 500;
      color: #fff;
    }
  }
}
