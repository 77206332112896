.thirdparty_home {
  display: flex;

  .sidebar_container {
    // background-color: yellow;
    //  height: 100%;
    max-height: 700px;
    position: relative;

    @media (max-width: 1024px) {
      display: none;
    }
  }

  .main {
    max-width: 1440px;
    width: 100%;
    margin: auto;
    padding: 44px;
    height: 100vh;
    overflow: auto;

    @media (max-width: 565px) {
      padding: 24px;
    }

    .title {
      font-size: 2.4rem;
      font-weight: 700;
      color: #1a1a1a;
      margin-bottom: 24px;

      @media (max-width: 565px) {
        font-size: 1.4rem;
        margin-bottom: 0.4rem;
      }
    }

    .empty_list {
      font-size: 3.2rem;
      font-weight: 500;
      text-align: center;
      color: #063;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      @media (max-width: 565px) {
        font-size: 1.6rem;
    }

      img {
          width: 50rem;
          height: auto;
          margin-bottom: 2.4rem;

          @media (max-width: 565px) {
            width: 35rem;
          }
      }
  }
    
  }


}
