.thirdparty-kyc_modal_container {
    width: 100%;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    background: rgba(0, 0, 0, 0.7);
    z-index: 2;

    .kyc_modal {
      ::-webkit-scrollbar {
        display: none;
      }

      width: 90%;
      max-width: 918px;

      height: 90vh;
      overflow: auto;
      margin: auto;
      background-color: #fff;
      // box-shadow: -24px 0px 12px 0px #66718559;
      box-shadow: 0px 16px 6px 0px #00000040;
      border-radius: 24px;
      position: fixed;
      top: 50%;
      left: 50%;
      padding: 32px;
      z-index: 2;

      transform: translate(-50%, -50%);

      .title {
        font-size: 32px;
        font-weight: 700;
        color: #282828;
        margin-bottom: 0;

        @media (max-width: 768px) {
          font-size: 2.4rem !important;
        }
      }

      .subtitle {
        margin-bottom: 4.3rem;

        @media (max-width: 768px) {
          margin-bottom: 2.4rem !important;
        }
      }

      .thirdparty_kyc_grid {
        // background-color: yellow;
        display: grid;
        grid-template-columns: 1fr;
        grid-column-gap: 24px;
        grid-row-gap: 42px;

        @media (max-width: 1024px) {
          grid-template-columns: 1fr;
        }
        @media (max-width: 768px) {
          grid-row-gap: 12px;
        }

        .thirdparty_kyc_input {
          color: #667185;
          border: 1px solid #E5E5E5;
          border-radius: 12px;
          height: 5.5rem;
          width: 100%;
          outline: none !important;
          font-size: 1.2rem;
          margin-bottom: 0.6rem;
          display: flex;
          align-items: center;
          padding-left: 24px;
          cursor: pointer;
          
        }

        .thirdparty_kyc_input-date {
          display: unset;
          // padding-right: 1.5rem; /* Adjust the value as needed */
          appearance: none; /* Remove default dropdown icon */
          background-image: url("../../images/calendar-svgrepo-com.svg"); /* Replace with your desired icon */
          background-repeat: no-repeat;
          background-position: right 2.5rem center; /* Adjust the position as needed */
          background-size: 1.4rem;
        }
        .thirdparty_kyc_input-date::-webkit-calendar-picker-indicator {
          width: 80%;
          opacity: 0;
          cursor: pointer;
          // display: none; /* Remove default calendar icon in WebKit-based browsers */
        }
      
        
        .thirdparty_kyc_textarea {
          color: #667185;
          border: 1px solid #E5E5E5;
          border-radius: 12px;
          height: 7.5rem;
          width: 100%;
          outline: none !important;
          font-size: 1.2rem;
          margin-bottom: 0.6rem;
          padding-top: 24px;
          padding-left: 24px;
        }

        .kyc_label {
          font-size: 1.6rem;
          font-weight: 600;
          color: #282828;
          margin-bottom: 1.2rem;
          display: flex;
          align-items: center;

          
          @media (max-width: 768px) {
            font-size: 1.2rem;
            font-weight: 700;
          }

          svg {
            margin-left: 5px;
            cursor: pointer;
          }
        }

        .kyc_input_flex {
          display: flex;
          align-items: center;

          .thirdparty_kyc_input {
            margin-bottom: 0 !important;
            border-radius: 12px;

            @media (max-width: 768px) {
              font-size: 0.8rem;
            }
          }
        }

        .kyc_upload_button {
          width: 133px;
          height: 55px;
          background-color: #00aed9;
          outline: none;
          border: none;
          border-radius: 12px;
          color: #fff;
          margin-left: -133px;
          display: flex;
          align-items: center;
          justify-content: center;
          font-size: 1.4rem;
          font-weight: 700;
          cursor: pointer;
        }

        .drag_drop {
          border: 0.5px dashed #374957;
          width: 100%;
          height: 12.3rem;
          background-color: #fafafa;
          border-radius: 12px;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          margin-top: 8px;

          & > div {
            font-weight: 700;
            color: #667185;
            margin-top: 14px;
          }
        }

        .highlight {
          background: linear-gradient(116.29deg, #006633 0.76%, #00aed9 100%) !important;
          &>div {
            font-weight: 700;
            color: #fff ;
            // background: yellow;
          }

          svg {
            path {
              fill: #fff;
            }
          }
        }
      }
      .thirdparty_questionaire {
        .questionaire_inner {
          display: flex;
          align-items: center;
          justify-content: space-between;

          .thirdparty_checkbox_group {
              display: flex;
            align-items: center;
          }
          & > div {
            // display: flex;
            // align-items: center;
            font-size: 1.6rem;
            // color: yellow;
            margin-bottom: 2.9rem;

            .questionaire_title {
              margin-right: -3rem !important;
            }

            span {
              color: #00aed9;
              margin-left: 0.3rem;
              cursor: pointer;
            }
            input {
              margin-right: 0.8rem;
              width: 2.4rem;
              height: 2.4rem;
            }
          }

          & > :last-child {
            margin-left: 3.2rem;
          }

          .thirdparty_checkbox_group {
            & > div {
              display: flex;
            align-items: center;

          }
          & > :last-child {
            margin-left: 2rem;
          }
          }
        }
      }
      .kyc_submit {
        width: 100%;
        height: 72px;
        border-radius: 12px;
        background: linear-gradient(116.29deg, #006633 0.76%, #00aed9 100%);
        display: flex;
        align-items: center;
        justify-content: center;
        margin: auto;
        border: none;
        outline: none;
        color: #ffffff;
        font-size: 1.6rem;
        font-weight: 600;
        cursor: pointer;
        margin-top: 6rem;
        transition: 0.7s;

        @media (max-width: 768px) {
          width: 100% !important;
        }

        & > div {
          margin-right: 12px;
          color: #ffffff;
        }

        &:hover {
          transition: 0.7s;

          background: none;
          color: #006633;
          border: 1px solid #006633;

          & > div {
            color: #006633;
          }
        }
      }
    }

    .kyc_modal_mini {
      width: 768px;
    }
  }