.notifications {
    width: 47.1rem;
    height: 100vh;
    background-color: #fff;
    position: fixed;
    top: 0;
    // right: 0;
    padding: 49px;
    border: 0.5px solid  #667185;
    overflow: auto;
    z-index: 4;
transition: 0.3s;

@media (max-width: 768px) {
    width: 25.7rem;
    padding: 24px;
}
    .close {
        margin-bottom: 2.4rem;
        // background: yellow;
        width: 100%;
        display: flex;
        justify-content: end;

    }

    .notifications_title {
        font-size: 1.6rem;
        font-weight: 600;
        color: #1a1a1a;
        // text-align: end;
        margin-bottom: 1.3rem;
        // background: yellow;
    }

    .notification_card {
        width: 100%;
        border-radius: 12px;
        background-color: #0A8B4B;
        display: flex;
        padding: 16px;
        margin-bottom: 1.6rem;

        .content {
            width: 100%;
            margin: 0 3rem;
            color: #fff;

            @media (max-width: 768px) {
                margin: 0 0.8rem;
            }

            .notifications_card_title {
                font-size: 1.4rem;
                font-weight: 700;
                margin-bottom: 1.6rem;
                color: #fff;

                @media (max-width: 768px) {
                    font-size: 1.2rem;
                }
            }

            .subtitle {
                font-size: 1.2rem;
                font-weight: 400;
                margin-bottom: 1.6rem;

                @media (max-width: 768px) {
                    font-size: 1rem;
                }

                & > span {
                    font-size: 10px;
                    text-decoration: underline;
                    cursor: pointer;
                }

                &__span-green {
                    background: #099137;
                }
                &__span-red {
                    color: #E43424;
                }

            }

            .time {
                font-size: 1rem;

                @media (max-width: 768px) {
                    font-size: 0.8rem;
                }
            }
        }

        svg {
            @media (max-width: 768px) {
                width: 16px;
                height: 16px;
            }
        }

        & >:last-child {
            & > svg {
                width: 16px;
            height: 16px;
            }
        }
    }

    .cancel-installation-modal {
        width: 100%;
        height: 100vh;
        position: fixed;
        top: 0;
        left: 0;
        background: rgba(0, 0, 0, 0.7);
        z-index: 2;

        display: flex;
        align-items: center;
        justify-content: center;

        &__inner {
            width: 100%;
            max-width: 215px;
            // height: 139px;
            border-radius: 8px;
            padding: 24px 16px;
            background: #FFF;

            &__title {
                color: var(--Net-Black, #1A1A1A);
font-size: 14px;
font-weight: 700;
margin-bottom: 6px;
text-align: center;
            }
            &__subtitle {
                color: var(--Grey-500, #667185);
text-align: center;
font-size: 10px;
font-weight: 400;
margin-bottom: 12px;
            }

            &__button-group {
                display: flex;
                align-items: center;
                gap: 12px;
                width: 100%;
                margin: auto;
          
                & > button {
                  width: 100%;
                height: 32px;
                outline: none;
                border: none;
                border-radius: 6px;
                background: linear-gradient(116.29deg, #006633 0.76%, #02a4cc 100%);
                font-size: 12px;
                font-weight: 400;
                color: #fff;
                margin: auto;
                // margin-top: 1.6rem;
                display: flex;
                align-items: center;
                justify-content: center;
                }
          
                & >:first-child {
                  background: transparent;
                        border: 1px solid  #063;
                        background: linear-gradient(116deg, rgba(0, 102, 51, 0.40) 0.76%, rgba(2, 164, 204, 0.40) 100%);
          background-clip: text;
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
                }
              }

              &__reschedule {

                color: var(--Blue, #00AED9);
text-align: center;
                font-size: 10px;
font-weight: 500;

& > span {
    text-decoration: underline;
    cursor: pointer;
}
              }
        }
    
    }
}

.closed {
   right: -450px;
   transition: 0.3s;
 
}
.open {
   right: 0;
   transition: 0.3s;

}