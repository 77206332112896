.shared-videos {
  width: 100%;
  max-width: 1192px;
  padding: 100px 20px;
  margin: 0 auto;

  &__group {
    display: flex;
    align-items: end;
    gap: 63px;
    margin-bottom: 64px;

    @media (max-width: 768px) {
      flex-direction: column;
      align-items: start;
    }

    & > div {
      width: fit-content;
    }

    &__plan {
      display: flex;
      align-items: center;
      width: 100%;
      max-width: 466px;
      height: 56px;
      border-radius: 12px;
      border: 1px solid #898989;
      display: grid;
      grid-template-columns: 1fr 172px;
      margin-bottom: 12px;

      @media (max-width: 565px) {
        max-width: 100%;
      }

      & > div {
        // width: 100%;
        height: 100%;
        border: none;
        outline: none;
        background: transparent;
        font-size: 1.4rem;
        font-weight: 400;
        color: #898989;
        padding: 0 18px;
        display: flex;
        align-items: center;
        justify-content: center;
        white-space: nowrap;

        @media (max-width: 768px) {
            white-space: initial;
        }
      }
      & > button {
        // width: 13.5rem;
        height: 100%;
        color: #fff;
        font-size: 1.6rem;
        font-weight: 500;
        border-radius: 12px;
        background-color: #063;
        border: none;
        outline: none;
        margin-left: 20px;
        padding: 0 18px;
      }
    }

    &__title {
      color: var(--Green-1, #0d924f);
      font-size: 16px;
      font-weight: 500;
      line-height: 150%; /* 24px */
      margin-bottom: 8px;
    }
    &__subtitle {
      color: var(--Black, #141414);
      font-size: 48px;
      font-weight: 700;
    }

    &__stats {
      color: var(--Black-2, #3b3b3b);
      font-size: 14px;
      font-weight: 500;
      margin-bottom: 8px;
    }

    &__stats-mini {
      color: var(--Grey, #898989);
      font-size: 14px;
      font-weight: 400;
    }
  }

  &__grid {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 24px;
    margin-bottom: 48px;

    @media (max-width: 768px) {
      grid-template-columns: 1fr;
    }

    &:nth-child(3) {
      grid-template-columns: 1.33fr 1fr;
      @media (max-width: 768px) {
        grid-template-columns: 1fr;
      }
    }
    &:nth-child(5) {
      grid-template-columns: 1fr 1.33fr;
      @media (max-width: 768px) {
        grid-template-columns: 1fr;
      }
    }
    &:last-child {
      grid-template-columns: 1fr;
    }

    &__card {
      width: 100%;

      & > iframe {
        width: 100%;
        height: 293px;
        object-fit: cover;
        margin-bottom: 16px;
        border-radius: 12px;
      }

      &__title {
        color: var(--Black, #141414);
        font-size: 24px;
        font-weight: 500;
        margin-bottom: 12px;
      }
      &__subtitle {
        color: var(--Black-2, #3b3b3b);
        font-size: 16px;
        font-weight: 400;
      }
    }
  }

  &__search {
    display: flex;
    gap: 8px;
    margin-bottom: 16px;

    & > select {
        border: none;
        border-radius: 8px;
        background: #fafafa;
        min-width: 70px;
        width: fit-content;
        max-width: 170px;
        height: 44px;
        padding: 10px;
        gap: 10px;
        color: var(--Primary-color, #063);
  }

    & > div {
      display: flex;
      width: 100%;
      max-width: 671px;
      height: 44px;
      padding: 8px;
      justify-content: center;
      align-items: center;
      gap: 8px;
      border-radius: 8px;
background: #FAFAFA;

& > input {
    width: 100%;
     height: 100%;
     border: none;
     background: none;
     &::placeholder {
        color: var(--Grey-2, #B1B1B1);
     }
}
    }
  }
}
