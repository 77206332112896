.create-blog {
  display: flex;

  .sidebar_container {
    // background-color: yellow;
    //  height: 100%;
    max-height: 700px;
    position: relative;

    @media (max-width: 1024px) {
      display: none;
    }
  }

  .create-blog_main {
    max-width: 1440px;
    width: 100%;
    margin: auto;
    height: 100vh;
    background-color: #fff;
    padding: 44px;
    overflow-x: auto;

    @media (max-width: 1024px) {
      overflow: unset !important;
    }

    @media (max-width: 565px) {
      padding: 24px;
    }

    .create-blog_form {
      max-width: 1000px;
      width: 100%;
      display: flex;
      flex-direction: column;

      .create-blog_grid {
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: 55px 68px;
        margin-bottom: 2.4rem;

        @media (max-width: 565px) {
          grid-template-columns: 1fr;
          gap: 24px;

        }

        .create-blog_input {
          width: 100%;
          height: 6.6rem;
          border-radius: 12px;
          border: 1px solid #E5E5E5;
          margin-top: 1.2rem;
          padding-left: 24px;
          outline: none;
        }
      }

      label {
        font-size: 1.6rem;
        font-weight: 600;
        color: #1a1a1a;

        span {
          color: red;
          font-weight: 800;
        }
      }

      textarea {
        border-radius: 12px;
        padding-top: 24px;
        padding-left: 24px;
        outline: none;
        height: 55rem;
        margin-top: 1.2rem;
      }

      .create-blog_button-group {
        display: grid;
        margin-top: 3.6rem;
        grid-template-columns: 21.2rem 37.9rem;
        gap: 32px;

        @media (max-width: 768px) {
          grid-template-columns: 1fr 1fr;
        }
        @media (max-width: 565px) {
          margin-top: 50px;
          margin-bottom: 50px;
        }

        .button-group_filled {
            width: 100%;
            height: 72px;
            border-radius: 12px;
            background: linear-gradient(116.29deg, #006633 0.76%, #00aed9 100%);
            display: flex;
            align-items: center;
            justify-content: center;
            border: none;
            outline: none;
            color: #ffffff;
            font-size: 1.6rem;
            font-weight: 600;
            cursor: pointer;
            transition: 0.7s;

            @media (max-width: 565px) {
              height: 4.8rem;
              font-size: 1.2rem;
            }
    
            &:hover {
              transition: 0.7s;
    
              background: none;
              color: #006633;
              border: 1px solid #006633;
            }
          }
        .button-group_trans {
            width: 100%;
            height: 72px;
            border-radius: 12px;
            background: transparent;
            color:  rgba(2, 164, 204, 0.40);
            display: flex;
            align-items: center;
            justify-content: center;
            border: 0.5px solid  #063;
            text-decoration: underline;
            outline: none;
            font-size: 1.2rem;
            font-weight: 700;
            cursor: pointer;
            transition: 0.7s;

            @media (max-width: 565px) {
              height: 4.8rem;
              font-size: 1.2rem;
            }
    
            
          }
      }

      
    }
  }
}


.helip {
  width: 100%; height: 800px !important;
  background: yellow;
}