.business-plan-payment {
    &__inner {
        max-width: 1440px;
        width: 100%;
        padding: 100px 144px 0 144px;
        margin: 0 auto;
        position: relative;
        height: 100%;

        &__box {
            border-radius: 12px;
border: 2px solid #0D924F;
padding: 80px;

&__title {
    color: var(--Black-2, #3B3B3B);
font-size: 3.2rem;
font-weight: 700;
margin-bottom: 64px;
text-align: center;
}
&__subtitle {
    color: var(--Black, #141414);
    font-size: 2.4rem;
    font-weight: 500;
    display: flex;
    align-items: center;
    gap: 24px;
    margin: 24px 0;
}
& > hr {
    stroke: var(--Grey-2, green);

}

&__label {
    color: var(--Black-2, #3B3B3B);
font-size: 1.6rem;
font-weight: 500;
margin-bottom: 16px;
margin-top: 24px;
}

&__radio {
    margin-bottom: 24px;
    display: flex;
    align-items: center;
    gap: 6px;
    color: var(--Black-2, #3B3B3B);
font-size: 1.4rem;
font-weight: 400;

& > svg {
    cursor: pointer;
}
}

&__summary-title {
    color: var(--Black-2, #3B3B3B);
font-size: 1.6rem;
font-weight: 500;
margin-bottom: 16px;
margin-top: 24px;
}

&__summary-group {
    color: var(--Black-2, #3B3B3B);
font-size: 1.6rem;
font-weight: 500;
margin-bottom: 16px;
display: grid;
align-items: center;
grid-template-columns: 150px auto;
gap: 30px;
margin-bottom: 24px;
}

& > button {
    width: 100%;
height: 82px;
border-radius: 12px;
margin-top: 32px;
border: none;
background: #0D924F;
color: #FFF;
font-size: 1.4rem;
font-weight: 500;
}


        }
    }
}