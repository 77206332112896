.validation {
  display: flex;
  .sidebar_container {
    // background-color: yellow;
    //  height: 100%;
    max-height: 700px;
    position: relative;

    @media (max-width: 1024px) {
      display: none;
    }
  }
  .main {
    width: 1440px;
    max-width: 100%;
    height: 100vh;
    background-color: #fff;
    padding: 44px;
    overflow-x: auto;
    margin: auto;

    @media (max-width: 565px) {
      padding: 24px;
    }

    .validation_label {
      font-size: 1.6rem;
      font-weight: 500;
      margin-bottom: 0.8rem;
      display: flex;
      align-items: center;

      .progress {
        width: 10px;
        height: 10px;
        background-color: #00aed9;
        border-radius: 50%;
        margin: 0 5px;
      }
      .completed {
        width: 10px;
        height: 10px;
        background-color: #0f973d;
        border-radius: 50%;
        margin: 0 5px;
      }
    }

    .progress {
      color: #00aed9;
    }

    .completed {
      color: #0f973d;
      margin-top: 2.4rem;
    }

    .failed {
      color: #d42620;
      margin-top: 2.4rem;
    }
    .validation_box_container {
      width: 100%;
      display: grid;
      grid-template-columns: 1fr;
      grid-column-gap: 50px;
      grid-row-gap: 12px;

      .validation_box {
        max-width: 1200px;
        width: 100%;
        // background-color: blue;
        min-height: 149px;
        border-radius: 16px;
        border: 1px solid #E5E5E5;
        padding: 0 20px 20px 20px;

        .validation_bar {
          width: 100%;
          color: #00aed9;
          height: 12px;
        }
        .validation_bar_completed {
          width: 100%;
          color: #0f973d;
          height: 12px;
        }
        .validation_bar_failed {
          width: 100%;
          color: #d42620;
          height: 12px !important;
        }

        .validation_box_flex {
          display: flex;
          align-items: center;

          @media (max-width: 768px) {
            flex-direction: column;
          }

          .validation_progress {
            // width: 100px;
            // height: 100px;
            // background: rgba(0, 174, 217, 0.3);
            // border-radius: 50%;
            // margin-top: 0 !important;
            // font-size: 1.8rem;
            // font-weight: 700;
            // color: #282828;
            // display: flex;
            // align-items: center;
            // justify-content: center;
            // position: relative;
            // border: 12px solid #00AED9;
            margin-right: 9.7rem;

            @media (max-width: 768px) {
                margin-right: 0;
                margin-bottom: 12px;
            }
          }

          .validation_error {
            border: 12px solid #d42620 !important;
            background: none;
          }

          .validation_tick_group {
            display: flex;
            align-items: center;

            @media (max-width: 768px) {
                width: 100%;
                justify-content: space-between;  
            }
            .validation_tick {
              margin-right: 3.65rem;
              width: fit-content;
              display: flex;
              flex-direction: column;
              align-items: center;
              justify-content: center;

              @media (max-width: 768px) {
                margin-right: 0;
              }

              & > :first-child {
                width: 32px;
                height: 32px;
                background-color: #00aed9;
                border-radius: 50%;
                display: flex;
                align-items: center;
                justify-content: center;
                margin-bottom: 4px;

                @media (max-width: 565px) {
                    width: 16px;
                height: 16px;
                }

                svg {
                    @media (max-width: 565px) {
                        width: 10px;
                    height: 10px;
                    }
                }
              }

              & > :last-child {
                text-align: center;
                font-weight: 500;
                color: #1a1a1a;
              }
            }

            .progress_tick {
              opacity: 0.3;
            }

            .validation_tick_completed {
              & > :first-child {
                background-color: #0f973d;
              }
            }

            .validation_tick_error {
              & > :first-child {
                background-color: #d42620;
              }
            }
          }
        }
      }
    }
    .empty_list {
      font-size: 3.2rem;
      font-weight: 500;
      text-align: center;
      color: #063;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      @media (max-width: 565px) {
        font-size: 1.6rem;
    }

      img {
        width: 50rem;
        height: auto;
        margin-bottom: 2.4rem;

        @media (max-width: 565px) {
          width: 35rem;
        }
      }
    }
  }
}
